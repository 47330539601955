
const chartConfigData = {
  // 粗集料刻度值默认值
  particlexAxisTick: [
    {
      name: '筛底', // 在x轴显示的数值
      xAxis: 1.077, // 与series每一项的data二维数组的第一个元素对应的值
    },
    {
      name: '2.36',
      xAxis: 1.472,
    },
    {
      name: '4.75',
      xAxis: 2.016,
    },
    {
      name: '9.5',
      xAxis: 2.754,
    },
    {
      name: '16.0',
      xAxis: 3.482,
    },
    {
      name: '19.0',
      xAxis: 3.762,
    },
    {
      name: '26.5',
      xAxis: 4.37,
    },
    {
      name: '31.5',
      xAxis: 4.723,
    },
    {
      name: '37.5',
      xAxis: 5.109,
    },
  ],
  // 颗粒级配ecahrts默认数据
  particleEchartsData: [
    {
      aperture: '筛底',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '2.36',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '4.75',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '9.5',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '16.0',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '19.0',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '26.5',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '31.5',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
    {
      aperture: '37.5',
      upperLimit: 0, //最大值
      lowerLimit: 0, //最小值
      detectionResults: 0, //实际值（时间通过百分率%）
    },
  ],
  // 细集料刻度值默认值
  finessxAxisTick: [
    {
      name: '筛底', // 在x轴显示的数值
      xAxis: 0, // 与series每一项的data二维数组的第一个元素对应的值
    },
    {
      name: '0.075',
      xAxis: 0.312,
    },
    {
      name: '0.15',
      xAxis: 0.426,
    },
    {
      name: '0.3',
      xAxis: 0.582,
    },
    {
      name: '0.6',
      xAxis: 0.795,
    },
    {
      name: '1.18',
      xAxis: 1.077,
    },
    {
      name: '2.36',
      xAxis: 1.472,
    },
    {
      name: '4.75',
      xAxis: 2.016,
    },
    {
      name: '9.5',
      xAxis: 2.754,
    },
  ],
  // 颗粒级配ecahrts默认数据
  finessEchartsData: [
    {
      aperture: '筛底',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '0.075',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '0.15',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '0.3',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '0.6',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '1.18',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '2.36',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '4.75',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
    {
      aperture: '9.5',
      detectionResults: 0,
      lowerLimit: 0,
      upperLimit: 0,
    },
  ]
}

export default chartConfigData