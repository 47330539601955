//列格式
const colConfig = {
    lg: 6,
    md: 6,
    sm: 24,
    xs: 24,
};
const colConfig_1 = {
    lg: 8,
    md: 8,
    sm: 24,
    xs: 24,
};

export {
    colConfig,
    colConfig_1
}