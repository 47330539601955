import axios from "axios";

export class apiCenterBottom {
  //集料规格查询
  static getAggregateSpecification(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/aggregateManage",
      method: "GET",
      params: query,
    });
  }
  //供应商格查询
  static getSupplier(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/vendorManage",
      method: "GET",
      params: query,
    });
  }
  //根据集料id查询供应商
  static getSupplierByAggregateId(id) {
    return axios({
      url: `device/vendorManage/aggregateIdByVendor/${id}`,
      method: "GET",
    });
  }
  //根据供应商id集料id查询生产商
  static getManuacturer(data) {
    return axios({
      url: "/device/vendorManage/FindManufacturers",
      method: "POST",
      data: data,
    });
  }
  //编辑和保存取样和样品登记
  static confirmRegiste(data) {
    return axios({
      url: "/device/sampling/aggregateBegin",
      method: "POST",
      data: data,
    });
  }
  //获取取样和样品登记信息
  static getRegisteSampleInfo(id) {
    return axios({
      url: `/device/sample/${id}`,
      method: "get",
    });
  }
  //获取设备参数
  static getDeviceSettings(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/deviceSettings",
      method: "GET",
      params: query,
    });
  }
  //修改设备参数
  static saveDeviceSettings(data) {
    return axios({
      url: "/device/deviceSettings",
      method: "PUT",
      data: data,
    });
  }
  //查询检测单位
  static getTestingUnit() {
    return axios({
      url: "/device/sample/testUnit",
      method: "GET",
    });
  }
  //查询供货单位
  static getCompanyByType(data) {
    return axios({
      url: "/device/company/getCompanyByType",
      method: "POST",
      data: data,
    });
  }
   //查询树形分组规格
   static aggregatesTypeGroup(data) {
    return axios({
      url: "/device/aggregateManage/aggregatesTypeGroup",
      method: "GET",
      params: data,
    });
  }
   //查询执行标准
   static getAllConfig(data) {
    return axios({
      url: "/sysConfig/getAllConfig",
      method: "GET",
      params: data,
    });
  }

   //查询检测依据
   static getTestingBasisList(data) {
    return axios({
      url: "/device/testingBasis/getTestingBasisList",
      method: "GET",
      params: data,
    });
  }

  //查询执行标准
  static getTestingStandards(data) {
    return axios({
      url: "/device/sample/testingStandards",
      method: "GET",
      params: data,
    });
  }

   //查询执行标准2
   static getExecutionStandards(data) {
    return axios({
      url: "/sysConfig/getExecutionStandards",
      method: "GET",
      params: data,
    });
  }
  


}
