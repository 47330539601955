import setting from "./setting";
import {message} from 'ant-design-vue';
import store from '@/store';
// import {save, updateRecord} from "@/sql/method";
const SocketUrl = process.env.VUE_APP_SOCKET_BASE_URL;

export class Socket {
    constructor() {
        console.log("start socket")
        this.heartInterval = 1000 * 10;
        this.heartSeverTimeout = 1000 * 8;
        this.url = process.env.VUE_SOCKET_BASE_URL;
        this.device = "pc"
        this.messageBox = message;
        this.socketUrl = SocketUrl;
    }

    initWebSocket() {
        console.log("初始化socket======");
        // console.log(process.env)
        // console.log(this.socketUrl);
        // this.url = "ws://localhost:8081/WebSocket/" + this.device + "?access_token=" + setting.takeToken();
        clearTimeout(this.subscribeTimeoutObj);
        this.url = this.socketUrl + this.device + "?access_token=" + setting.takeToken();
        // http://192.168.0.228:8080/
        try {
            this.websocket = new WebSocket(this.url)
        } catch (e) {
            console.log(e)
        }
        // 连接错误
        this.websocket.onerror = this.setErrorMessage()

        // 连接成功
        this.websocket.onopen = this.setOnopenMessage()

        // 收到消息的回调
        this.websocket.onmessage = this.setOnmessageMessage()

        // 连接关闭的回调
        this.websocket.onclose = this.setOncloseMessage()

        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = this.onbeforeunload()

        this.subscriptions = {}



    }

    reconnect() {
        this.subscribeTimeoutObj && clearTimeout(this.subscribeTimeoutObj);

        // 重新连接
        if (this.lockReconnect) return;
        this.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        this.timeoutnum && clearTimeout(this.timeoutnum);
        this.timeoutnum = setTimeout(() => {
            //新连接
            this.initWebSocket();
            this.lockReconnect = false;
        }, 5000);
    }

    reset() { // 重置心跳
        // 清除时间
        clearTimeout(this.heartTimeObj);
        clearTimeout(this.serverTimeoutObj);
        // 重启心跳
        this.start();
    }

    start() { // 开启心跳
        this.heartTimeObj && clearTimeout(this.heartTimeObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.heartTimeObj = setTimeout(() => {
            // 这里发送一个心跳，后端收到后，返回一个心跳消息，
            console.log("start" + '   状态码：' + this.websocket.readyState)
            if (this.websocket && this.websocket.readyState == 1) { // 如果连接正常
                // this.websocketsend('mt');
            } else { // 否则重连
                this.reconnect();
            }
            this.serverTimeoutObj = setTimeout(() => {
                //超时关闭
                this.websocket.close();
            }, this.heartSeverTimeout);

        }, this.heartInterval)
    }


    resetSubscribe(){
        clearTimeout(this.subscribeTimeoutObj);
        this.startSubscribe()
      }
      startSubscribe() { // 开启心跳
        this.subscribeTimeoutObj && clearTimeout(this.subscribeTimeoutObj);
        this.subscribeTimeoutObj = setTimeout(() => {
          this.closeWebSocket()
        },this.heartSeverTimeout)
      }

    setOnmessageMessage() {
        return (event) => {
                        
            // this.reset();
            let message = event.data;
            // console.log("接收到的服务器消息：", message)

            if (message == `engineMessage`) {
                // console.log(message);
                this.resetSubscribe()
                return
            }

            if (message == 'mt') {
                console.log("心跳")
                return;
            }
            if (message == 'connected') {
                console.log("连接")
                return;
            }
            let startIndex = message.indexOf('{'); // 找到第一个 { 的位置
            let headerStr = message.substring(0, startIndex)
            let command = headerStr.substring(0, headerStr.indexOf("\n"));
            headerStr = message.substring(message.indexOf("\n"), startIndex)
            // let bodyJson = message.substring(message.indexOf("\n\n") + 2, message.length - 2)
            let bodyJson = message.substring(startIndex); // 从第一个 { 开始截取到最后
            var lines = headerStr.split("\n");
            let headers = {};
            lines.forEach((line) => {
                let keyValue = line.split(":");
                headers[keyValue[0]] = keyValue[1];
            })

            let body = bodyJson;
            // try {
            //     body = JSON.parse(bodyJson)
            // } catch (err) {
            //     console.log(err)
            // }

            let destination = headers["destination"];

            if (command == "MESSAGE") {


                let messageCallback = this.subscriptions[destination]
                if (messageCallback) {
                    messageCallback(destination, body, headers)
                }


            } else if (command == "P2P") {
                let messageCallback = this.subscriptions[destination]
                let fromUser = headers["fromUser"]
                if (messageCallback) {
                    messageCallback(destination, body, headers, fromUser)
                }
            }

            // let obj = JSON.parse(event.data);
            // console.log("obj",obj)
            // switch(obj.type) {
            //   case 'heartbeat':
            //     //收到服务器信息，心跳重置
            //     this.reset();
            //     break;
            //   case 'sendMessage':
            //     this.data = obj.data
            //     console.log("接收到的服务器消息：",obj.data)
            // }

        }
    }

    setErrorMessage() {
        return () => {
            // console.log(e);
            // console.log("WebSocket连接发生错误" + '   状态码：' + this.websocket.readyState)
            //重连
            this.reconnect();
            //设置状态为未连接
            store.dispatch('user/setSocketStatus', false)
                .then(() => {
                console.log("设置未连接状态成功")
            });
        }

    }

    setOnopenMessage() {
        return () => {
            //开启心跳
            // this.start();
            console.log("WebSocket连接成功" + '   状态码：' + this.websocket.readyState)
            //设置状态为已连接
            store.dispatch('user/setSocketStatus', true)
                .then(() => {
                console.log("设置已连接状态成功")
                //TODO 订阅引擎主题
                store.state.user.socket.stompSubscribe("engineMessage", (destination, body) => {
                    // console.log("收到引擎正常消息")
                    // console.log(headers);
                    // console.log('收到引擎正常消息',JSON.parse(JSON.parse(body + "]")[0]["newValue"]))
                    // console.log(destination)
                    //store处理引擎数据
                    store.dispatch("engine/handleEngineAction", body);

                })
            });
        }
    }

    setOncloseMessage() {
        return () => {
            //重连
            this.reconnect();
            console.log("WebSocket连接关闭" + '   状态码：' + this.websocket.readyState)
            //设置状态为未连接
            store.dispatch('user/setSocketStatus', false)
                .then(() => {
                console.log("设置未连接状态成功")
            });
        }
    }

    onbeforeunload() {
        return () => {
            this.closeWebSocket();
        }

    }

//websocket发送消息
    websocketsend(messsage) {
        if (this.websocket.readyState == 1) {
            this.websocket.send(messsage)
        }
    }

    closeWebSocket() { // 关闭websocket
        console.log(this)
        this.websocket?.close()
    }

    createMessage(command, headers, body) {
        let message = command + "\n";
        if (headers) {
            for (let headerKey in headers) {
                message += headerKey + ":" + headers[headerKey] + "\n";
            }
        }
        message += "\n";
        if (body) {
            message += JSON.stringify(body) + "^@";
        }
        return message;
    }

    stompPublish(destination, body) {
        let headers = {destination: destination, "content-type": "application/json"};
        let message = this.createMessage("SEND", headers, body)
        this.websocketsend(message);
    }

    stompP2P(destination, toUser, body) {
        let headers = {destination: destination, toUser: toUser, "content-type": "application/json"};
        let message = this.createMessage("P2P", headers, body)
        this.websocketsend(message);
    }

    stompSubscribe(destination, messageCallback) {
        let headers = {destination: destination, "content-type": "application/json"};
        let message = this.createMessage("SUBSCRIBE", headers)
        this.websocketsend(message);
        this.subscriptions[destination] = messageCallback;
    }

    stompUnsubscribe(destination) {
        let headers = {destination: destination, "content-type": "application/json"};
        let message = this.createMessage("UNSUBSCRIBE", headers)
        this.websocketsend(message);
        this.subscriptions[destination] = void 0;
    }

    resolve(e) {
        // console.log(e.elementName)
        // console.log(e.elementAddress);
        // console.log(e.elementName);
        // console.log(e.elementValue);
        // let elementAddress=elementAddress;
        // debugger
        let elementName = e.elementName;
        let elementValue = e.elementValue;
        //这是总线启停的开关独立于设备参数之外
        if (elementName == "Detection_ACK") {
            store.state.device.Detection_ACK = elementValue;
            // save();
            // return;
        }
        if (elementName == 'TGx_axis_position') {
            console.log(elementName + '=' + elementValue)
        }
        let prefix = elementName.substring(0, 2);
        //依据2位大写字母前缀判断设备 修改对应设备属性值
        let prefixDevice = store.state.device.prefixDevice;
        let deviceName = prefixDevice[prefix];
        let deviceControl = store.state.device.deviceControl;
        let deviceId = deviceControl[deviceName].device_id;
        // console.log(deviceId)
        let deviceData = store.state.device.deviceData;
        elementName = elementName.substring(2, elementName.length);
        // console.log(elementName)
        let device = deviceData[deviceId];
        device.attribute[elementName].current_value = elementValue;//赋值 数据值
        device.attribute[elementName].current_time = this.getDateYYYYMMddHHMMSS();//当前时间
        if (elementName == 'weight') {
            //含水含泥 称重值
            device.attribute[elementName].history.push({
                detection: 'jc0001',
                value: device.attribute[elementName].current_value,
                time: device.attribute[elementName].current_time,
            });
            store.state.device.chartData.water_and_mud.push({
                xAxis: this.getDateYYYYMMddHHMMSS(),
                series: device.attribute[elementName].current_value
            })
        }

        //细度模数  筛上重量
        if (elementName == 'sieve_weight_4_75mm') {
            store.state.device.chartData.fineness_modulus[0] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#1890FF'
                }
            };
            // console.log("4.75mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'sieve_weight_2_36') {
            store.state.device.chartData.fineness_modulus[1] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#29AA4F'
                }
            };
            // console.log("2.36mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'sieve_weight_1_15') {
            store.state.device.chartData.fineness_modulus[2] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#DBB211'
                }
            };
            // console.log("1.15mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'sieve_weight_0_6') {
            store.state.device.chartData.fineness_modulus[3] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#1F2C65'
                }
            };
            // console.log("0.6mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'sieve_weight_0_3') {
            store.state.device.chartData.fineness_modulus[4] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#743BC2'
                }
            };
            // console.log("0.3mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'sieve_weight_0_15') {
            store.state.device.chartData.fineness_modulus[5] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#11AAAB'
                }
            };
            // console.log("0.15mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'sieve_weight_0_075') {
            store.state.device.chartData.fineness_modulus[6] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#2D2FAE'
                }
            };
            // console.log("0.075mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'small_sieve_weight_0_075mm') {
            store.state.device.chartData.fineness_modulus[7] = {
                value: device.attribute[elementName].current_value,
                itemStyle: {
                    color: '#D23E57'
                }
            };
            // console.log("<0.075mm筛上重量" + device.attribute[elementName].current_value);
        }
        if (elementName == 'device_status' && prefix == 'TG') {
            let value;
            switch (elementValue) {
                case "1":
                    value = '手动模式'
                    break;
                case "2":
                    value = '自动模式'
                    break;
                default:
                    value = '急停模式'
            }
            deviceControl[deviceName][elementName] = value;//0急停模式1手动模式2自动模式
        } else if (elementName == 'device_status' && prefix == 'QY') {
            deviceControl[deviceName][elementName] = elementValue == 1 ? '运行中' : '停机';
        } else {
            deviceControl[deviceName][elementName] = elementValue == 'ON' ? '运行中' : '停机';
        }

        // save();
    }

    getDateYYYYMMddHHMMSS() {
        const date = new Date();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const strDate = date.getDate().toString().padStart(2, '0');
        const starHours = date.getHours().toString().padStart(2, '0');
        const starMinutes = date.getMinutes().toString().padStart(2, '0');
        const starSeconds = date.getSeconds().toString().padStart(2, '0');
        // return `${date.getFullYear()}-${month}-${strDate} ${starHours}:${starMinutes}:${starSeconds}`;
        return `${date.getFullYear()}年${month}月${strDate}日 ${starHours}:${starMinutes}:${starSeconds}`;
    }
}
