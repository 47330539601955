//集料管理列表列
const aggregatesColumns = [
    //序号
    {
        type: 'seq',
        title: '序号',
        sortable: false,
        maxWidth: 20
    },
    //集料分类 field: "aggregatesType",
    {

        title: "集料分类",
        sortable: false,
        minWidth: 50,
        slots: { default: "aggregatesGroup" }
    },
    //集料规格
    {
        field: "aggregatesSpecs",
        title: "集料规格",
        sortable: false,
        minWidth: 80
    },
    //地磅集料规格
    {
        field: "iwsAggregatesSpecs",
        title: "地磅集料规格",
        sortable: false,
        minWidth: 80
    },
    // {

    //     title:"类别",
    //     sortable:false,
    //     minWidth:80,
    //     slots:{default:"aggregateCategory"}
    // },
    //状态 field:"status",
    {

        title: "状态",
        sortable: false,
        minWidth: 50,
        slots: { default: "state" }
    },
    {
        title: "操作",
        width: 300,
        slots: { default: "operate" },
        fixed: "right"
    }

]



const supplierColumns = [
    //序号
    {
        type: 'seq',
        title: '序号',
        sortable: false,
        minWidth: 50
    },
    //供应商名称
    {
        field: "vendorName",
        title: "供应商名称",
        sortable: false,
        minWidth: 80
    },
    //供应集料
    {
        title: '供应集料',
        sortable: false,
        minWidth: 150,
        slots: { default: "aggregatesGroup" }
    },
    //法人
    {
        field: "juridicalPerson",
        title: '法人',
        sortable: false,
        minWidth: 50,
    },
    {
        field: "phone",
        title: '电话',
        sortable: false,
        minWidth: 50,
    },
    {
        title: "状态",
        sortable: false,
        minWidth: 50,
        slots: { default: "state" }
    },
    {
        title: "操作",
        width: 300,
        slots: { default: "operate" },
        fixed: "right"

    },
];

//日志列
const logColumns = [
    {
        title: '类型',
        sortable: false,
        minWidth: 50,
        slots: { default: "type" }
    },
    {
        title: '设备',
        sortable: false,
        minWidth: 50,
        slots: { default: "equipment" }
    },
    {
        field: "testNumber",
        title: '样品编号',
        sortable: false,
        minWidth: 100,

    },
    {
        field: "content",
        title: '详细内容',
        sortable: false,
        minWidth: 250,
    },
    {

        field: "createDatetime",
        title: '时间',
        sortable: false,
        minWidth: 100,
    }

];

//单位管理列
const unitsColumns = [
    {
        title: '单位名称',
        sortable: false,
        minWidth: 150,
        field: "name",
    },
    {
        field: "type",
        title: '单位类型',
        sortable: false,
        minWidth: 100,
        slots: { default: "untype" }

    },
    {
        field: "legalPerson",
        title: '法人',
        sortable: false,
        minWidth: 50,
    },
    {
        field: "phone",
        title: '电话',
        sortable: false,
        minWidth: 100,
    },{
        title: '状态',
        sortable: false,
        minWidth: 50,
        slots: { default: "status" }
    },
    {
        title: "操作",
        width: 200,
        slots: { default: "operate" },
        fixed: "right"

    },

];

//单位管理列
const standardColumns = [
    {
        field: "testingBasisCode",
        title: '编号',
        sortable: false,
        minWidth: 50,
    },
    {
        field: "testingBasis",
        title: '名称',
        sortable: false,
        minWidth: 100,
    },
    {
        title: "操作",
        width: 200,
        slots: { default: "operate" },
        fixed: "right"

    },

];

export {
    aggregatesColumns,
    supplierColumns,
    logColumns,
    unitsColumns,
    standardColumns


}