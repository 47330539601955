import { apply } from 'json-patch'
export default {
    namespaced: true,
    state: {
        // 检测线名称
        detectionLine: '',
        // 总状态，有数据为ture，没数据时为false
        sseStatus:{
            dataStatus:false
        },
        allEngineData: {
            //TODO: 货物识别 左 1-1
            goodsIdentifyModeulus: null,

            //TODO 取样 右1-1
            sampling: null,

            //TODO 当前检测单号
            currentTestNumber: "",

            // 执行标准字典
            executionStandardOptions: [],

            // 设备状态
            // 取样设备状态
            samplingDeviceStatus: null,
            // 机械手设备状态
            manipulatorDeviceStatus: null,
            // 烘干设备状态
            dryDeviceStatus: null,
            // 水洗设备状态
            washDeviceStatus: null,
            // 颗粒级配设备状态
            particleGradingModulusDeviceStatus: null,
            // 细度模数设备状态
            finenessModulusDeviceStatus: null,
            // 亚甲蓝设备状态
            methyleneBlueDeviceStatus: null,
            // 货物识别设备状态
            goodsIdentificationStatus: null,

            // 含水率部分的温度
            blockTemperature: null,

            //TODO 样品服务
            sampleService: {},
            //TODO 样品待检测队列
            toBeTestedQueue: {},
            //TODO 样品待检测队列数组
            toBeTestedQueueToList: [],
            //TODO 样品列表检测完和未完成状态数据
            sampleListData: [],
            //TODO: 水洗设备
            washDeviceX: {},
            //TODO: 烘干设备
            dryDeviceX: {},
            //TODO: 水洗设备
            washDeviceC: {},
            //TODO: 烘干设备
            dryDeviceC: {},
            //TODO: 含水率 左1
            containingWaterModulus: null,
            containingWaterCharts: null,

            //TODO: 含泥率 左2
            containingMudModulus: null,

            //TODO: 颗粒级配 左3
            particleGradingModulus: null,


            //TODO 细度模数 右1
            finenessModulusDevice: {},
            finenessModulusCharts: null,


            //TODO MB值 右2
            methyleneBlueStationStatus: {},//状态及基础数据
            stationResult1: {},//工位1的结果数据
            stationResult2: {},//工位2的结果数据
            stationResult3: {},//工位3的结果数据

            // 亚甲蓝设备故障信息
            methyleneBlueDeviceFault: null,



            //取样钻头设备
            sampleBitDevice: null,
            // 货物识别结果
            goodsIdentificationResult: null,



            // 推荐集料砂率
            sandRateResultMap: {}, //最大公称粒径、细度模数
            sandRateParam: {}, //水胶比、砂率


            // 推荐集料掺配比例
            planningSolutionResult: {},

            // 气压状态
            pressureStatus: false,
            // 水压状态
            waterPressureStatus:false
            
        }



    },
    //TODO:set方法
    mutations: {
        SET(state, obj) {
            state[obj.key] = obj.value;
        },
        SET_ENGINE(state, obj) {
            for (let key in obj) {
                state[key] = obj[key];
            }
            // console.log(
            //     'socket推送的样品数据1111111111111111111：',
            //     state.engine?.toBeTestedQueue
            // );
        },
        handleEngineSseData(state, value) {
            // console.log("🚀 ~ handleEngineSseData ~ value:", value)
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                state.allEngineData = { ...value }
            } else if (Array.isArray(value)) {
                // 后续补丁数据通过apply替换
                state.allEngineData = apply(state.allEngineData, value)
            }
            state.allEngineData = { ...state.allEngineData }
            // console.log("🚀 ~ SET_ENGINE ~ state:", state.allEngineData)
        },
        setDetectionLine(state, line) {
            state.detectionLine = line
        },
        setSseStatus(state, status) {
            // console.log("🚀 ~ setSseStatus ~ status:", status)
            state.sseStatus = { dataStatus:status }
        },
    },
    //TODO:处理异步方法，通过提交mutations实现
    actions: {
        handleEngineAction({ commit }, body) {

            // console.log("initEngine")
            // console.log(body)
            // console.log(JSON.parse(body + "}}"));
            try {
                let tempBody = JSON.parse(body);
                let actualBody = tempBody["newValue"];
                // console.log('订阅socket数据保存到vuex', actualBody);
                commit('SET_ENGINE', actualBody);
            } catch (error) {
                console.log('保存socket数据出错', error);
            }

        },
        handleEngineSseData({ commit }, body) {
            try {
                // console.log("🚀 ~ handleEngineSseData ~ body:", body)
                commit('SET_ENGINE', body);
            } catch (error) {
                console.log('保存sse数据出错', error);
            }

        }
    }
}