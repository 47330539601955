<template>
  <a-modal
    :visible="props.visible"
    width="100%"
    wrap-class-name="table-modal"
    centered
    :destroyOnClose="true"
    :closeIcon="true"
    @cancel="closeLogModal"
  >
    <template #title>
      <div class="modal-name">日志列表</div>
      <a-button class="close-btn" @click="closeLogModal">关闭 </a-button>
    </template>
    <div>
      <div class="mod-main">
        <a-tabs v-model:activeKey="activeKey" @change="onLogChange">
          <a-tab-pane
            key="2"
            tab="设备故障"
            :animated="true"
            :tabPosition="'top'"
          >
            <vxe-grid
              ref="logerrorGridRef"
              highlight-current-row
              v-bind="logerrorTableConfig"
              class="my-grid"
            >
              <template #type="{ row }">
                <div class="hitch-color" v-if="row.type == '1'">操作日志</div>
                <div class="offline-color" v-if="row.type == '2'">设备故障</div>
              </template>
              <template #equipment="{ row }">
                <div>{{ row.equipment ?? '--' }}</div>
              </template>
              <!-- 空状态 -->
              <template #empty>
                <a-empty />
              </template>
            </vxe-grid>
          </a-tab-pane>
          <a-tab-pane key="1" tab="操作日志">
            <vxe-grid
              v-if="true"
              ref="logGridRef"
              highlight-current-row
              v-bind="logTableConfig"
              class="my-grid"
            >
              <template #type="{ row }">
                <div class="hitch-color" v-if="row.type == '1'">操作日志</div>
                <div class="offline-color" v-if="row.type == '2'">设备故障</div>
              </template>
              <template #equipment="{ row }">
                <div>{{ row.equipment ?? '--' }}</div>
              </template>
              <!-- 空状态 -->
              <template #empty>
                <a-empty />
              </template>
            </vxe-grid>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <template #footer> </template>
  </a-modal>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits, nextTick } from 'vue';
import { apiTopHeader } from '@/api/api-topHeader.js';
// import {message} from "ant-design-vue";
//TODO 表格相关配置
import TableConfig from '../../js/base.vxetable.config.js';
import { logColumns } from '../../js/table.columns.js';
//TODO 代理函数
import { useAjaxProxy } from '../../js/useAjaxProxy.js';
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
});
const emits = defineEmits(['update:visible']);
const closeLogModal = () => {
  emits('update:visible', false);
};
//节点
let logGridRef = ref();
let logerrorGridRef = ref();
const activeKey = ref('2');
//查询参数
let queryParams = ref({
  limit: 5,
  page: 1,
  order: 'desc',
  sort: 'createDatetime',
  type: activeKey.value,
});

//列表表格基础配置
let logTableConfig = TableConfig.getTableConfig_3();
let logerrorTableConfig = TableConfig.getTableConfig_3();

//合并列
logTableConfig['columns'] = logColumns;
logerrorTableConfig['columns'] = logColumns;

//数据代理扩展
logTableConfig['proxyConfig']['ajax'] = {};
logerrorTableConfig['proxyConfig']['ajax'] = {};
//TODO 代理扩展函数
logTableConfig['proxyConfig']['ajax']['query'] = useAjaxProxy(
  queryParams,
  logGridRef,
  apiTopHeader.findLogListByPage
);
logerrorTableConfig['proxyConfig']['ajax']['query'] = useAjaxProxy(
  queryParams,
  logerrorGridRef,
  apiTopHeader.findLogListByPage
);

onMounted(() => {
  //调用接口
  // logGridRef.value?.commitProxy('query');
  logerrorGridRef.value?.commitProxy('query');
});
/**
 * @introduction 日志tab页切换事件
 */
const onLogChange = (value) => {
  // console.log('🚀 ~ file: log-list.vue:132 ~ onLogChange ~ value:', value == 1);
  // console.log(logGridRef.value);
  queryParams.value.type = value;
  queryParams.value.page = 1;
  nextTick(() => {
    value == 1
      ? logGridRef.value?.commitProxy('query')
      : logerrorGridRef.value?.commitProxy('query');
  });
};
</script>

<style scoped>
@import '../../../assets/style/vxe-coustom.scss';
.mod-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}
:deep(.ant-btn-primary) {
  height: fit-content;
}
.offline-color {
  color: #ff7875;
}
/* .close-btn {
  margin-left: auto;
  z-index: 9999;
  background: none;
  border: none;
  font-size: 0.08rem;
  color: white;
} */
:deep(.ant-tabs-tab) {
  color: white;
}
</style>
