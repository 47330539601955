import {message} from "ant-design-vue";

/**
 *
 * @param where 筛选条件
 * @param gridRef 表格节点
 * @param cbFn   api接口函数
 * @param isQurey 重置筛选条件
 */
function useAjaxProxy(where,gridRef,cbFn){
    return ({page}, isQuery=false) => {  // 接收 Promise
        // console.log(page);
        //打印筛选条件
        // console.log(where.value);

        let queryParams = {};

        if (isQuery) {
            queryParams.page = 1;
        } else {
            queryParams.page = page.currentPage;
        }
        queryParams.limit = page.pageSize;
        //合并
        queryParams=Object.assign(where.value,queryParams);
        // console.log("===queryParams===");
        // console.log(queryParams)
        const $grid = gridRef.value;
        $grid.queryParams = queryParams;
        return  cbFn(queryParams).catch(() => {
            message.error('获取数据失败', 2);
        });
    };

}

export {
    useAjaxProxy
}