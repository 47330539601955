<template>
  <div>
    <div class="mod-main">
      <div style="margin-bottom: 0.06rem">
        <a-form
          :model="supplierformState"
          style="width: 100%"
          v-bind="baseFormConfig"
        >
          <a-row>
            <!-- <a-col
              v-bind="{
                lg: 4,
                md: 4,
                sm: 24,
                xs: 24,
              }"
            >
              <a-form-item label="状态">
                <a-select
                  v-model:value="supplierformState.status"
                  placeholder="请选择状态"
                  :options="statusOptions1"
                  @change="handleStatusChange"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              v-bind="{
                lg: 4,
                md: 4,
                sm: 24,
                xs: 24,
              }"
            >
              <a-form-item
                label="单位类型"
                :wrapper-col="{ span: 16, offset: 1 }"
              >
                <a-select
                  v-model:value="supplierformState.type"
                  placeholder="请选择单位类型"
                  :options="unitsGroupOptions"
                  @change="handleUnitsSelected"
                >
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col
              v-bind="{
                lg: 4,
                md: 4,
                sm: 24,
                xs: 24,
              }"
            >
              <a-form-item label="编号">
                <a-input
                  v-model:value="supplierformState.testingBasisCode"
                  placeholder="请输入编号"
                  :maxlength="255"
                  allow-clear
                  data-label="testingBasisCode"
                  @change="handleNameInput"
                />
              </a-form-item>
            </a-col>

            <a-col
              v-bind="{
                lg: 4,
                md: 4,
                sm: 24,
                xs: 24,
              }"
            >
              <a-form-item label="名称">
                <a-input
                  v-model:value="supplierformState.testingBasis"
                  placeholder="请输入名称"
                  :maxlength="255"
                  allow-clear
                  @change="handlePhoneInput"
                />
              </a-form-item>
            </a-col>
            <a-col v-bind="colConfig">
              <a-form-item>
                <div style="display: flex; align-items: center">
                  <a-button
                    type="primary"
                    @click="handleListDataQuery"
                    style="margin-right: 10px"
                    >查询
                  </a-button>
                  <a-button
                    type="primary"
                    @click="handleListDataReset"
                    style="margin-right: 10px"
                    >重置
                  </a-button>
                  <a-button type="primary" @click="editEvent(null)"
                    >添加
                  </a-button>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <vxe-grid
        ref="supplierGridRef"
        highlight-current-row
        v-bind="supplierTableConfig"
        class="my-grid msads"
      >
        <template #status="{ row }">
          <div class="hitch-color" v-if="row.status == '1'">启用</div>
          <div class="success-color" v-if="row.status == '0'">停用</div>
        </template>
        <template #untype="{ row }">
          <div v-if="row.type == 1">供应商</div>
          <div v-if="row.type == 2">生产商</div>
          <div v-if="row.type == 3">施工单位</div>
          <div v-if="row.type == 4">建设单位</div>
          <div v-if="row.type == 5">检测单位</div>
        </template>

        <template #aggregatesGroup="{ row }">
          <div v-if="row.aggregates && row.aggregates.length > 0">
            <div v-for="(item, index) in row.aggregates" :key="index">
              <div>
                <span>{{ item.aggregatesTypeParent }}-</span>
                <span>{{ item.aggregatesSpecs }}</span>
                <!-- <span v-if="item.aggregateCategory == 1">Ⅰ类</span>
                <span v-if="item.aggregateCategory == 2">Ⅱ类</span>
                <span v-if="item.aggregateCategory == 3">Ⅲ类</span> -->
              </div>
            </div>
          </div>
          <div v-else-if="row.aggregates == null">暂无数据</div>
        </template>

        <template #operate="{ row }">
          <a-space>
            <a-popconfirm
              title="是否确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="deleteEvent(row)"
            >
              <div class="btn btn-color1">删除</div>
            </a-popconfirm>
            <div class="btn btn-color2" @click="editEvent(row)">编辑</div>
          </a-space>
        </template>
      </vxe-grid>

      <!-- 新增/编辑 弹窗   -->
      <AddStandard
        v-if="editVars.modalShow"
        v-model:visible="editVars.modalShow"
        :modalTitle="editVars.modalTitle"
        :childGridRef="supplierGridRef"
        :childEditVars="editVars"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';

//TODO 表单相关配置
import { baseFormConfig } from '../../js/base.form.config.js';
// import { colConfig_1 } from "../../js/col.config.js";

//TODO 表格相关配置
import TableConfig from '../../js/base.vxetable.config.js';

import { standardColumns } from '../../js/table.columns.js';

//TODO 下拉选项配置项
import {  unitsOptions } from '../../js/opts.group.js';

//TODO 代理函数
import { useAjaxProxy } from '../../js/useAjaxProxy.js';
import { apiTopHeader } from '@/api/api-topHeader.js';

// import {message} from "ant-design-vue";

//TODO 导入子组件
import AddStandard from './add-standard.vue';
import { message } from 'ant-design-vue';

//表格节点
const supplierGridRef = ref(null);
let supplierformState = ref({
  // 状态
  // status: '全部',
  // 集料id
  // type: '',
  testingBasisCode: '',
  testingBasis: '',
  page: 1,
  limit: 5,
});

//查询参数
let queryParams = ref({});

//编辑弹窗变量集合
let editVars = ref({
  modalShow: false,
  modalTitle: '添加标准',
  currentId: null,
});

//列表表格基础配置
let supplierTableConfig = TableConfig.getTableConfig_4();

//TODO 表格基础配置扩展
//合并列
supplierTableConfig['columns'] = standardColumns;
//数据代理扩展
supplierTableConfig['proxyConfig']['ajax'] = {};

//TODO 代理扩展函数
supplierTableConfig['proxyConfig']['ajax']['query'] = useAjaxProxy(
  queryParams,
  supplierGridRef,
  apiTopHeader.getTestingBasisPage
);

//集料下拉项集合
let unitsGroupOptions = ref([...unitsOptions]);
unitsGroupOptions.value.unshift({
  label: '全部',
  value: '',
});

//TODO 集料集合下拉项拼接
// const handleJoinGroupOptions = () => {
//   //请求接口
//   apiTopHeader.queryAllAggregates().then((res) => {
//     // console.log(res);
//     if (res.data.code == 0) {
//       if (res.data.data && res.data.data.length == 0) {
//         AggregatesGroupOptions.value = [];
//       } else if (res.data.data && res.data.data.length > 0) {
//         for (let i = 0; i < res.data.data.length; i++) {
//           let tempObj = {};
//           tempObj.label =
//             res.data.data[i]["aggregatesTypeParent"] +
//             "-" +
//             res.data.data[i]["aggregatesType"] +
//             "-" +
//             res.data.data[i]["aggregatesSpecs"];
//           // if(res.data.data[i]["aggregateCategory"]==1){
//           //   tempObj.label=tempObj.label+'-Ⅰ类';
//           // }
//           // if(res.data.data[i]["aggregateCategory"]==2){
//           //   tempObj.label=tempObj.label+'-Ⅱ类';
//           // }
//           // if(res.data.data[i]["aggregateCategory"]==3){
//           //   tempObj.label=tempObj.label+'-Ⅲ类';
//           // }
//           tempObj.value = res.data.data[i]["id"];
//           AggregatesGroupOptions.value.push(tempObj);
//         }
//       }
//     }
//   });
// };
//TODO 列表渲染ok
onMounted(() => {
  // handleJoinGroupOptions();
  // console.log(supplierformState.value);
  queryParams.value = { ...supplierformState.value };
  if (supplierformState.value.status == '全部') {
    queryParams.value.status = '';
  }
  //调用接口
  supplierGridRef.value.commitProxy('query');
});

//TODO 5.8 列表渲染，搜索  添加修改删除

//TODO 搜索 ok

// const handleUnitsSelected = () => {
//   queryParams.value = { ...supplierformState.value };
//   if (supplierformState.value.status == '全部') {
//     queryParams.value.status = '';
//   }
// };

// //状态选择
// const handleStatusChange = (val) => {
//   queryParams.value = { ...supplierformState.value };
//   queryParams.value.status = val;
// };

//input name输入
const handleNameInput = () => {
  // console.log(supplierformState.value);
  queryParams.value = { ...supplierformState.value };
  // if (supplierformState.value.status == '全部') {
  //   queryParams.value.status = '';
  // }
};
//input phone输入
const handlePhoneInput = () => {
  // console.log(supplierformState.value);
  queryParams.value = { ...supplierformState.value };
  // if (supplierformState.value.status == '全部') {
  //   queryParams.value.status = '';
  // }
};

//列表数据查询
const handleListDataQuery = () => {
  //查询参数
  // console.log(queryParams.value);
  supplierGridRef.value.commitProxy('query', ...[true]);
};

//列表重置
const handleListDataReset = () => {
  supplierformState.value = {
    // 状态
    // status: '全部',
    // 集料id
    // type: null,
    testingBasisCode: '',
    testingBasis: '',
    page: 1,
    limit: 5,
  };

  queryParams.value = { ...supplierformState.value };
  // if (supplierformState.value.status == '全部') {
  //   queryParams.value.status = '';
  // }
  supplierGridRef.value.commitProxy('reload');
};

//删除
const deleteEvent = (row) => {
  // console.log(row);

  //调用删除接口
  apiTopHeader.testingBasisDelete(row.id).then((res) => {
    // console.log(res);
    if (res.data.code == 0) {
      message.success('删除成功', 1);
      //刷新表格
      setTimeout(() => {
        supplierGridRef.value.commitProxy('query', ...[true]);
      }, 1500);
    } else {
      message.error('删除失败');
    }
  });
};

// 编辑
const editEvent = (row) => {
  // console.log(row);
  if (row) {
    //编辑 赋值ID
    editVars.value.currentId = row.id;
    editVars.value.item = row;
    editVars.value.modalShow = true;
    editVars.value.modalTitle = '编辑标准';
    
  } else {
    //新增
    editVars.value.currentId = null;
    editVars.value.modalShow = true;
    editVars.value.modalTitle = '添加标准';
  }
};
</script>
<style scoped>
@import '../../../assets/style/vxe-coustom.scss';
.ant-select {
  font-size: inherit;
}
.mod-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}
.success-color {
  color: #ff9393;
}
.btn {
  /*font-size: 0.06rem;*/
  color: #fff;

  padding: 0.02rem 0.08rem;
  display: inline-block;
  border-radius: 0.02rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.05rem;
}
:deep(.ant-btn-primary) {
  height: fit-content;
}

.btn-color1 {
  background: rgba(263, 39, 69, 0.8);
  border: 1px solid rgb(263, 39, 69, 0.8);
}

.btn-color2 {
  background: rgba(250, 180, 33, 0.8);
  border: 1px solid rgb(250, 180, 33, 0.8);
}
/* .close-btn {
  margin-left: auto;
  z-index: 9999;
  background: none;
  border: none;
  font-size: 0.08rem;
  color: white;
} */
</style>
<style >
.msads .vxe-grid--pager-wrapper{
  display: none;
}
</style>
