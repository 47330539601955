<template>
  <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9，待机10-->
  <div class="cfot-item-content-item">
    <div
      class="cfot-item-content-status"
      v-if="
        deviceStatus == 0 || deviceStatus == null || deviceStatus == undefined
      "
    >
      <div class="cfotstars color3"></div>
      <div class="device-status">离线</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 1">
      <div class="cfotstars color2"></div>
      <div class="device-status">准备中</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 2">
      <WarningOutlined class="wricon" />
      <div class="device-status">故障</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 3">
      <div class="cfotstars color1"></div>
      <div class="device-status">就绪</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 4">
      <div class="cfotstars color1 twinkle"></div>
      <div class="device-status">运行中</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 5">
      <div class="cfotstars color1"></div>
      <div class="device-status">完成</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 6">
      <PauseCircleOutlined class="wricon" />
      <div class="device-status">暂停中</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 7">
      <div class="cfotstars color2"></div>
      <div class="device-status">手动</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 8">
      <div class="cfotstars color2"></div>
      <div class="device-status">本地</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 9">
      <WarningOutlined class="wricon" />
      <div class="device-status">急停中</div>
    </div>
    <div class="cfot-item-content-status" v-else-if="deviceStatus == 10">
      <div class="cfotstars color2"></div>
      <div class="device-status">待机</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, onMounted } from 'vue';
import { WarningOutlined, PauseCircleOutlined } from '@ant-design/icons-vue';
const props = defineProps({
  deviceStatus: {
    type: [String, Number],
    required: true,
    default() {
      return null;
    },
  },
});
const { deviceStatus } = toRefs(props);
onMounted(() => {
  // console.log('设备状态', deviceStatus);
});
</script>

<style scoped>
.cfot-item-content-item {
  font-size: 0.07rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cfot-item-content-status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.color1 {
  background: #00ff00;
  box-shadow: 0px 0px 5px #00ff00;
}
.color2 {
  background: #fab421;
  box-shadow: 0px 0px 5px #fab421;
}
.color3 {
  background: #ec2745;
  box-shadow: 0px 0px 5px #ec2745;
}
.cfotstars-warn {
  font-size: 0.1rem;
  color: #ec2745;
  margin-right: 10px;
}
.twinkle {
  animation: star 1.2s linear infinite;
  box-shadow: 0px 0px 10px #00ff00;
}
.twinklewr {
  animation: star 1.2s linear infinite;
}
@keyframes star {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
.device-status {
  font-size: 0.07rem;
  color: white;
}
.wricon {
  color: red;
  font-size: 0.08rem;
  margin-right: 0.05rem;
}
.cfotstars {
  box-sizing: border-box;
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  margin-right: 0.04rem;
}
</style>
