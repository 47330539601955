<template>
  <div class="right-card-2 card">
    <div class="card-top">
      <div class="card-top-left"></div>
      <div class="card-top-center">
        <span>
          {{
            `${particaleGrading?.workId?.slice(0, 4) || '-'}-${
              particaleGrading?.workId?.slice(-4) || '-'
            }`
          }}
        </span>
      </div>
      <div class="card-top-right">
        <div class="tabicon">
          <img
            src="../assets/table.png"
            style="margin-right: 0.04rem; cursor: pointer"
            @click="modlShow"
            v-if="!isCloudPage"
          />
        </div>
      </div>
    </div>
    <div class="card-center">
      <div class="card-center-left">
        <ul>
          <li>
            <div class="title"><span>针片状:</span></div>
            <div class="text-result">
              <span
                >{{
                  particaleGrading?.needleParticleTotalQualityRate?.toFixed(
                    1
                  ) ?? '--'
                }}%</span
              >
            </div>
          </li>
          <li>
            <div class="title"><span>不规则:</span></div>
            <div class="text-result">
              <span
                >{{
                  particaleGrading?.irregularParticleContent?.toFixed(1) ??
                  '--'
                }}%</span
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="card-center-right">
        <ul>
          <li>
            <div class="yuansu"></div>
            <div class="title">试验前:</div>
            <div class="text">
              {{ particaleGrading?.qualityBeforeExperiment?.toFixed(1) ?? '--' }}g
            </div>
          </li>
          <li>
            <div class="yuansu"></div>
            <div class="title">试验后:</div>
            <div class="text">
              {{ particaleGrading?.qualityAfterExperiment?.toFixed(1) ?? '--' }}g
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-bottom" style="flex: 1">
      <v-chart class="chart" ref="mchart" autoresize :option="optionParticle" />
    </div>
    <!--表格弹框-->
    <a-modal
      v-model:visible="logShow"
      width="100%"
      wrap-class-name="table-modal"
      centered
      :closeIcon="true"
    >
      <template #title>
        <div ref="modalTitleRef" style="">颗粒级配（粗集料）</div>
        <a-button class="close-btn" @click="logShow = false">关闭 </a-button>
      </template>
      <div class="modmian" style="padding: 0">
        <div class="modmian-top">
          <div class="modmian-top-item nonum">
            {{ totalData.aggreFateData.testNumber }}
          </div>
          <div class="modmian-top-item">
            {{
              `【${totalData.aggreFateData.aggregatesTypeParent}】 ${totalData.aggreFateData.aggregatesSpecs}`
            }}
          </div>
          <div class="modmian-top-item">
            {{ totalData.aggreFateData.vendorName }}
          </div>
          <div class="modmian-top-item">
            {{ totalData.aggreFateData.manufacturersName }}
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="totalData.tableData"
          :loading="loading"
          :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :pagination="pagination"
          @change="pageChange"
        >
          <template v-slot:bodyCell="{ column, record }">
            <template v-if="column.key === 'aperture'">
              {{ record.aperture ?? '--' }}
            </template>
            <template v-else-if="column.key === 'onSieveQuality'">
              {{ record.onSieveQuality ?? '--' }}
            </template>
            <template v-else-if="column.key === 'gradeScreening'">
              {{ record.gradeScreening ?? '--' }}
            </template>
            <template v-else-if="column.key === 'accumulateScreening'">
              {{ record.accumulateScreening ?? '--' }}
            </template>
            <template v-else-if="column.key === 'detectionResults'">
              {{ record.detectionResults ?? '--' }}
            </template>
          </template>

          <!-- <template #footer>
            <a-table :columns="columns"
              :data-source="data1"
              :showHeader="false"
              :pagination="false">
            </a-table>
          </template> -->
        </a-table>
      </div>
      <template #footer> </template>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import {} from '@ant-design/icons-vue';
import VChart from 'vue-echarts';
import { apiLeft } from '@/api/api-left/api-left.js';
import { message } from 'ant-design-vue';
// import { computed } from 'vue';
import { nextTick } from 'vue';
import tool from './js/tool.js';
import 'echarts/lib/component/markLine';
import chartConfigData from './js/chartConfigData.js';
import { useStore } from 'vuex';
const store = useStore();
const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';

const columns = [
  {
    title: '筛孔尺寸（mm）',
    dataIndex: 'aperture',
    key: 'aperture',
    width: 360,
  },
  {
    title: '分计筛余量(g)',
    dataIndex: 'onSieveQuality',
    width: 360,
    key: 'onSieveQuality',
  },
  {
    title: '分计筛余(%)',
    dataIndex: 'gradeScreening',
    width: 360,
    key: 'gradeScreening',
  },
  {
    title: '累计筛余(%)',
    key: 'accumulateScreening',
    dataIndex: 'accumulateScreening',
    width: 360,
  },
  {
    title: '通过百分率（%）',
    key: 'detectionResults',
    dataIndex: 'detectionResults',
    width: 360,
  },
];
// 弹窗所有数据
const totalData = reactive({
  //表格上当展示的数据信息
  aggreFateData: {},
  // tableTotalCount: 0,
  // 表格数据
  tableData: [
    // {
    //   key: '1',
    //   aperture: '37.6',
    //   onSieveQuality: '100',
    //   gradeScreening: '50',
    //   accumulateScreening: '40',
    //   detectionResults: '66'
    // }
  ],
});
// 请求数据的时候展示加载状态
let loading = ref(true);
// 表格分数数据配置信息
let pagination = ref({
  current: 1,
  // pageSize: 1,
  total: null,
  hideOnSinglePage: true,
  showSizeChanger: false,
  showQuickJumper: true,
  // showTotal: (total) => `总共 ${total / 10} 页数据`, //展示的所有项为页数，每页固定展示10条数据
});
// echarts实例
const mchart = ref(null);
// 颗粒级配所有数据
const particaleGrading = ref({});
// 颗粒级配--自定义markLine的数据
const xAxisTickData = ref(chartConfigData.particlexAxisTick);
// 颗粒级配ecahrts默认数据
let particaleGradingTotalData = ref(chartConfigData.particleEchartsData);
// 颗粒级配ecahrts数据
let ecahrtsParticleData = reactive({
  upperLimitData: [],
  detectionResultsData: [],
  lowerLimitData: [],
});
//展示表格弹窗的布尔值
const logShow = ref(false);
// 颗粒级配--ecahrts配置项数据
const optionParticle = ref(
  tool.getOptions(
    xAxisTickData,
    ecahrtsParticleData,
    5.109,
    1.077,
    false,
    false
  )
);

/**
 * @name 郭冲
 * @Date 2023-05-08 15:38:09
 * @introduction 显示弹窗
 * @description 请求并加载表格数据
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const modlShow = () => {
  logShow.value = true;
  getTableList();
};
/**
 * @name 郭冲
 * @Date 2023-05-08 15:39:45
 * @introduction 表格分页器页数变化的回调
 * @description 设置当前页为选中或输入的页数，并请求对应页的数据
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const pageChange = (page) => {
  let { current } = page;
  pagination.value.current = current;
  // console.log('页码变化', pagination);
  getTableList();
};
/**
 * @name 郭冲
 * @Date 2023-05-08 15:40:14
 * @introduction 获取表格数据
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const getTableList = () => {
  apiLeft
    .getGrainCompositionChart({ page: pagination.value.current })
    .then((res) => {
      if (res.data.code === 0) {
        loading.value = false;
        // console.log('颗粒级配表格数据', res.data);
        // 表格上方展示的信息
        totalData.aggreFateData = res.data.data.aggreFateData;
        // totalData.tableTotalCount = res.data.data.count
        // pagination.value.total = res.data.data.count * 10; //返回的结果count是总页数，每页固定展示10条数据
        // 表格的临时数据
        let tempTableData = res.data.data.data.map((item) => {
          return {
            key: item.id,
            aperture: item.aperture, //筛孔尺寸
            onSieveQuality: item.sieveWeight?.toFixed(1), //筛上重
            gradeScreening: item.sieveResidue?.toFixed(1), //分计筛余
            accumulateScreening: item.accumulateResidue?.toFixed(1), //累计筛余
            detectionResults: item.passRate?.toFixed(1), //通过百分率
          };
        });
        // 排序先把筛孔尺寸和筛底筛后总质量的数据筛选出来
        let numberArray = tempTableData
          .filter(
            (item) => item.aperture != '筛底' && item.aperture != '筛后总质量'
          )
          .sort((a, b) => b.aperture - a.aperture);
        let stringArray = tempTableData.filter(
          (item) => item.aperture == '筛底' || item.aperture == '筛后总质量'
        );
        totalData.tableData = [...numberArray, ...stringArray];
        // console.log('颗粒级配表格数据tableData', totalData.tableData);
        // console.log('颗粒级配aggreFateData', totalData.aggreFateData);
        // console.log('颗粒级配tableTotalCount', totalData.tableTotalCount);
      }
    })
    .catch((error) => {
      message.error(error.data.message, 2);
    });
};

/**
 * @name 郭冲
 * @Date 2023-09-07 11:42:13
 * @introduction 监听器-监听细度模数的结果数据，以及细度模数图表的新数据
 * @description 详细描述
 * @param {any} newValue 变化后的数据
 * @param {any} oldValue 变化前的数据
 * @return 无返回类型
 * @exception [违例类型] [违例类型说明]
 */
watch(
  () =>  store.state.engine.allEngineData, 
  (newValue, oldValue) => {
    const newParticaleGrading = newValue?.particleGradingChartVo || {} //最新检测项的颗粒级配(粗集料)结果数据以及级配图表数据
    const newChartData = newValue?.particleGradingChartVo || {}
    const oldChartData = oldValue?.particleGradingChartVo || {}
    nextTick(() => {
      particaleGrading.value = newParticaleGrading;

      // 设置最新的图表数据
      operateEchartsData(
        newChartData?.chartData || [],
        oldChartData || {},
        newChartData || {}
      );
    });
  },
  { immediate: true }
);
onMounted(() => {});

const operateEchartsData = (newChartsValue, oldDeviceValue, newDeviceValue) => {
  if (Array.isArray(newChartsValue) && newChartsValue.length) {
    let newParticleGradingChart = newChartsValue?.sort(
      (a, b) => a.taylorValue - b.taylorValue
    );
    // 获取得到泰勒值和筛孔组成的刻度值
    getxAxisData(newParticleGradingChart || []);

    // xAxisTickDataTemp和finenessTotalDataTemp用于设置图表的刻度和值的数据
    let particaleGradingTotalDataTemp = [];
    let xAxisTickDataTemp = [];
    particaleGradingTotalData.value = newParticleGradingChart || [];

    // 先清空ecahrtsFinenessData的上下限和实际值数据
    ecahrtsParticleData.upperLimitData = [];
    ecahrtsParticleData.detectionResultsData = [];
    ecahrtsParticleData.lowerLimitData = [];
    // 37.5刻度的值全部为100
    particaleGradingTotalData.value.forEach((item) => {
      if (item.aperture == '37.5') {
        item.detectionResults = 100.0;
        item.lowerLimit = 100.0;
        item.upperLimit = 100.0;
      }
    });
    // 如果上限和下限没有设置时该筛孔尺寸不要
    particaleGradingTotalDataTemp = particaleGradingTotalData.value
      .filter(
        (item) =>
          item.lowerLimit !== null &&
          item.upperLimit !== null &&
          item.lowerLimit !== undefined &&
          item.upperLimit !== undefined &&
          !item.aperture.includes('筛孔尺寸') &&
          item.aperture != '筛后总质量'
      )
      .map((ele) => {
        ele.aperture = tool.formatScreenSize(ele.aperture);
        return ele;
      });

    xAxisTickDataTemp = xAxisTickData.value.filter((item) =>
      particaleGradingTotalDataTemp.some(
        (ele) => tool.formatScreenSize(ele.aperture) == item.name
      )
    );

    // 找到去除上下限为undefined的轴之后，筛选出最大的x轴刻度值
    let initialMax = Math.max(...xAxisTickDataTemp.map((item) => item.xAxis));
    let initialMin = Math.min(...xAxisTickDataTemp.map((item) => item.xAxis));
    // console.log('最初MAX', initialMax);

    // console.log('颗粒级配图表实例', mchart.value);
    if (oldDeviceValue?.workId != newDeviceValue?.workId) {
      optionParticle.value = tool.getOptions(
        xAxisTickDataTemp,
        ecahrtsParticleData,
        initialMax,
        initialMin,
        false,
        false
      );
      mchart.value?.setOption(optionParticle.value, true);
    }

    // 通过百分率值为100,并且上下限达到100，不显示后面的数据
    let firstFullParticaleGrading = particaleGradingTotalDataTemp
      .filter((item) => item.aperture != '筛底')
      .find(
        (item) =>
          item.passRate == 100 &&
          item.upperLimit == 100 &&
          item.lowerLimit == 100
      );
    // console.log("🚀 ~ nextTick ~ firstFullParticaleGrading:", firstFullParticaleGrading)
    xAxisTickData.value.forEach((tickitem, tickindex) => {
      // console.log("🚀 ~ xAxisTickData.value.forEach ~ tickitem:", tickitem.name,tickitem.name ==firstFullParticaleGrading?.aperture)
      if (tickitem?.name == firstFullParticaleGrading?.aperture) {
        // console.log("🚀 ~ nextTick ~ xAxisTickMax", xAxisTickData.value[tickindex]?.xAxis)
        optionParticle.value.xAxis.max =
          Number(tickitem?.xAxis || initialMax) + 0.01;
        mchart.value?.setOption(optionParticle.value, true);
      }
    });
    // 转换数据格式，图表展示用
    tool.formatData(
      particaleGradingTotalDataTemp,
      ecahrtsParticleData,
      xAxisTickDataTemp
    );
    // console.log("🚀 ~ nextTick ~ ecahrtsParticleData:", ecahrtsParticleData)
    // console.log("🚀 ~ nextTick ~ xAxisTickDataTemp:", xAxisTickDataTemp)
    // console.log("🚀 ~ nextTick ~ particaleGradingTotalDataTemp:", particaleGradingTotalDataTemp)
  } else {
    console.log('颗粒级配（粗集料）数据为空或有误');
  }
};
/**
 * @introduction 泰勒值和筛孔组成的刻度值
 * @param { Array } originData sse推送的最新的echarts原始数据
 */
const getxAxisData = (originData) => {
  if (originData.length > 0) {
    xAxisTickData.value = originData.map((item) => {
      return {
        name: item.aperture,
        xAxis: item.taylorValue,
      };
    });
  }
};
</script>

<style scoped lang="scss">
@import '../../static/font.css';
@import '../assets/style/common.scss';
.right-card-2 {
  .card-top {
    height: 16%;
    background: url(../assets/left/particle/particle.png) center/100% no-repeat;
    .card-top-left {
      width: 55%;
    }
    .card-top-center {
      width: 35%;
      font-family: 'PangMenZhengDao', sans-serif;
      span {
        font-size: 0.09rem;
      }
    }
    .card-top-right {
      width: 10%;
      .tabicon {
        text-align: right;
        img {
          width: 0.12rem;
          height: 0.12rem;
          opacity: 0.6;
        }
      }
    }
  }

  .card-center {
    height: 25%;
    .card-center-left {
      width: 1.2733rem;
      height: 0.45rem;
      margin-left: 2%;
      background: url(../assets/left/particle/jieguo.png) 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .card-center-right {
      width: 50%;
      height: 100%;
    }
  }
  .card-bottom {
    height: 55%;
  }
  .modmian-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.08rem;
    margin-bottom: 0.08rem;
  }
  .modmian-top-item {
    margin-right: 0.08rem;
    color: #fff;
  }
  .modmian-top .nonum {
    color: #e7a10b;
  }
}
// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
</style>
