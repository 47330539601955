<template>
  <a-modal
    :visible="props.visible"
    width="100%"
    wrap-class-name="table-modal"
    centered
    class="sample-list"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">样品列表</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <div class="modmian" style="padding: 0">
      <diV class="search">
        <div class="search-left">
          <a-form
            :model="formState"
            style="width: 100%"
            :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
            :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
          >
            <a-row>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="状态">
                  <a-select
                    v-model:value="formState.statusList"
                    placeholder="请选择状态"
                    :allowClear="true"
                    mode="tags"
                    :options="optionsStatus"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="执行标准">
                  <a-select
                    v-model:value="formState.executionStandard"
                    placeholder="请选择执行标准"
                    :allowClear="true"
                    :options="executionStandardOptions"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="集料分类">
                  <a-select
                    v-model:value="formState.aggregatesType"
                    :options="aggregatesTypeOptions"
                    placeholder="请选择集料分类"
                    allowClear
                    @change="handleTypeChange"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="规格型号">
                  <a-select
                    v-model:value="mspecificationMode"
                    :options="specificationModeOptions"
                    placeholder="请选择规格型号"
                    allowClear
                    @change="onSpecificate"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="供货单位">
                  <a-select
                    v-model:value="formState.supplierName"
                    :options="optionsSupplier"
                    :allowClear="true"
                    placeholder="请选择供货单位"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="生产厂家">
                  <a-select
                    v-model:value="formState.producerName"
                    placeholder="请选择生产厂家"
                    :allowClear="true"
                    :options="optionsProducer"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="检测单位">
                  <a-select
                    v-model:value="formState.testingUnitName"
                    :options="optionsTestUnit"
                    :allowClear="true"
                    placeholder="请选择检测单位"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="12" :sm="24" :xs="24">
                <a-form-item label="检测日期">
                  <a-range-picker
                    v-model:value="testingTime"
                    show-time
                    :locale="locale"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    :placeholder="['开始时间', '结束时间']"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="search-right">
          <a-space :size="'small'">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button type="primary" @click="resetSearch">重置</a-button>
          </a-space>
        </div>
      </diV>
      <a-table
        ref="sampleTableList"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :scroll="{ x: '110%' }"
        :pagination="pagination"
        @change="pageChange"
      >
        <template v-slot:bodyCell="{ column, record }">
          <!-- 执行标准 -->
          <template v-if="column.key === 'executionStandard'">
            {{ getExecutionStandard(record?.executionStandard) }}
            <!-- <span v-if="record.executionStandard.includes('highway')">
              <img
                src="../../../assets/mainbottom/gong.svg"
                class="use-standard"
              />
              {{ getExecutionStandard(record?.executionStandard) }}
            </span>
            <span v-else-if="record.executionStandard.includes('national')">
              <img
                src="../../../assets/mainbottom/guo.svg"
                class="use-standard"
              />
              {{ getExecutionStandard(record?.executionStandard) }}
            </span>
            <span v-else-if="record.executionStandard.includes('railway')">
              <img
                src="../../../assets/mainbottom/tie.svg"
                class="use-standard"
              />
              {{ getExecutionStandard(record?.executionStandard) }}
            </span>
            <span v-else-if="record.executionStandard.includes('hydraulic')">
              <img
                src="../../../assets/mainbottom/shui.svg"
                class="use-standard"
              />
              {{ getExecutionStandard(record?.executionStandard) }}
            </span> -->
          </template>
          <!-- 集料规格 -->
          <template v-if="column.key === 'aggregatesSpecs'">
            {{
              record.aggregatesSpecs
                .split('-')
                .slice(2, record.aggregatesSpecs.split('-').length)
                .join('-') ?? '--'
            }}
          </template>
          <!-- 供货单位 -->
          <template v-if="column.key === 'supplierName'">
            {{ record.supplierName ?? '--' }}
          </template>
          <!-- 生产厂家 -->
          <template v-if="column.key === 'producerName'">
            {{ record.producerName ?? '--' }}
          </template>
          <!-- 检测项目 -->
          <template v-if="column.key === 'detectionItem'">
            {{ record.detectionItem ?? '--' }}
          </template>
          <!-- 检测单位 -->
          <template v-if="column.key === 'testingUnitName'">
            {{ record.testingUnitName ?? '--' }}
          </template>
          <!-- 取样时间 -->
          <template v-if="column.key === 'samplingTime'">
            {{ record.samplingTime ?? '--' }}
          </template>
          <!-- 检测时间 -->
          <template v-if="column.key === 'detectDatetime'">
            {{ record.detectDatetime ?? '--' }}
          </template>
          <!-- 材料等级 -->
          <!-- <template v-if="column.key === 'aggregateGrade'">
              {{ record.aggregateGrade ?? '--' }}
            </template> -->
          <!-- 等级价格 -->
          <!-- <template v-if="column.key === 'aggregatePrice'">
              {{ record.aggregatePrice ?? '--' }}
            </template> -->
          <!-- 状态 -->
          <template v-if="column.key === 'status'">
            <div class="typeTag offline-color" v-if="record.status == 1">
              待检测
            </div>
            <div class="typeTag detecting-color" v-if="record.status == 2">
              检测中
            </div>
            <div class="typeTag success-color" v-if="record.status == 3">
              完成
            </div>
            <div class="typeTag hitch-color" v-if="record.status == 4">
              未完成
            </div>
            <div class="typeTag none-color" v-if="record.status == null">
              --
            </div>
          </template>
          <template v-if="column.key === 'operate'">
            <div v-if="record.status == 2 || record.status == 4 || record.status == null">--</div>
            <div v-if="record.status == 1" class="under_detection">
              <a-button
                type="link"
                :size="'middle'"
                @click="editSample(record)"
              >
                编辑
              </a-button>
              <a-popconfirm
                title="确定删除吗?"
                @confirm="deleteSample(record.testNumber)"
              >
                <a-button type="link" :size="'middle'" danger> 删除 </a-button>
              </a-popconfirm>
              <!-- <a-button
                  type="link"
                  :size="'middle'"
                  danger
                  @click="deleteSample(record.testNumber)"
                >
                  删除
                </a-button> -->
            </div>
            <div class="under_detection">
              <a-button
                type="link"
                :size="'middle'"
                v-if="record.status == 3"
                @click="showRecord(record)"
              >
                内控记录
              </a-button>
              <!-- <a-button
                  type="link"
                  :size="'middle'"
                  v-if="record.status == 3 || record.status == 4"
                  @click="showRecord(record)"
                >
                  打印
                </a-button> -->
              <a-button
                type="link"
                :size="'middle'"
                v-if="record.status == 3"
                @click="showReport(record)"
              >
                检测报告
              </a-button>
            </div>
          </template>
        </template>
      </a-table>

      <!-- 取样和样品登记 -->
      <sampleRegist
        v-if="isRegiste"
        v-model:visible="isRegiste"
        :registerName="registerName"
        :detailId="detailId"
        @refresh-samplist="handleSearch"
      >
      </sampleRegist>

      <!-- 检测记录 -->
      <detectionRecord
        v-if="aggregateRecordShow"
        v-model:visible="aggregateRecordShow"
        ref="aggregateRecordRef"
        :recordData="recordData"
      ></detectionRecord>

      <!-- 内控记录 -->
      <sampleRecord
        v-if="recordShow"
        v-model:visible="recordShow"
        ref="recordRef"
        :testNumber="testNumber"
      ></sampleRecord>

      <!-- 检测报告 -->
      <sampleReport
        v-if="reportShow"
        v-model:visible="reportShow"
        ref="reportRef"
        :testNumber="testNumber"
        :executionStandard="executionStandard"
      ></sampleReport>
    </div>
    <template #footer> </template>
  </a-modal>
</template>

<script setup>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import { apiMainfoots } from '@/api/api-main-foots/api-main-foots.js';
import { message } from 'ant-design-vue';
import detectionRecord from './detection-record.vue';
import sampleReport from '../sample/sample-report.vue';
import sampleRecord from '../sample/sample-record.vue';
import sampleRegist from './sample-regist.vue';
import { typeOptions } from '../../js/opts.group.js';
import { apiCenterBottom } from '@/api/api-center-bottom/api-center-bottom.js';
// import { useStore } from 'vuex';
// let store = useStore();
// import { useRouter } from 'vue-router';
// const router = useRouter();
const emits = defineEmits(['update:visible']);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
});

// 检测报告需要的执行标准
const executionStandard = ref('nationalStandard');
// 检测报告和内控记录需要的检测单号
const testNumber = ref('');

// 取样和样品登记编辑标题
const registerName = ref('');

// 是否显示取样或样品登记
const isRegiste = ref(false);

// 获取的取样或样品登记的id
let detailId = ref({});

// 内控记录表显示
const aggregateRecordShow = ref(false);
// 检测报告显示
const reportShow = ref(false);
// 内控记录显示
const recordShow = ref(false);

// 样品列表---集料分类数据字典
const aggregatesTypeOptions = ref(typeOptions);
// 样品列表---执行标准数据字典
const executionStandardOptions = ref([]);
// const executionStandardOptions = ref(store.state.engine.executionStandardOptions);
// 样品列表---规格型号数据字典
const specificationModeOptions = ref([]);
// // 集料分类父类
// const aggregatesTypeParent = ref(typeOptions[0].label);
// // 集料分类子类
// const aggregatesType = ref(typeOptions[0].options[0].label);
//样品列表---供货单位数据字典
const optionsSupplier = ref([]);
//样品列表---生产厂家数据字典
const optionsProducer = ref([]);
//样品列表---检测单位数据字典
const optionsTestUnit = ref([]);
// 样品列表---状态数据字典
const optionsStatus = ref([
  {
    value: 1,
    label: '待检测',
  },
  {
    value: 2,
    label: '检测中',
  },
  {
    value: 3,
    label: '完成',
  },
  {
    value: 4,
    label: '未完成',
  },
]);

// 样品列表弹窗---表格加载数据
let loading = ref(true);
// 样品列表弹窗---表格列配置源
const columns = [
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    width: '3%',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '执行标准',
    dataIndex: 'executionStandard',
    key: 'executionStandard',
    width: '6%',
    ellipsis: true,
    fixed: 'left',
    align: 'left',
  },
  {
    title: '检测单号',
    dataIndex: 'testNumber',
    key: 'testNumber',
    width: '6%',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '集料规格',
    dataIndex: 'aggregatesSpecs',
    key: 'aggregatesSpecs',
    width: '4%',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '供货单位',
    dataIndex: 'supplierName',
    key: 'supplierName',
    ellipsis: true,
    width: '5%',
    align: 'left',
  },
  {
    title: '生产厂家',
    key: 'producerName',
    dataIndex: 'producerName',
    ellipsis: true,
    width: '5%',
    align: 'left',
  },
  {
    title: '检测项目',
    key: 'detectionItem',
    dataIndex: 'detectionItem',
    width: '7%',
    ellipsis: true,
    align: 'center',
  },
  {
    title: '检测单位',
    key: 'testingUnitName',
    dataIndex: 'testingUnitName',
    width: '5%',
    ellipsis: true,
    align: 'center',
  },
  {
    title: '取样时间',
    key: 'samplingTime',
    dataIndex: 'samplingTime',
    width: '5%',
    ellipsis: true,
    align: 'center',
  },
  {
    title: '检测时间',
    key: 'detectDatetime',
    dataIndex: 'detectDatetime',
    width: '5%',
    ellipsis: true,
    align: 'center',
  },
  // {
  //   title: '材料等级',
  //   key: 'aggregateGrade',
  //   dataIndex: 'aggregateGrade',
  //   width: '5%',
  //   ellipsis: true,
  //   align: 'center',
  // },
  // {
  //   title: '等级价格',
  //   key: 'aggregatePrice',
  //   dataIndex: 'aggregatePrice',
  //   width: '5%',
  //   ellipsis: true,
  //   align: 'center',
  // },
  {
    title: '操作',
    key: 'operate',
    dataIndex: 'operate',
    width: '7%',
    align: 'center',
    fixed: 'right',
  },
];
// 样品列表弹窗---表格数据源
let data = ref([]);
let mspecificationMode = ref(undefined);
//样品列表弹窗---表格查询条件数据
let formState = ref({
  aggregatesType: undefined,
  // specificationMode: undefined,
  supplierName: undefined,
  producerName: undefined,
  testingUnitName: undefined,
  beginTime: '',
  endTime: '',
  statusList: [],
});
// 检测日期
const testingTime = ref([]);

// 样品列表弹窗---弹窗表格分页器配置项
let pagination = ref({
  current: 1,
  pageSize: 10,
  total: null,
  pageSizeOptions: ['5', '8', '10', '13'],
  showSizeChanger: true,
  showQuickJumper: true,
  responsive: true,
  showTotal: (total) => `总共 ${total} 条数据`,
});
onMounted(() => {
  // 样品列表弹窗---展示默认的表格数据
  handleSearch();
  searchSpecificationMode();
  getSupplier();
  getProducer();
  getTestUnit();
  searchExecutionStandard();
});
/**
 * @introduction 样品列表---获取供货单位数据字典
 *  * @param { Object } type //1供货单位 2生产厂家
 */
const getSupplier = () => {
  apiMainfoots
    .getSupplier({ type: 1 })
    .then((res) => {
      // console.log('🚀 ~ .then ~ res:', res);
      if (res.data.code == 0) {
        optionsSupplier.value = res.data.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
      }
      // console.log('供货单位', options1.value);
    })
    .catch((error) => {
      message.error(error.data.message, 2);
    });
};
/**
 * @introduction 样品列表---获取生产厂家数据字典
 *  * @param { Object } type //1供货单位 2生产厂家
 */
const getProducer = () => {
  apiMainfoots
    .getSupplier({ type: 2 })
    .then((res) => {
      if (res.data.code == 0) {
        optionsProducer.value = res.data.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
      }
      // console.log('生产厂家', options1.value);
    })
    .catch((error) => {
      message.error(error.data.message, 2);
    });
};
/**
 * @introduction 样品列表---获取检测单位数据字典
 *  * @param { Object } type //1供货单位 2生产厂家
 */
const getTestUnit = () => {
  apiMainfoots
    .getSupplier({ type: 5 })
    .then((res) => {
      if (res.data.code == 0) {
        optionsTestUnit.value = res.data.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
      }
      // console.log('检测单位', options1.value);
    })
    .catch((error) => {
      message.error(error.data.message, 2);
    });
};
/**
 * @introduction 获取执行标准
 */
const searchExecutionStandard = async () => {
  try {
    const res = await apiCenterBottom.getExecutionStandards();
    if (res.data.code == 0) {
      executionStandardOptions.value = res.data.data.map((item) => {
        return {
          value: item.standardValue,
          svalue: item.standardValue,
          label: item.standardName,
        };
      });
    }
  } catch (error) {
    message.error('获取执行标准失败', 2);
  }
};
/**
 * @introduction 获取指定标准
 */
const getExecutionStandard = (executionStandard) => {
		return executionStandardOptions.value.find((element) => element.value == executionStandard)?.label
	}
/**
 * @introduction 集料分类改变事件
 */
const handleTypeChange = () => {
  mspecificationMode.value = undefined;
  searchSpecificationMode();
};
const onSpecificate = () => {
  // formState.value.aggregatesType =
  //   formState.value.aggregatesType + "-" + mspecificationMode.value;
};

//获取规格型号
const searchSpecificationMode = () => {
  apiCenterBottom
    .aggregatesTypeGroup({
      aggregatesTypeParent: formState.value.aggregatesType?.split('-')[0],
      aggregatesType: formState.value.aggregatesType?.split('-')[1],
    })
    .then((res) => {
      if (res.data.code == 0) {
        specificationModeOptions.value = res.data.data;
      }
    })
    .catch(() => {
      message.error('获取规格型号失败', 2);
    });
};
/**
 * @name 郭冲
 * @Date 2023-05-08 15:43:57
 * @introduction 样品列表弹窗---分页器页码变化的回调
 * @description 详细描述
 * @param { Number } page 页码
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const pageChange = (page) => {
  let { current, pageSize } = page;
  pagination.value.current = current;
  pagination.value.pageSize = pageSize;
  // console.log('页码变化', pagination);
  handleSearch();
};

/**
 * @name 郭冲
 * @Date 2023-09-04 10:10:58
 * @introduction 重置查询
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const resetSearch = () => {
  formState.value = {
    aggregatesType: undefined,
    // specificationMode: undefined,
    supplierName: undefined,
    producerName: undefined,
    beginTime: '',
    endTime: '',
    statusList: [],
  };
  testingTime.value = [];
  handleSearch();
};

/**
 * @name 郭冲
 * @Date 2023-05-08 15:47:46
 * @introduction 样品列表弹窗---样品列表查询事件
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const handleSearch = () => {
  //获取样品数据携带的参数
  let mdass = JSON.parse(JSON.stringify(formState.value));
  if (mspecificationMode.value != '' && mspecificationMode.value != undefined) {
    mdass.aggregatesType =
      mdass.aggregatesType + '-' + mspecificationMode.value;
  }

  let params = {
    page: pagination.value.current,
    limit: pagination.value.pageSize,
    ...mdass,
    beginTime: testingTime.value[0],
    endTime: testingTime.value[1],
  };
  // 样品列表弹窗---获取样品列表数据
  apiMainfoots
    .getSampleList(params)
    .then((res) => {
      if (res.data.code == 0) {
        loading.value = false;
        pagination.value.total = res.data.data.count;
        // console.log('样品列表', res.data.data.data);
        data.value = res.data.data.list.map((item) => {
          // 样品列表弹窗
          let sampling = { result: null }; // 取样（每一项反对应的取样）
          let moisture = { result: null }; // 含水（每一项...）
          let mudcontent = { result: null }; // 含泥（每一项...）
          let needleParticle = { result: null }; // 颗粒级配--针片状颗粒（每一项...）
          let irregularParticle = { result: null }; // 颗粒级配--不规则颗粒（每一项...）
          let finenessModulus = { result: null }; // 细度模数（每一项...）
          let methyleneBlue = { result: null }; // 亚甲蓝（每一项...）
          item.sampleDetails?.forEach((element) => {
            switch (element.detections) {
              case '取样':
                sampling.result = element.detectionResults;
                break;
              case '含水率':
                moisture.result = element.detectionResults;
                break;
              case '含泥率':
                mudcontent.result = element.detectionResults;
                break;
              case '颗粒级配':
                needleParticle.result = JSON.parse(
                  element.detectionResults
                )?.needleParticleTotalQualityRate;
                irregularParticle.result = JSON.parse(
                  element.detectionResults
                )?.irregularParticleContent;
                break;
              case '细度模数':
                finenessModulus.result = element.detectionResults;
                break;
              case 'MB值':
                methyleneBlue.result = element.detectionResults;
                break;
            }
          });
          if (item.detectionItem) {
            let mdas = item.detectionItem.split(',').map((detect) => {
              if (detect == '含泥率') {
                return '含泥量';
              }
              if (detect == '颗粒级配') {
                return '颗粒级配(粗)';
              }
              if (detect == '细度模数') {
                return '颗粒级配(细)';
              }
              if (detect == 'MB值') {
                return `${detect} - ${item.detectionMethod}`;
              }
              return detect;
            });
            item.detectionItem = mdas.join('、');
          }
          return {
            ...item,
            sampling: sampling, // 取样
            moisture: moisture, // 含水
            mudcontent: mudcontent, // 含泥
            needleParticle: needleParticle, // 颗粒级配--针片状颗粒
            irregularParticle: irregularParticle, // 颗粒级配--不规则颗粒
            finenessModulus: finenessModulus, // 细度模数
            methyleneBlue: methyleneBlue, // 亚甲蓝
          };
        });
        // console.log('样品列表data', data.value);
      }
    })
    .catch((error) => {
      loading.value = false;
      console.log(error);
      message.error(error.data.message, 2);
    });
};

const showReport = (row) => {
  // console.log('🚀 ~ showReport ~ row:', row);
  // router.push('/report');
  if (!row.detectionItem) {
    message.warning("无检测数据", 2);
    return;
  }
  if (sessionStorage.getItem("erpToken")) {
    reportShow.value = true;
    testNumber.value = row.testNumber;
    executionStandard.value = row.executionStandard;
  } else {
    message.warning("打开失败");
    console.error("sse推送数据缺失erptoken");
  }
};

// 样品记录每一项的数据，点开记录表时传到子组件的值
let recordData = ref({});
/**
 * @name 郭冲
 * @Date 2023-08-05 08:59:42
 * @introduction 显示检测记录表弹窗
 * @description 将当前点击的检测记录传给子组件
 * @param {Object} record 样品列表某一行的数据信息
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const showRecord = (row) => {
  // console.log('🚀 ~ showRecord ~ row:', row);
  // Object.assign(recordData.value, row);
  // aggregateRecordShow.value = true;
  // if (row.detectionItem) {
  //   message.warning('无检测数据', 2);
  //   return;
  //
  if (sessionStorage.getItem("erpToken")) {
    recordShow.value = true;
    testNumber.value = row.testNumber;
  } else {
    message.warning("打开失败");
    console.error("sse推送数据缺失erptoken");
  }
};

const aggregateRecordRef = ref(null);

/**
 * @name 郭冲
 * @Date 2023-08-29 08:58:06
 * @introduction 编辑待检测状态的取样或样品登记项
 * @description 详细描述
 * @param {Object} record 检测项的所有数据信息
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const editSample = (record) => {
  // console.log('🚀 ~ file: sample-list.vue:563 ~ editSample ~ record:', record);
  record.samplingSwitch == 1
    ? (registerName.value = '编辑-取样登记')
    : (registerName.value = '编辑-样品登记');
  detailId.value = record.id;
  isRegiste.value = true;
};
/**
 * @name 郭冲
 * @Date 2023-08-29 09:17:59
 * @introduction 删除待检测状态的取样或样品登记项
 * @description 详细描述
 * @param {String | Number} testNumber 检测项的编号
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const deleteSample = (testNumber) => {
  let params = {
    testNumber: testNumber,
  };
  apiMainfoots
    .deleteUnderDetection(params)
    .then((res) => {
      if (res.data.code == 0) {
        message.success('删除成功', 2);
        // 刷新数据
        handleSearch();
      }
    })
    .catch((error) => {
      console.error(error);
      message.error('删除失败', 2);
    });
};
/**
 * @introduction 关闭弹窗
 */
const closeModal = () => {
  emits('update:visible', false);
};
</script>

<style lang="scss" scoped>
@import '../../../assets/style/modalcoustom.scss';
:deep(.ant-table-cell-fix-left) {
  background-color: #27354d !important;
}
:deep(.ant-table-cell-fix-right) {
  background-color: #27354d !important;
}
.use-standard {
  max-width: 0.1rem;
  max-height: 0.125rem;
  margin-right: 0.03rem;
}
.success-color {
  color: #5bde75;
}
.detecting-color {
  color: #d1ff99;
}
.offline-color {
  color: #fdb474;
}
.none-color {
  color: #fff;
}
.hitch-color {
  color: #e46570;
}
.ant-btn-link {
  border-color: #1890ff;
}
.btns {
  font-size: 0.07rem;
  color: #fff;
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
  padding: 0.02rem 0.08rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.02rem;
  width: 0.45rem;
  cursor: pointer;
}
.btns .bticon {
  font-size: 0.08rem;
  margin-left: 0.04rem;
}
:deep(.ant-pagination-total-text) {
  color: white;
}
:deep(.ant-pagination-options-quick-jumper) {
  color: white;
}
:deep(.ant-form-item-label > label) {
  color: white;
  font-size: 0.08rem;
}
:deep(.ant-pagination-jump-prev) {
  display: none !important;
}
:deep(.ant-pagination-jump-next) {
  display: none !important;
}
:deep(
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus
  ) {
  color: #ff7875;
  border-color: #ff7875;
  background: transparent;
}
:deep(.ant-btn-dangerous) {
  color: #ff7875;
  border-color: #ff7875;
  background: transparent;
}
:deep(.ant-btn-dangerous:not(:focus)) {
  border-color: #ff7875;
}
// :deep(.ant-table-tbody > tr > td) {
//   background-color: #273349 !important;
// }
// :deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table
//     tfoot
//     > tr
//     > th, .ant-table tfoot > tr > td) {
//   background-color: #273349 !important;
// }
.close-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  float: right;
  z-index: 9999;
  background: none;
}
.modal-name {
  width: 100%;
  display: flex;
  justify-content: center;
}
// .close-btn {
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   margin-left: auto;
//   color: white;
// }
.download-btn {
  background: none;
  color: white;
}
.under_detection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.search {
  display: flex;
  .search-left {
    flex: 1;
  }
  .search-right {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
