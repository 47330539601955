/**
 * 登录状态管理
 */
import axios from 'axios';
import setting from "../../config/setting";
import { Socket } from "../../config/Socket";
// import store from "@/store";
import { detectionSse } from '@/config/Sse'

export default {
    namespaced: true,
    state: {
        // 当前用户信息
        user: setting.takeUser(),
        // socket监听
        socket: new Socket(),
        //socket状态
        socketStatus: false,
        detectionSseLocal: null,
        detectionSseOnlie: null,
    },
    mutations: {
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },
        // 修改token
        SET_TOKEN(state, obj) {
            setting.cacheToken(obj.token, obj.remember);
            state.token = obj.token;
            if (!obj.token) {
                state.user = {};
                state.menus = null;
                state.tabs = [];
                setting.cacheUser();
            }
        },
    },
    actions: {
        /**
         * @introduction 初始化sse连接
         */
        initSse({ commit }) {
            this.detectionSseLocal = new detectionSse()
            // console.log("🚀 ~ initSse ~ detectionSseLocal:", this.detectionSseLocal)
            commit('SET', { key: 'detectionSseLocal', value: this.detectionSseLocal });
            this.detectionSseLocal.initSee()
        },
        /**
         * @introduction 初始化线上sse连接
         */
        initOnlieSse({ commit }, payload) {
            this.detectionSseOnlie = new detectionSse(true, payload)
            commit('SET', { key: 'detectionSseOnlie', value: this.detectionSseOnlie });
            this.detectionSseOnlie.initSee()
        },
        /**
         * 缓存token
         * @param commit
         * @param token {String, {token: String, remember: String}}
         */
        setToken({ commit }, token) {
            let remember = true;
            if (typeof token === 'object') {
                remember = token.remember;
                token = token.token;
            }
            commit('SET_TOKEN', { token: token, remember: remember });
        },
        /**
         * 移除token
         * @param commit
         */
        removeToken({ commit }) {
            commit('SET_TOKEN', {});
        },
        /**
         * 设置socket状态
         * @param commit
         * @param socketStatus
         */
        setSocketStatus({ commit }, socketStatus) {
            commit('SET', { key: 'socketStatus', value: socketStatus });
        },

        /**
         * 设置用户信息
         * @param commit
         * @param user {Object} 用户信息
         */
        setUser({ commit }, user) {
            setting.cacheUser(user);
            commit('SET', { key: 'user', value: user });
        },
        /**
         * 获取用户信息
         * @returns {Promise<unknown>}
         */
        getUser() {
            return new Promise((resolve, reject) => {
                // store.state.user.socket.initWebSocket();
                axios.get(setting.userUrl).then((res) => {
                    const result = setting.parseUser ? setting.parseUser(res.data) : res.data;
                    if (result.code === 0) {
                        const user = result.data;
                        setting.cacheUser(user);
                        setting.saveUserRole(user.roles[0].roleCode);
                        //store.dispatch('user/getMenus',user)
                        resolve();
                    } else if (result.msg) {
                        this.$message.error(result.msg);
                        return reject(new Error(result.msg));
                    }
                }).catch((e) => {
                    this.$message.error(e.message);
                    return reject(new Error(e.message));
                })
            })
        },
        /*
        移除user
         */
        removeUser({ commit }) {
            commit('SET', { key: 'user', value: {} });
        }
    },
}
