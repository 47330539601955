<template>
  <a-modal
    :visible="props.visible"
    width="50%"
    wrap-class-name="qy-modal"
    centered
    class="registe-list"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">{{ action == 'add' ? '添加' : '编辑' }}账号</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <a-spin :spinning="loading">
      <div class="modlcont" style="padding: 0 0.5rem">
        <a-form
          name="custom_form"
          ref="modalRef"
          :model="formState"
          @sumbmit="handleSubmit"
          :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
          :wrapper-col="{ span: 24 }"
          :rules="rules"
        >
          <a-form-item label="账号" name="account">
            <div class="fromline">
              <a-col :span="22">
                <a-input
                  v-model:value="formState.account"
                  :disabled="action == 'edit'"
                  placeholder="请输入账号"
                />
              </a-col>
            </div>
          </a-form-item>
          <a-form-item label="姓名" name="name">
            <div class="fromline">
              <a-col :span="22">
                <a-input
                  v-model:value="formState.name"
                  placeholder="请输入姓名"
                />
              </a-col>
            </div>
          </a-form-item>
          <a-form-item label="角色" name="role">
            <div class="fromline">
              <a-col :span="22">
                <a-select
                  v-model:value="formState.role"
                  :options="optionsRole"
                  placeholder="请选择角色"
                >
                </a-select>
              </a-col>
            </div>
          </a-form-item>
          <a-form-item label="状态" name="status">
            <div class="agrund">
              <div
                class="agrund-item"
                v-for="(item, index) in statusOptions"
                :class="{ agrundActive: item.isCk }"
                :key="index"
                @click="handleStatusChange(item, index)"
              >
                {{ item.name }}
              </div>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
    <template #footer>
      <div
        class="footes"
        style="padding-bottom: 0.06rem"
        html-type="submit"
        @click="handleSubmit"
      >
        <div class="mbtns stars">
          <span>确定</span>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits } from 'vue';
import { apiTopHeader } from '@/api/api-topHeader.js';
import { message } from 'ant-design-vue';

const emits = defineEmits(['update:visible', 'refreshList']);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
  record: {
    type: Object,
    default() {
      return {};
    },
  },
});
const loading = ref(false);
const action = ref('add');
const formState = ref({
  account: undefined,
  name: undefined,
  role: undefined,
  status: 1,
});
let modalRef = ref();
//处理状态选择
const handleStatusChange = (item, index) => {
  //重置所有状态
  statusOptions.value.forEach((item) => {
    item.isCk = false;
  });
  //选中
  if (statusOptions.value[index].isCk) {
    //选中取反
    statusOptions.value[index].isCk = false;
  } else {
    statusOptions.value[index].isCk = true;
  }
  formState.value.status = item.value;
};
//状态opts
const statusOptions = ref([
  {
    name: '启用',
    value: 1,
    isCk: true,
  },
  {
    name: '停用',
    value: 0,
    isCk: false,
  },
]);
const optionsRole = ref([
  {
    value: '管理员',
    label: '管理员',
  },
  {
    value: '操作员',
    label: '操作员',
  },
]);
const rules = {
  account: [
    {
      required: true,
      message: '请输入账号',
      trigger: 'blur',
    },
  ],
  name: [
    {
      required: true,
      message: '请输入姓名',
      trigger: 'blur',
    },
  ],
  role: [
    {
      required: true,
      type: 'string',
      message: '请选择角色',
      trigger: 'blur',
    },
  ],
  status: [
    {
      required: true,
      message: '请输选择状态',
      trigger: 'blur',
    },
  ],
};
onMounted(() => {
  if (JSON.stringify(props.record) != '{}') {
    action.value = 'edit';
    // getDetail(props.detailId);
  }
});

/**
 * @introduction 获取集料类别详情
 */
// const getDetail = (id) => {
//   loading.value = true;
//   apiTopHeader
//     .getCategoryDetail({ id: id })
//     .then((res) => {
//       if (res.data.code == 0) {
//         Object.assign(formState.value, res.data.data[0]);
//         statusOptions.value.forEach((item) => {
//           if (formState.value.status == item.value) {
//             item.isCk = true;
//           } else {
//             item.isCk = false;
//           }
//         });
//         loading.value = false;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       message.error(error.data.message, 2);
//     });
// };
/**
 * @introduction 编辑新增保存
 */
const handleSubmit = async () => {
  const valid = await modalRef.value.validate();
  if (!valid) return;
  // 编辑
  if (props.detailId) {
    apiTopHeader
      .editCategoryClasification({ ...formState.value })
      .then((res) => {
        if (res.data.code == 0) {
          closeModal();
          emits('refreshList');
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.data.message, 2);
      });
  } else {
    // 新增
    apiTopHeader
      .addCategoryClasification({ ...formState.value })
      .then((res) => {
        if (res.data.code == 0) {
          closeModal();
          emits('refreshList');
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.data.message, 2);
      });
  }
};
/**
 * @introduction 关闭弹窗
 */
const closeModal = () => {
  emits('update:visible', false);
};
</script>

<style lang="scss" scoped>
:deep(.ant-checkbox + span) {
  color: white;
}
:deep(.ant-checkbox-disabled + span) {
  color: rgb(167, 167, 167);
}
:deep(.ant-checkbox-disabled .ant-checkbox-inner) {
  background-color: gray;
}
:deep(.ant-form label) {
  color: white;
}
.registe-list {
  min-width: 720px !important;
}
.regist-type {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
.fromline {
  display: flex;
  align-items: center;
  position: relative;
}
.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.agrund-item {
  width: 30%;
  margin-right: 1%;
  padding: 0.04rem 0;
  text-align: center;
  background: rgba(18, 27, 37, 0.8);
  border: 1px solid #9ecdfc;
  margin-bottom: 0.06rem;
  color: #fff;
  border-radius: 0.02rem;
  cursor: pointer;
  font-size: 0.07rem;
}
.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
.footes {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,

  ) {
  background: #121b25;
  color: white;
}
</style>
