<template>
  <div class="mod-main">
    <a-spin :spinning="loading" tip="页面加载中....." style="height: 100%">
      <!-- <div class="back" @click="goBack">
        返回
      </div> -->
      <iframe
        title="智能检测"
        id="detection-screen"
        :src="iframeUrl"
        width="100%"
        :style="{ height: calHeight }"
        allowfullscreen
      >
      </iframe>
    </a-spin>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  nextTick,
  defineProps,
  defineEmits,
  toRefs,
} from 'vue';
// import { CloseOutlined } from '@ant-design/icons-vue';
import { useRoute,useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter()

const loading = ref(true);
const calHeight = ref(`${window.innerHeight - 96}px`);
const baseUrl = ref(process.env.VUE_APP_IFRAME_URL);
let erpClientToken = sessionStorage.getItem('erpClientToken');
const iframeUrl = ref(`${baseUrl.value}/intelligentDetection?token=${erpClientToken}`);
onMounted(() => {
  onLoading();
  nextTick(() => {
    calHeight.value = `${window.innerHeight}px`;
  });
});
const onLoading = () => {
  const iframe = document.querySelector('#detection-screen');
  // 处理兼容行问题
  if (iframe.attachEvent) {
    iframe.attachEvent('onload', () => {
      loading.value = false;
    });
  } else {
    iframe.onload = () => {
      loading.value = false;
    };
  }
};

const goBack = () => {
    router.go(-1)
}
</script>

<style scoped>
/* @import '../../../assets/style/vxe-coustom.scss'; */
:deep(.ant-spin){
  min-width: 100%;
  min-height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mod-main {
  width: 100%;
  height: 100%;
  position: relative;
}
.back{
  /* color: white;
  font-size: 0.1rem;
  height: 7%;
  width: 7%;
  background-image: url('../../../assets/quit.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 18%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; */
  position: absolute;
  top: 2%;
  right: 18%;
  z-index: 999999;
  height: 4%;
  width: 5% !important;
  background: linear-gradient(to right, #6a8aea, #679cde);
  border: 0.01rem solid #a5bee5;
  box-shadow: 0px 0px 0.03rem #a5bee5;
  border-radius: 0.02rem;
  color: white;
  font-size: 0.08rem;
  padding: 0.03rem 0.04rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  column-gap: 0.02rem;
  align-items: center;
  cursor: pointer;

}
</style>
