<template>
  <a-modal
    :visible="props.visible"
    width="100vw"
    height="100%"
    centered
    wrap-class-name="report-modal"
    :destroyOnClose="true"
    :closeIcon="true"
    @cancel="closeLogModal"
    style="max-width: 100vw"
    :bodyStyle="{
      padding: '0px',
    }"
  >
    <template #title>
      <div class="modal-name">检测报告</div>
      <a-button class="close-btn" @click="closeLogModal">关闭 </a-button>
      <!-- <a-button class="close-btn" @click="closeLogModal">
        <CloseOutlined style="color: black" />
      </a-button> -->
    </template>
    <div>
      <div class="mod-main">
        <a-spin :spinning="loading" tip="页面加载中.....">
          <iframe
            title="检测报告"
            id="report_iframe"
            :src="iframeUrl"
            width="100%"
            :style="{ height: calHeight }"
            allowfullscreen
          >
          </iframe>
        </a-spin>
      </div>
    </div>
    <template #footer> </template>
  </a-modal>
</template>

<script setup>
import {
  ref,
  onMounted,
  nextTick,
  defineProps,
  defineEmits,
  toRefs,
} from 'vue';
// import { CloseOutlined } from '@ant-design/icons-vue';
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
  testNumber: {
    type: String,
    required: true,
    default() {
      return '';
    },
  },
  executionStandard: {
    type: String,
    required: true,
    default() {
      return 'nationalStandard';
    },
  },
});
const { testNumber,executionStandard } = toRefs(props);
const emits = defineEmits(['update:visible']);
const loading = ref(true);
const calHeight = ref(`${window.innerHeight - 96}px`);
const baseUrl = ref(process.env.VUE_APP_IFRAME_URL)
const erpToken = sessionStorage.getItem('erpToken')
const iframeUrl = ref(`${baseUrl.value}/report?testNumber=${testNumber.value}&executionStandard=${executionStandard.value}&erpToken=${erpToken}&hastop=false`);

const closeLogModal = () => {
  emits('update:visible', false);
};
onMounted(() => {
  onLoading();
  nextTick(() => {
    calHeight.value = `${window.innerHeight - 96}px`;
  });
});
const onLoading = () => {
  const iframe = document.querySelector('#report_iframe');
  // 处理兼容行问题
  if (iframe.attachEvent) {
    iframe.attachEvent('onload', () => {
      loading.value = false;
    });
  } else {
    iframe.onload = () => {
      loading.value = false;
    };
  }
};
</script>

<style scoped>
/* @import '../../../assets/style/vxe-coustom.scss'; */
.mod-main {
  width: 100%;
  height: 100%;
}
.offline-color {
  color: #ff7875;
}
:deep(.ant-modal-body) {
  padding: 0px !important;
}
:deep(.ant-modal-body) {
  padding: 0px !important;
}
:deep(.ant-modal-centered) {
  overflow: hidden !important;
}
:deep(.ant-spin){
  min-width: 100%;
  min-height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style>
.ant-modal-centered {
  overflow: hidden !important;
}
</style>
