class TableConfig {
    constructor() {}
    getTableConfig_1() {
        return {
            id: Math.random().toString(),
            border: true,
            align: "center",
            resizable: true,
            autoSize: true,
            rowId: 'eid',
            showOverflow: true,
            highlightHoverRow: true,
            stripe: true,
            // keepSource: true,
            // height:800,
            //TODO 工具栏配置
            toolbarConfig: {
                refresh: false,
                zoom: false,
            },
            //TODO 分页配置
            pagerConfig: {
                pageSize: 10,
                pageSizes: [5,8,10,13]
            },
            //TODO 数据代理配置
            proxyConfig: {
                autoLoad: false, //自动加载数据关闭
                seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                form: false, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                props: {
                    result: 'data.data.data', // 配置响应结果列表字段
                    total: 'data.data.count', // 配置响应结果总页数字段

                },
            },

        };
    }
    getTableConfig_2() {
        return {
            id: Math.random().toString(),
            border: true,
            align: "center",
            resizable: true,
            autoSize: true,
            rowId: 'eid',
            showOverflow: true,
            highlightHoverRow: true,
            stripe: true,
            // keepSource: true,
            // height:800,
            //TODO 工具栏配置
            toolbarConfig: {
                refresh: false,
                zoom: false,
            },
            //TODO 分页配置
            pagerConfig: {
                pageSize: 10,   
                pageSizes: [5,8,10,13]
            },
            //TODO 数据代理配置
            proxyConfig: {
                autoLoad: false, //自动加载数据关闭
                seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                form: false, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                props: {
                    result: 'data.data.data', // 配置响应结果列表字段
                    total: 'data.data.count', // 配置响应结果总页数字段
                },
            },

        };
    }
    getTableConfig_3() {
        return {
            id: Math.random().toString(),
            border: true,
            align: "center",
            resizable: true,
            autoSize: true,
            rowId: 'eid',
            showOverflow: true,
            highlightHoverRow: true,
            stripe: true,
            // keepSource: true,
            // height:800,
            //TODO 工具栏配置
            toolbarConfig: {
                refresh: false,
                zoom: false,
            },
            //TODO 分页配置
            pagerConfig: {
                pageSize: 10,
                pageSizes: [5,8,10,13]
            },
            //TODO 数据代理配置
            proxyConfig: {
                autoLoad: false, //自动加载数据关闭
                seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                form: false, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                props: {
                    result: 'data.data.list', // 配置响应结果列表字段
                    total: 'data.data.count', // 配置响应结果总页数字段
                },
            },

        };
    }
    getTableConfig_4() {
        return {
            id: Math.random().toString(),
            border: true,
            align: "center",
            resizable: true,
            autoSize: true,
            rowId: 'eid',
            showOverflow: true,
            highlightHoverRow: true,
            stripe: true,
            // keepSource: true,
            // height:800,
            //TODO 工具栏配置
            toolbarConfig: {
                refresh: false,
                zoom: false,
            },
            //TODO 分页配置
            pagerConfig: {
                // enabled:false,
                pageSize: 10,
                pageSizes: [5,8,10,13]
            },
            //TODO 数据代理配置
            proxyConfig: {
                autoLoad: false, //自动加载数据关闭
                seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                form: false, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                props: {
                    result: 'data.data', // 配置响应结果列表字段
                    total: 'data.code', // 配置响应结果总页数字段
                },
                // ajax: {
                //     // 接收 Promise
                //     query: () => {
                //       return findList()
                //     }
                //   }
            },

        };
    }
}

let tempTableConfig = new TableConfig();
export default tempTableConfig;