<!--集料 列表-->
<template>
  <div>
    <!-- 列表   -->
    <div class="mod-main">
      <div style="margin-bottom: 0.06rem">
        <a-form
          :model="aggregatesformState"
          style="width: 100%"
          v-bind="baseFormConfig"
        >
          <a-row>
            <a-col v-bind="colConfig">
              <a-form-item label="集料分类">
                <a-select
                  v-model:value="aggregatesformState.aggregatesType"
                  :options="typeOptions"
                  placeholder="请选择集料分类"
                  @change="handleListTypeChange"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col v-bind="colConfig">
              <a-form-item label="集料类别">
                <a-select v-model:value="aggregatesformState.aggregateCategory"
                          :options="categoryOptions"
                          @change="handleListCategoryChange"
                          placeholder="请选择集料类别">

                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col v-bind="colConfig">
              <a-form-item
                label="集料规格"
                :wrapper-col="{ span: 16, offset: 1 }"
              >
                <a-input
                  v-model:value="aggregatesformState.aggregatesSpecs"
                  placeholder="请输入集料规格"
                  :maxlength="255"
                  @change="handleInput"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col v-bind="colConfig">
              <a-form-item
                label="地磅集料规格"
                :wrapper-col="{ span: 16, offset: 1 }"
              >
                <a-input
                  v-model:value="aggregatesformState.iwsAggregatesSpecs"
                  placeholder="请输入地磅集料规格"
                  :maxlength="255"
                  @change="handleIwsInput"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col v-bind="colConfig">
              <a-form-item style="align-items: flex-start">
                <a-button
                  type="primary"
                  @click="aggregatesListQuery"
                  style="margin-bottom: 1px; margin-right: 6px"
                  >查询
                </a-button>
                <a-button
                  type="primary"
                  @click="aggregatesListReset"
                  style="margin-bottom: 1px; margin-right: 6px"
                  >重置
                </a-button>
                <a-button
                  type="primary"
                  @click="editEvent(null)"
                  style="margin-bottom: 1px"
                  >添加
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <vxe-grid
        ref="aggregatesGridRef"
        highlight-current-row
        v-bind="aggregatesTableConfig"
        class="my-grid"
      >
        <template #aggregatesGroup="{ row }">
          {{ row.aggregatesTypeParent }}-{{ row.aggregatesType }}
        </template>

        <template #state="{ row }">
          <div class="hitch-color" v-if="row.status == '1'">启用</div>
          <div class="success-color" v-if="row.status == '0'">停用</div>
        </template>

        <!-- <template #aggregateCategory="{ row }">
          <div v-if="row.aggregateCategory == '1'">Ⅰ类</div>
          <div v-if="row.aggregateCategory == '2'">Ⅱ类</div>
          <div v-if="row.aggregateCategory == '3'">Ⅲ类</div>
        </template> -->

        <template #operate="{ row }">
          <a-space>
            <a-popconfirm
              v-if="userRole == 'admin'"
              title="是否确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="deleteEvent(row)"
            >
              <div class="btn btn-color1">删除</div>
            </a-popconfirm>
            <div class="btn btn-color2" @click="editEvent(row)">编辑</div>
          </a-space>
        </template>
      </vxe-grid>
    </div>

    <!-- 新增/编辑 弹窗   -->
      <AddAggregates
        v-if="editVars.modalShow"
        v-model:visible="editVars.modalShow"
        :modalTitle="editVars.modalTitle"
        :childGridRef="aggregatesGridRef"
        :childEditVars="editVars"
      ></AddAggregates>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
// import {message} from 'ant-design-vue';

//TODO 表单相关配置
import { baseFormConfig } from '../../js/base.form.config.js';
import { colConfig } from '../../js/col.config.js';

//TODO 表格相关配置
import TableConfig from '../../js/base.vxetable.config.js';

import { aggregatesColumns } from '../../js/table.columns.js';

//TODO 下拉选项配置项

import {
  // categoryOptions,
  typeOptions,
} from '../../js/opts.group.js';

//TODO 代理函数
import { useAjaxProxy } from '../../js/useAjaxProxy.js';
import { apiTopHeader } from '@/api/api-topHeader.js';

//TODO 导入子组件
import AddAggregates from './add-aggregates.vue';
import { message } from 'ant-design-vue';

//表格节点
const aggregatesGridRef = ref(null);

//集料列表搜索表单参数
let aggregatesformState = ref({
  //子类
  aggregatesType: '全部',
  //类别
  // aggregateCategory: '全部',
  //规格
  aggregatesSpecs: '',
  // 地磅集料规格
  iwsAggregatesSpecs: '',
  //状态
  // status: '',
  //页码
  page: 1,
  //页数
  limit: 10,
});
//查询参数
let queryParams = ref({});

//编辑弹窗变量集合
let editVars = ref({
  modalShow: false,
  modalTitle: '添加集料',
  currentId: null,
});

//列表表格基础配置
let aggregatesTableConfig = TableConfig.getTableConfig_1();

//TODO 表格基础配置扩展
//合并列
aggregatesTableConfig['columns'] = aggregatesColumns;
//数据代理扩展
aggregatesTableConfig['proxyConfig']['ajax'] = {};

//TODO 代理扩展函数
aggregatesTableConfig['proxyConfig']['ajax']['query'] = useAjaxProxy(
  queryParams,
  aggregatesGridRef,
  apiTopHeader.findAggregateListByPage
);

// 用户角色
let userRole = ref(null);
onMounted(() => {
  userRole.value = JSON.parse(localStorage.getItem('userRole'));
  //调用接口 集料列表接口
  // console.log(aggregatesformState.value);

  queryParams.value = { ...aggregatesformState.value };
  if (aggregatesformState.value.aggregatesType == '全部') {
    queryParams.value.aggregatesType = '';
  }
  if (aggregatesformState.value.aggregateCategory == '全部') {
    queryParams.value.aggregateCategory = '';
  }

  //调用

  aggregatesGridRef.value.commitProxy('query');
});

// 处理列表页分类选择
const handleListTypeChange = (val) => {
  let aggregatesType = val.split('-');
  aggregatesformState.value.aggregatesType = aggregatesType[1];
  queryParams.value = { ...aggregatesformState.value };
  // if (aggregatesformState.value.aggregatesType == '全部') {
  //   queryParams.value.aggregatesType = '';
  // }
  // if (aggregatesformState.value.aggregateCategory == '全部') {
  //   queryParams.value.aggregateCategory = '';
  // }
};
//处理列表页集料类别选择
// const handleListCategoryChange = (val) => {
//   aggregatesformState.value.aggregateCategory = val
//   queryParams.value = { ...aggregatesformState.value }
//   if (aggregatesformState.value.aggregatesType == '全部') {
//     queryParams.value.aggregatesType = ''
//   }
//   if (aggregatesformState.value.aggregateCategory == '全部') {
//     queryParams.value.aggregateCategory = ''
//   }
// }

//input输入
const handleInput = () => {
  // console.log(aggregatesformState.value);
  queryParams.value = { ...aggregatesformState.value };
  // if (aggregatesformState.value.aggregatesType == '全部') {
  //   queryParams.value.aggregatesType = '';
  // }
  // if (aggregatesformState.value.aggregateCategory == '全部') {
  //   queryParams.value.aggregateCategory = '';
  // }
};
// 地磅集料规格事件
const handleIwsInput = () => {
  // console.log(aggregatesformState.value);
  queryParams.value = { ...aggregatesformState.value };
};

//集料管理列表数据查询
const aggregatesListQuery = () => {
  //查询参数
  // console.log(queryParams.value);
  aggregatesGridRef.value.commitProxy('query', ...[true]);
};

//集料管理列表数据重置
const aggregatesListReset = () => {
  aggregatesformState.value = {
    //子类
    aggregatesType: '',
    //类别
    // aggregateCategory: '全部',
    //规格
    aggregatesSpecs: '',
    // 地磅集料规格
    iwsAggregatesSpecs: '',
    //状态
    // status: '',
    //页码
    page: 1,
    //页数
    limit: 10,
  };
  queryParams.value = { ...aggregatesformState.value };
  if (aggregatesformState.value.aggregatesType == '全部') {
    queryParams.value.aggregatesType = '';
  }
  if (aggregatesformState.value.aggregateCategory == '全部') {
    queryParams.value.aggregateCategory = '';
  }

  aggregatesGridRef.value.commitProxy('reload');
};
let timer = null;
//删除
const deleteEvent = (row) => {
  // console.log(row);
  //调用删除接口
  apiTopHeader
    .deleteAggregate(row.id)
    .then((res) => {
      // console.log(res);
      if (res.data.code == 0) {
        //弹窗提示
        message.success('删除成功', 2);
        //刷新表格
        timer = setTimeout(() => {
          aggregatesGridRef.value.commitProxy('query', ...[true]);
        }, 1500);
      }
    })
    .catch((error) => {
      message.error(`删除失败-${error.data.message}`, 2);
    });
};
onUnmounted(() => {
  clearTimeout(timer);
});
//编辑
const editEvent = (row) => {
  // console.log(row);
  if (row) {
    //编辑 赋值ID
    editVars.value.currentId = row.id;
    editVars.value.modalShow = true;
    editVars.value.modalTitle = '编辑集料';
  } else {
    //新增
    editVars.value.currentId = null;
    editVars.value.modalShow = true;
    editVars.value.modalTitle = '添加集料';
  }
};
</script>
<style scoped>
@import '../../../assets/style/vxe-coustom.scss';
:deep(.ant-form-item-label > label) {
  color: white;
  font-size: 0.08rem;
}
.mod-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}

.typeTag {
  /*font-size: 0.06rem;*/
}
:deep(.ant-btn-primary) {
  height: fit-content;
}

.btn {
  /*font-size: 0.06rem;*/
  color: #fff;

  padding: 0.02rem 0.08rem;
  display: inline-block;
  border-radius: 0.02rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.05rem;
}
.success-color {
  color: #ff9393;
}

.btn-color1 {
  background: rgba(263, 39, 69, 0.8);
  border: 1px solid rgb(263, 39, 69, 0.8);
}

.btn-color2 {
  background: rgba(250, 180, 33, 0.8);
  border: 1px solid rgb(250, 180, 33, 0.8);
}
/* .close-btn {
  margin-left: auto;
  z-index: 9999;
  background: none;
  border: none;
  font-size: 0.08rem;
  color: white;
} */
</style>
