<template>
  <div class="content-foots-1">
    <!-- 取样 -->
    <!-- <div class="cfot-item" style="width: 9%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">取样</div>
        <setting-outlined
          @click="setSampleDeviceShow"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9
        <DeviceStatus :deviceStatus="deviceStatus?.samplingDeviceStatus ?? 0" />
      </div>
      设备故障提示
      <div class="device-breakdown" v-if="false">
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！ <span>编号:168</span>
        </div>
      </div>
    </div>

    <div class="divider"></div> -->

    <!-- 机械手 -->
    <div class="cfot-item" style="width: 11%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">机械手</div>
        <setting-outlined
          @click="setOtherDeviceShow('机械手')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus
          :deviceStatus="deviceStatus?.manipulatorDeviceStatus ?? 0"
        />
      </div>
      <!-- 设备故障提示 -->
      <div class="device-breakdown" v-if="false">
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！ <span>编号:168</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- 烘干 -->
    <div class="cfot-item" style="width: 9%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">烘干</div>
        <setting-outlined
          @click="setDryDeviceShow"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus :deviceStatus="deviceStatus?.dryDeviceStatus ?? 0" />
      </div>
      <!-- 设备故障提示 -->
      <div class="device-breakdown" v-if="false">
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！ <span>编号:168</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- 水洗 -->
    <div class="cfot-item" style="width: 9%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">水洗</div>
        <setting-outlined
          @click="setOtherDeviceShow('水洗')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus :deviceStatus="deviceStatus?.washDeviceStatus ?? 0" />
      </div>
      <!-- 设备故障提示 -->
      <div class="device-breakdown" v-if="false">
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！ <span>编号:168</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- 颗粒级配 -->
    <div class="cfot-item" style="width: 14%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">颗粒级配(粗)</div>
        <setting-outlined
          @click="setOtherDeviceShow('颗粒级配(粗)')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus
          :deviceStatus="deviceStatus?.particleGradingModulusDeviceStatus ?? 0"
        />
      </div>
      <!-- 设备故障提示 -->
      <div class="device-breakdown" v-if="false">
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！ <span>编号:168</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- 细度模数 -->
    <div class="cfot-item" style="width: 14%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">颗粒级配(细)</div>
        <setting-outlined
          @click="setOtherDeviceShow('颗粒级配(细)')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus
          :deviceStatus="deviceStatus?.finenessModulusDeviceStatus ?? 0"
        />
      </div>
      <!-- 设备故障提示 -->
      <div class="device-breakdown" v-if="false">
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！ <span>编号:168</span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- 亚甲蓝 -->
    <div class="cfot-item" style="width: 16%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">亚甲蓝（MB值）</div>
        <setting-outlined
          @click="setOtherDeviceShow('亚甲蓝（MB值）')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 离线0，准备1，故障2，就绪3，运行中4，完成5，暂停中6 ，手动7，本地8，急停中9-->
        <DeviceStatus
          :deviceStatus="deviceStatus?.methyleneBlueDeviceStatus ?? 0"
        />
        <!-- <div class="device-status">
          1#
          <check-outlined />
          <close-outlined />
        </div>
        <div class="device-status">
          2#
          <check-outlined />
          <close-outlined />
        </div>
        <div class="device-status">
          3#
          <check-outlined />
          <close-outlined />
        </div> -->
      </div>
      <!-- 设备故障提示 -->
      <div
        class="device-breakdown"
        v-if="deviceStatus.methyleneBlueDeviceFault"
      >
        <div class="device-breakdown-tip">
          <ExclamationCircleFilled />
          设备故障！
          <span class="breakdown-msg">{{
            deviceStatus.methyleneBlueDeviceFault
          }}</span>
        </div>
      </div>
    </div>

    <!--取样-钻头-参数设置-->
    <!-- <a-modal
      v-model:visible="samplingDeviceShow"
      width="50%"
      centered
      :wrapClassName="'qy-modal'"
      :closeIcon="true"
      @cancel="closeSampling"
    >
      <template #title>
        <div ref="modalTitleRef" class="setting-title">钻头-参数设置</div>
        <a-button class="close-btn" @click="closeSampling">关闭 </a-button>
      </template>
      <div
        style="padding: 0 0.5rem"
        v-if="JSON.stringify(setting.bitParameter) !== '{}'"
      >
        <div class="setmina">
          <div class="setmina-item">
            <div class="setmina-item-title">取样点_x轴</div>
            <a-input
              suffix="%"
              v-model:value="setting.bitParameter.position_x"
            />
          </div>
          <div class="setmina-item">
            <div class="setmina-item-title">取样点_y轴</div>
            <a-input
              suffix="%"
              v-model:value="setting.bitParameter.position_y"
            />
          </div>
          <div class="setmina-item">
            <div class="setmina-item-title">取样点_z轴</div>
            <a-input
              suffix="%"
              v-model:value="setting.bitParameter.position_z"
            />
          </div>
        </div>
      </div>
      <div class="dry-loading" v-else>
        <a-spin size="large" />
      </div>
      <template #footer>
        <div class="footes" style="padding-bottom: 0.06rem">
          <div class="mbtns stars" @click="handleSaveSampling">
            <span>保存</span>
          </div>
        </div>
      </template>
    </a-modal> -->

    <!--烘干-参数设置-->
    <a-modal
      v-model:visible="dryDeviceShow"
      width="50%"
      wrap-class-name="qy-modal"
      centered
      :closeIcon="true"
      @cancel="closeDrying"
    >
      <template #title>
        <div ref="modalTitleRef" class="setting-title">烘干-参数设置</div>
        <a-button class="close-btn" @click="closeDrying">关闭 </a-button>
      </template>
      <div class="" v-if="JSON.stringify(setting.dryingParameter) !== '{}'">
        <div
          class="setting-dry"
          v-for="(item, key) in setting.dryingParameter"
          :key="key"
        >
          <div class="setting-dry-item">{{ key }}</div>
          <div class="setmina">
            <div class="setmina-item">
              <div class="setmina-item-title">功率</div>
              <div style="padding: 0 0.03rem">
                <a-input
                  suffix="kw"
                  placeholder="请输入功率"
                  v-model:value="item.config.power"
                />
              </div>
            </div>
            <div class="setmina-item">
              <div class="setmina-item-title">风量</div>
              <div style="padding: 0 0.03rem">
                <a-input
                  suffix="%"
                  placeholder="请输入风量"
                  v-model:value="item.config.air"
                />
              </div>
            </div>
            <div class="setmina-item">
              <div class="setmina-item-title">温度上限</div>
              <div style="padding: 0 0.03rem">
                <a-input
                  suffix="℃"
                  placeholder="请输入温度上限"
                  v-model:value="item.config.temperature_ceiling"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modlcont dry-loading" v-else>
        <a-spin size="large" />
      </div>
      <template #footer>
        <div class="footes" style="padding-bottom: 0.06rem">
          <div class="mbtns stars" @click="handleSaveDrying">
            <span>保存</span>
          </div>
        </div>
      </template>
    </a-modal>

    <!--暂无参数设备-参数设置-->
    <a-modal
      v-model:visible="otherDeviceShow"
      width="50%"
      wrap-class-name="qy-modal"
      centered
      :closeIcon="true"
    >
      <template #title>
        <div ref="modalTitleRef" class="setting-title">
          {{ `${deviceName}-参数设置` }}
        </div>
        <a-button class="close-btn" @click="otherDeviceShow = false"
          >关闭
        </a-button>
      </template>
      <div class="dry-loading">
        <div style="font-size: 0.08rem; color: #fab421">暂无参数</div>
      </div>
      <template #footer>
        <div class="footes" style="padding-bottom: 0.06rem">
          <div class="mbtns stars">
            <span>保存</span>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import {
  // LoadingOutlined,
  // WarningOutlined,
  // CloseOutlined,
  // CheckOutlined,
  SettingOutlined,
  // PauseCircleOutlined,
  // CloseOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue';
//TODO 导入子组件
import DeviceStatus from './cpns/device/device-status.vue';
import { ref, reactive, watch, nextTick } from 'vue';
import { apiCenterBottom } from '@/api/api-center-bottom/api-center-bottom.js';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
let store = useStore();

const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';

//整个设备列表对应设备数据
const setting = reactive({
  bitParameter: {}, //取样设备
  dryingParameter: {}, //烘干设备
});
// 各个设备状态
const deviceStatus = reactive({
  samplingDeviceStatus: null,
  manipulatorDeviceStatus: null,
  dryDeviceStatus: null,
  washDeviceStatus: null,
  particleGradingModulusDeviceStatus: null,
  finenessModulusDeviceStatus: null,
  methyleneBlueDeviceStatus: null,
});

watch(
  () => store.state.engine.allEngineData, //检测数据的前20条数据
  (newValue) => {
    nextTick(() => {
      deviceStatus.samplingDeviceStatus = newValue?.samplingDeviceStatus; //取样设备状态

      deviceStatus.manipulatorDeviceStatus = newValue?.manipulatorDeviceStatus; //机械手设备状态

      deviceStatus.dryDeviceStatus = newValue?.dryDeviceStatus; //烘干设备状态

      deviceStatus.washDeviceStatus = newValue?.washDeviceStatus; //水洗设备状态

      deviceStatus.particleGradingModulusDeviceStatus =
        newValue?.particleGradingModulusDeviceStatus; //颗粒级配设备状态

      deviceStatus.finenessModulusDeviceStatus =
        newValue?.finenessModulusDeviceStatus; //细度模数设备状态

      deviceStatus.methyleneBlueDeviceStatus =
        newValue?.methyleneBlueDeviceStatus; //亚甲蓝设备状态

      deviceStatus.methyleneBlueDeviceFault = newValue?.methyleneBlueDeviceFault; //亚甲蓝设备故障
      // console.log('设备状态', deviceStatus);
    });
  
  },
  { immediate: false }
);
//取样弹框
// const samplingDeviceShow = ref(false);
/**
 * @Date 2023-05-07 14:06:11
 * @introduction 获取取样设备参数
 * @description 打开取样设备参数时获取设备参数并展示
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const setSampleDeviceShow = () => {
//   samplingDeviceShow.value = true;
//   // 获取取样设备参数
//   apiCenterBottom
//     .getDeviceSettings()
//     .then((res) => {
//       if (res.data.code == 0) {
//         // console.log('取样设备参数', res.data.data)
//         setting.bitParameter = JSON.parse(res.data.data[0].sampling);
//         // console.log('取样设备参数', setting.bitParameter);
//       }
//     })
//     .catch(() => {
//       message.error('获取设备参数失败', 2);
//     });
// };

/**
 * @Date 2023-05-08 14:05:19
 * @introduction 保存取样设备参数
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const handleSaveSampling = () => {
//   if (
//     setting.bitParameter.position_x === '' ||
//     setting.bitParameter.position_y === '' ||
//     setting.bitParameter.position_z === ''
//   ) {
//     message.error('输入的值不能为空');
//     return;
//   }
//   let data = {
//     sampling: JSON.stringify(setting.bitParameter),
//   };
//   apiCenterBottom
//     .saveDeviceSettings(data)
//     .then((res) => {
//       if (res.data.code == 0) {
//         message.success('修改成功');
//         closeSampling();
//       }
//     })
//     .catch(() => {
//       message.error('修改失败');
//     });
// };
/**
 * @Date 2023-08-18 08:42:12
 * @introduction 关闭烘干设备参数弹框
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const closeSampling = () => {
//   samplingDeviceShow.value = false;
//   setting.bitParameter = {};
// };
//烘干
const dryDeviceShow = ref(false);
/**
 * @Date 2023-05-08 14:07:37
 * @introduction 关闭烘干设备参数弹框
 * @description 关闭时清空所有值的背景颜色
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const closeDrying = () => {
  dryDeviceShow.value = false;
  // console.log(
  //   '🚀 ~ file: contentFoots.vue:443 ~ closeDrying ~ setting.dryingParameter:',
  //   setting.dryingParameter
  // );
  setting.dryingParameter = {};
  // console.log(
  //   '🚀 ~ file: contentFoots.vue:443 ~ closeDrying ~ setting.dryingParameter:',
  //   setting.dryingParameter
  // );
};
/**
 * @Date 2023-05-08 14:08:56
 * @introduction 打开烘干设备参数弹窗
 * @description 打开时获取参数并展示背景颜色
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const setDryDeviceShow = () => {
  if (isCloudPage) {
    message.warning('线上不可以设置');
    return;
  }
  dryDeviceShow.value = true;
  // 获取烘干设备参数
  apiCenterBottom
    .getDeviceSettings()
    .then((res) => {
      if (res.data.code == 0) {
        setting.dryingParameter = JSON.parse(res.data.data[0].drying);
        // console.log('获取的烘干设备参数', setting.dryingParameter);
      }
    })
    .catch(() => {
      message.error('获取设备参数失败', 2);
    });
};

/**
 * @Date 2023-05-08 14:13:21
 * @introduction 保存烘干设备参数信息
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const handleSaveDrying = () => {
  for (const key in setting.dryingParameter) {
    const config = setting.dryingParameter[key].config;
    if (
      config.temperature_ceiling === '' ||
      config.air === '' ||
      config.power === ''
    ) {
      message.error('自定义的值不能为空');
      return;
    }
  }

  // console.log('保存烘干设备信息的参数', setting.dryingParameter);
  // 保存烘干设备信息的参数
  let data = {
    drying: JSON.stringify(setting.dryingParameter),
  };
  apiCenterBottom
    .saveDeviceSettings(data)
    .then((res) => {
      if (res.data.code == 0) {
        message.success('修改成功');
        closeDrying();
      }
    })
    .catch(() => {
      message.error('修改失败');
    });
};

//机械手，水洗，颗粒级配，细度模数，亚甲蓝
const otherDeviceShow = ref(false);
let deviceName = ref('');
const setOtherDeviceShow = (device) => {
  if (isCloudPage) {
    message.warning('线上不可以设置');
    return;
  }
  otherDeviceShow.value = true;
  deviceName.value = device;
};
</script>

<style scoped>
.bgc-glass {
  background: rgba(255, 255, 255, 0.15) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px);
}
:deep(
    .drill-modal .ant-modal,
    .drill-modal .ant-modal-header,
    .drill-modal .ant-modal-body,
    .drill-modal .ant-modal-footer
  ) {
  background: rgba(61, 136, 255, 0.15) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px);
}
.footes {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wricon {
  color: red;
  font-size: 0.08rem;
  margin-right: 0.05rem;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dry-loading {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-foots-1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.15rem;
  background-color: rgba(26, 49, 86, 0.5);
}
.device-status {
  font-size: 0.07rem;
  color: white;
}
.ctop {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.cfot-item {
  position: relative;
  height: 100%;
  padding: 0rem 0.07rem;
  box-sizing: border-box;
  /* border: 1px solid rgb(0, 183, 255, 0.4);
  box-shadow: rgba(15, 92, 151, 0.4) 0px 0px 0.2rem inset; */
  color: #fff;
  font-size: 0.06rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
}
.divider {
  width: 0.02rem;
  height: 70%;
  /* margin-left: 0.05rem; */
  background: radial-gradient(closest-side, #ffffff, #9f9f9f75, #8d8d8d12);
}
.cfot-item-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cfot-item-top img {
  width: 0.1rem;
  height: 0.1rem;
  cursor: pointer;
}
.device-breakdown {
  position: absolute;
  top: 0%;
  left: -10%;
  width: 120%;
  height: 100%;
  background-color: #e64b4b5d;
  border: #e64b4b solid 0.02rem;
  animation: breakdown 1s linear infinite;
}
@keyframes breakdown {
  0% {
    border-color: #dc3333;
  }
  50% {
    border-color: #f5bdbd;
  }
}

.device-breakdown-tip {
  width: 100%;
  height: 80%;
  overflow-y: auto;
  border: #e64b4b solid 0.01rem;
  position: absolute;
  color: #e35757;
  background-color: #273952c5;
  top: -83%;
  left: 0;
  font-size: 0.06rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.breakdown-msg {
  word-break: break-word;
  padding: 0px 0.05rem !important;
}
.cfot-item-top-left {
  display: flex;
  align-items: center;
}
.cfot-item-top-mied {
  display: flex;
  align-items: center;
}
.cfot-item-top-mieditem {
  display: flex;
  align-items: center;
  font-size: 0.07rem;
  color: #fff;
  margin-right: 0.08rem;
}
.cfotstars {
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  margin-right: 0.04rem;
}
.cfot-item-top-title {
  font-size: 0.08rem;
  color: white;
}
.cfot-item-content {
  width: 100%;
  font-size: 0.07rem;
  margin-top: 0.04rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cfot-item-content-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.color1 {
  background: #00ff00;
  box-shadow: 0px 0px 5px #00ff00;
}
.color2 {
  background: #fab421;
  box-shadow: 0px 0px 5px #fab421;
}
.color3 {
  background: #ec2745;
  box-shadow: 0px 0px 5px #ec2745;
}
.cfotstars-warn {
  font-size: 0.1rem;
  color: #ec2745;
  margin-right: 10px;
}
.twinkle {
  animation: star 1.2s linear infinite;
  box-shadow: 0px 0px 10px #00ff00;
}
.twinklewr {
  animation: star 1.2s linear infinite;
}
@keyframes star {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.mbtns {
  font-size: 0.08rem;
  color: #fff;
  padding: 0.03rem 0.06rem;
  display: inline-block;
  border-radius: 0.02rem;
  width: 0.65rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.stars {
  background: #47a3fc;
  border: 1px solid #47a3fc;
}
.mbtns .icons {
  font-size: 0.08rem;
  margin-left: 0.05rem;
}

/* .qy-modal .ant-modal {
  max-width: 60%;
  padding-bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.qy-modal .ant-modal-header {
  background: rgb(45, 58, 81);
  opacity: 1;
  border-bottom: none;
}

.qy-modal .ant-modal-title {
  text-align: center;
  opacity: 1;
  color: white;
  font-size: 25px;
  font-weight: bold;
  font-size: 0.15rem;
}

.qy-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(70vh);
  background: rgb(45, 58, 81, 0.8);
}

.qy-modal .ant-modal-body {
  flex: 1;
}

.qy-modal .ant-modal-footer {
  border-top: none;
}

.ant-modal-close-icon svg {
  color: rgb(45, 58, 81);
}

.ddddddd .ant-modal-content .ant-modal-header {
  margin-top: 100px;
  background: rgb(1, 18, 47);
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: -23px;
} */

.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
.fromline {
  display: flex;
  align-items: center;
  position: relative;
}
.fromline-name {
  font-size: 0.07rem;
  margin-left: 0.03rem;
  color: #fff;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}

:deep(.ant-form-item-label > label) {
  color: white;
  font-size: 0.08rem;
}

:deep(.qy-modal .ant-modal-title) {
  font-size: 0.15rem;
}
:deep(.ant-input-suffix) {
  font-size: 0.09rem;
}
.setmina {
  display: flex;
  align-items: center;
}
.setmina-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 0.03rem;
}
.setting-dry-item {
  color: white;
  font-size: 0.09rem;
  font-weight: 800;
  width: 0.4rem;
}
.setmina-item-title {
  text-align: center;
  font-size: 0.08rem;
  color: #fab421;
  margin-bottom: 0.04rem;
}
.setting-dry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: white;
  font-size: 0.08rem;
  padding: 0.1rem 0.3rem;
}
:deep(.ant-input) {
  font-size: 0.08rem;
}
.setting-title {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
/* .close-btn {
  margin-left: auto;
  z-index: 9999;
  background: none;
  border: none;
  font-size: 0.08rem;
  color: white;
} */
</style>
