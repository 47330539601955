<template>
        <a-form :model="formData" name="basic"  ref="formRef"       :label-col="{ md: { span: 6 }, sm: { span: 24 } }" :wrapper-col="{ span: 24 }">
        <a-row :gutter="24">
                <a-col v-for="(item, index) in formItems" :key="index" :span="12">
                    <a-form-item :label="item.title" :name="item.field" :rules="{ required: item.itemRender.props.required, message: item.itemRender.props.message }">

                        <a-input v-if="item.itemRender.name == 'input'" v-model:value="formData[item.field]" :type="item.itemRender.props.type" :placeholder="item.itemRender.props.placeholder" />

                        <a-textarea  v-else-if="item.itemRender.name == 'textarea '" v-model:value="formData[item.field]" :placeholder="item.itemRender.props.placeholder" />

                        <a-select v-else-if="item.itemRender.name == 'select'" v-model:value="formData[item.field]" :options="item.itemRender.options" :mode="item.itemRender.props.mode" :placeholder="item.itemRender.props.placeholder" />

                        <a-date-picker v-else-if="item.itemRender.name == 'date-picker'" v-model:value="formData[item.field]"/>

                        <a-range-picker v-else-if="item.itemRender.name == 'range-picker'" v-model:value="formData[item.field]"   value-format="YYYY-MM-DD HH:mm:ss" show-time @ok="confirmTime($event, item.field)"/>
                 
                    </a-form-item>
                </a-col>
        </a-row>
    </a-form>
</template>
<script setup>
import { ref,defineProps,defineExpose,watch } from 'vue';
const props = defineProps({
    data:{},
    formItems: {
			type: Array,
			default:()=> []
		}})
const formData = ref({});
const formRef=ref()
watch(()=>props.data,()=>{
    formData.value=props.data
},{deep:true})
const confirmTime=(e,field)=>{
    formData[field]=e.join(',')
}
// 调用这个函数将子组件的一些数据和方法暴露出去
defineExpose({formData,formRef,props})
</script>
