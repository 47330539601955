import axios from "axios";

export class apiRight {
  //细度模数图表数据
  static getFinenessodulusChart(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/finenessModulusCharts/page",
      method: "GET",
      params: query,
    });
  }
}
