<template>
  <div class="left-card-3 card">
    <div class="card-top">
      <div class="card-top-left"></div>
      <!-- <div class="card-top-center"><span>--</span></div> -->
      <div class="card-top-right"></div>
    </div>

    <div class="card-bottom">
      <!-- 第一个 工位 -->
      <div class="card-bottom-item">
        <div class="card-bottom-item-left">
          <div class="gongwei gongwei1"></div>
          <div class="gundong" v-if="deviceData?.station1?.status">
            <p class="tip" :text="deviceData?.station1?.status">
              {{ deviceData?.station1?.status }}
            </p>
          </div>
        </div>
        <div class="card-bottom-item-right">
          <ul>
            <li>
              <div class="yuansu"></div>
              <div class="container">
                <div class="title">
                  {{ deviceData?.station1?.workId || '--' }}
                </div>
                <div class="text">
                  {{ deviceData?.station1?.aggregates || '--' }}
                </div>
              </div>
            </li>
            <li>
              <div class="yuansu"></div>
              <div class="container">
                <div class="title">
                  <span>
                    {{ deviceData?.station1?.detectionMethod || '--' }}
                  </span>
                  <span
                    >{{ deviceData?.station1?.detectionQuality || '--' }}g
                  </span>
                </div>
                <div class="text">
                  <div class="check-name">
                    MB值:
                    {{
                      tool.formatNumber(
                        methyleneBlueResult?.station1?.MBValue
                      ) || '--'
                    }}
                  </div>
                  <div
                    class="result"
                    v-if="
                      methyleneBlueResult?.station1?.detectionResult !== '' &&
                      methyleneBlueResult?.station1?.detectionResult == '合格'
                    "
                  >
                    <div class="result-img-success"></div>
                    <div class="result-text"><span>合格</span></div>
                  </div>
                  <div
                    class="result"
                    v-if="
                      methyleneBlueResult?.station1?.detectionResult !== '' &&
                      methyleneBlueResult?.station1?.detectionResult == '不合格'
                    "
                  >
                    <div class="result-img-error"></div>
                    <div class="result-text"><span>不合格</span></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 第二个 工位 -->
      <div class="card-bottom-item">
        <div class="card-bottom-item-left">
          <div class="gongwei gongwei2"></div>
          <div class="gundong" v-if="deviceData?.station2?.status">
            <p class="tip" :text="deviceData?.station2?.status">
              {{ deviceData?.station2?.status }}
            </p>
          </div>
        </div>
        <div class="card-bottom-item-right">
          <ul>
            <li>
              <div class="yuansu"></div>
              <div class="container">
                <div class="title">
                  {{ deviceData?.station2?.workId || '--' }}
                </div>
                <div class="text">
                  {{ deviceData?.station2?.aggregates || '--' }}
                </div>
              </div>
            </li>
            <li>
              <div class="yuansu"></div>
              <div class="container">
                <div class="title">
                  <span>{{
                    deviceData?.station2?.detectionMethod || '--'
                  }}</span>
                  <span
                    >{{ deviceData?.station1?.detectionQuality || '--' }}g</span
                  >
                </div>
                <div class="text">
                  <div class="check-name">
                    MB值:
                    {{
                      tool.formatNumber(
                        methyleneBlueResult?.station2?.MBValue
                      ) || '--'
                    }}
                  </div>
                  <div
                    class="result"
                    v-if="
                      methyleneBlueResult?.station2?.detectionResult !== '' &&
                      methyleneBlueResult?.station2?.detectionResult == '合格'
                    "
                  >
                    <div class="result-img-success"></div>
                    <div class="result-text"><span>合格</span></div>
                  </div>
                  <div
                    class="result"
                    v-if="
                      methyleneBlueResult?.station2?.detectionResult !== '' &&
                      methyleneBlueResult?.station2?.detectionResult == '不合格'
                    "
                  >
                    <div class="result-img-error"></div>
                    <div class="result-text"><span>不合格</span></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 第三个 工位 -->
      <div class="card-bottom-item">
        <div class="card-bottom-item-left">
          <div class="gongwei gongwei3"></div>
          <div class="gundong" v-if="deviceData?.station3?.status">
            <p class="tip" :text="deviceData?.station3?.status">
              {{ deviceData?.station3?.status }}
            </p>
          </div>
        </div>
        <div class="card-bottom-item-right">
          <ul>
            <li>
              <div class="yuansu"></div>
              <div class="container">
                <div class="title">
                  {{ deviceData?.station3?.workId || '--' }}
                </div>
                <div class="text">
                  {{ deviceData?.station3?.aggregates || '--' }}
                </div>
              </div>
            </li>
            <li>
              <div class="yuansu"></div>
              <div class="container">
                <div class="title">
                  <span>{{
                    deviceData?.station3?.detectionMethod || '--'
                  }}</span>
                  <span
                    >{{ deviceData?.station3?.detectionQuality || '--' }}g</span
                  >
                </div>
                <div class="text">
                  <div class="check-name">
                    MB值:
                    {{
                      tool.formatNumber(
                        methyleneBlueResult?.station3?.MBValue
                      ) || '--'
                    }}
                  </div>
                  <div
                    class="result"
                    v-if="
                      methyleneBlueResult?.station3?.detectionResult !== '' &&
                      methyleneBlueResult?.station3?.detectionResult == '合格'
                    "
                  >
                    <div class="result-img-success"></div>
                    <div class="result-text"><span>合格</span></div>
                  </div>
                  <div
                    class="result"
                    v-if="
                      methyleneBlueResult?.station3?.detectionResult !== '' &&
                      methyleneBlueResult?.station3?.detectionResult == '不合格'
                    "
                  >
                    <div class="result-img-error"></div>
                    <div class="result-text"><span>不合格</span></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import tool from './js/tool';
const store = useStore();

let deviceData = ref({
  // station1: {
  //   workId: 'JXMQ12345678912',
  //   faultCode: '168',
  //   aggregates: '[粗集料-碎石]10-20mm碎石',
  //   detectionQuality: '100',
  //   detectionMethod: '快速+标准检测',
  //   status: '准备视觉中',
  // },
  // station2: {
  //   workId: 'JXMQ12345678912',
  //   faultCode: '168',
  //   aggregates: '[粗集料-碎石]16-31.5mm碎石',
  //   detectionQuality: '520',
  //   detectionMethod: '快速+标准检测',
  //   status: '等待移液',
  // },
  // station3: {
  //   workId: 'JXMQ12345678912',
  //   faultCode: '168',
  //   aggregates: '[粗集料-碎石]10-20mm碎石',
  //   detectionQuality: '1000',
  //   detectionMethod: '快速+标准检测',
  //   status: '等待检测结果',
  // },
});
let methyleneBlueResult = ref({
  station1: {
    // detectionResult: '合格',
    // MBValue: 50,
  },
  station2: {
    // detectionResult: '不合格',
    // MBValue: 50,
  },
  station3: {
    // detectionResult: '合格',
    // MBValue: 50,
  },
});

watch(
  () => store.state.engine.allEngineData
  ,
  (newValue) => {
    const newStation = newValue?.methyleneBlueStationStatus || {}
    const newResult1 = newValue?.stationResult1 || {}
    const newResult2 = newValue?.stationResult2 || {}
    const newResult3 = newValue?.stationResult3 || {}
    deviceData.value = newStation;
    methyleneBlueResult.value.station1 = newResult1;
    methyleneBlueResult.value.station2 = newResult2;
    methyleneBlueResult.value.station3 = newResult3;
  },
  { immediate: false }
);
</script>

<style scoped lang="scss">
@import '../assets/style/common.scss';
.left-card-3 {
  width: 100%;
  .card-top {
    height: 16%;
    width: 98%;
    background: url(../assets/right/MB.png) center/100% no-repeat;
    .card-top-left {
      width: 30%;
    }
    .card-top-center {
      width: 45%;
      span {
        font-size: 0.09rem;
      }
    }

    .card-top-right {
      width: 28%;
    }
  }
  .card-bottom {
    height: 82%;
    display: block;
    .card-bottom-item {
      width: 90%;
      height: 31%;
      margin: 0 auto;
      margin-bottom: 2%;
      background-color: rgba(255, 255, 255, 0.03);
      border-radius: 0.0133rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.08rem;
      .card-bottom-item-left {
        width: 18%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 4%;
        .gongwei {
          width: 0.36rem;
          height: 0.24rem;
        }
        .gongwei1 {
          background: url(../assets/right/gongwei1.png) center/cover no-repeat;
        }
        .gongwei2 {
          background: url(../assets/right/gongwei2.png) center/cover no-repeat;
        }
        .gongwei3 {
          background: url(../assets/right/gongwei3.png) center/cover no-repeat;
        }
      }
      .card-bottom-item-right {
        width: 77%;
        height: 100%;
        ul {
          height: 100%;
          width: 100%;
          padding: 0;
          li {
            list-style: none;
            height: 49%;
            display: flex;
            align-items: center;
            .yuansu {
              width: 0.0667rem;
              height: 0.0667rem;
              background: url(../assets/common/yuansu1.png) center/cover
                no-repeat;
            }
            .container {
              width: 90%;
              color: white;
              margin-left: 0.0667rem;
              .title {
                font-size: 0.08rem;
                display: flex;
                gap: 0.2rem;
              }
              .text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .check-name {
                  // width: 45%;
                }
                .result {
                  // width: 55%;
                  display: flex;
                  align-items: center;
                  margin-right: 10%;
                  .result-img-success {
                    width: 0.1rem;
                    height: 0.13rem;
                    background: url(../assets/right/result_success.png)
                      center/cover no-repeat;
                  }
                  .result-img-error {
                    width: 0.1rem;
                    height: 0.13rem;
                    background: url(../assets/right/result_error.png)
                      center/cover no-repeat;
                  }
                  .result-text {
                    text-align: center;
                    span {
                      padding-left: 0.02rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.gundong {
  width: 0.36rem;
  height: 0.12rem;
  line-height: 0.12rem;
  display: inline-block;
  background: #01010149;
  border-radius: 0.02rem;
  margin-top: 0.03rem;
  overflow: hidden;
  white-space: nowrap;
  .tip {
    color: white;
    font-size: 0.07rem;
    position: relative;
    /* 盒子背景宽度将随文字宽度而进行自适应 */
    width: fit-content;
    /* 让前面的几个文字有一个初始的距离，达到更好的呈现效果 */
    padding-left: 0.1rem;
    /* 添加动画 */
    animation: gundong 3s linear 0s infinite;
  }
  .tip::after {
    position: absolute;
    right: -100%;
    content: attr(text);
  }
}
@keyframes gundong {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
