//集料类别opts
const categoryOptions = [{
    value: 1,
    label: "Ⅰ类"
}, {
    value: 2,
    label: "Ⅱ类"
},
{
    value: 3,
    label: "Ⅲ类"
}
];

//集料分类opts
const typeOptions = [{
    label: '细集料',
    options: [{
        value: '细集料-天然砂',
        label: '天然砂',
    }, {
        value: '细集料-机制砂',
        label: '机制砂',
    },
    {
        value: '细集料-混合砂',
        label: '混合砂',
    },
    {
        value: '细集料-石屑',
        label: '石屑',
    }
    ],
}, {
    label: '粗集料',
    options: [
        {
            value: '粗集料-卵石',
            label: '卵石',
        },
        {
            value: '粗集料-碎石',
            label: '碎石',
        },
        {
            value: '粗集料-钢渣',
            label: '钢渣',
        },
        {
            value: '粗集料-矿渣',
            label: '矿渣',
        },
        {
            value: '粗集料-筛选砾石',
            label: '筛选砾石',
        },
        {
            value: '粗集料-破碎砾石',
            label: '破碎砾石',
        },
    ],
}];

//集料类别
const aggregatesCategoryOptions = [{
    value: 'Ⅰ类',
    label: 'Ⅰ类',
}, {
    value: 'Ⅱ类',
    label: 'Ⅱ类',
}, {
    value: 'Ⅲ类',
    label: 'Ⅲ类',
}]


//状态opts
const statusOptions = [
    {
        name: '启用',
        isCk: true,

    }, {
        name: '停用',
        isCk: false,

    }
];
const statusOptions1 = [
    {
        value: 1,
        label: '启用',
    },
    {
        value: 0,
        label: '停用',
    }
];
//状态opts
const jptypeOptions = [
    {
        name: '连续粒级',
        isCk: true,

    }, {
        name: '单粒粒级',
        isCk: false,

    }
];


const unitsOptions = [

    {
        label: "供应商",
        value: 1,
    },
    {
        label: "生产商",
        value: 2,
    },
    {
        label: "施工单位",
        value: 3,
    },
    {
        label: "建设单位",
        value: 4,
    },
    {
        label: "检测单位",
        value: 5,
    }
];

export {
    categoryOptions,
    typeOptions,
    statusOptions,
    statusOptions1,
    jptypeOptions,
    unitsOptions,
    aggregatesCategoryOptions
}