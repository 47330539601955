<template>
  <div class="menu">
    <img
      v-if="isMenuOut"
      class="menu-out"
      src="../assets/btn_jiantou_out.png"
      alt="弹出菜单"
      @click="menuOut"
    />
    <a-drawer
      class="drawer"
      placement="right"
      :closable="false"
      :visible="!isMenuOut"
      :get-container="false"
      :style="{ position: 'absolute' }"
      :size="'default'"
      :mask="false"
    >
      <img
        v-if="!isMenuOut"
        class="menu-in"
        src="../assets/btn_jiantou_in.png"
        alt=""
        @click="menuIn"
      />
      <div class="menu-pop-div">
        <div class="sample-management">
          <div class="title">
            <img src="../assets/img_yangpin.png" alt="" />
            <div class="sample-name">样品管理</div>
          </div>
          <div class="setting-list">
            <!-- <div class="setting-list-item" @click="showMolde1('取样登记')">
              取样登记
            </div> -->
            <div class="setting-list-item" @click="showMolde1('样品登记')">
              样品登记
            </div>
            <div class="setting-list-item" @click="onClickSampleList">
              样品列表
            </div>
          </div>
        </div>
        <div class="setting">
          <div class="title">
            <img src="../assets/img_shezhi.png" alt="" />
            <div class="setting-name">配置</div>
          </div>
          <div class="setting-list">
            <div
              class="setting-list-item"
              @click="modalConfigurationShow('集料管理')"
            >
              集料管理
            </div>
            <!-- <div
              class="setting-list-item"
              @click="modalConfigurationShow('供应商')"
            >
              供应商
            </div> -->
            <div
              class="setting-list-item"
              @click="modalConfigurationShow('单位管理')"
            >
              单位管理
            </div>
            <div
              class="setting-list-item"
              @click="modalConfigurationShow('检测标准')"
            >
              检测标准
            </div>
            <div
              class="setting-list-item"
              @click="modalConfigurationShow('标准管理')"
            >
              标准管理
            </div>
            <!--<div
              class="setting-list-item"
              @click="modalConfigurationShow('账号管理')"
            >
              账号管理
            </div>-->
            <div
              class="setting-list-item"
              @click="modalConfigurationShow('系统参数')"
            >
              系统参数
            </div>
          </div>
        </div>
        <!-- <div class="operation">
          <div class="title">
            <img src="../assets/img_caozuo.png" alt="" />
            <div class="operation-name">操作</div>
          </div>
          <div class="setting-list">
            <div class="setting-list-item">
              <img src="../assets/operate_sample.png" alt="" />
              <a-button type="text"> 急停 </a-button>
            </div>
            <div class="setting-list-item">
              <img src="../assets/operate_detection.png" alt="" />
              <a-button type="text" @click="operation(9, '急停')">
                急停
              </a-button>
              <a-button type="text" @click="operation(2, '暂停')">
                暂停
              </a-button>
              <a-button type="text" @click="operation(1, '启动')">
                启动
              </a-button>
            </div>
          </div>
        </div> -->
      </div>
    </a-drawer>

    <!--取样和样品取样弹框-->
    <sampleRegist
      v-if="isRegiste"
      v-model:visible="isRegiste"
      :registerName="registerName"
    />
    <!--账号管理-->
    <accountManage v-if="accountShow" v-model:visible="accountShow" />

    <!--系统参数-->
    <systemParameter v-if="parameterShow" v-model:visible="parameterShow" />

    <!--样品列表表格弹框-->
    <sampleList v-if="sampleListShow" v-model:visible="sampleListShow" />

    <!--集料管理-->
    <a-modal
      v-model:visible="aggregatesShow"
      width="100%"
      wrap-class-name="table-modal"
      centered
      :destroyOnClose="true"
      :closeIcon="true"
    >
      <template #title>
        <div class="modal-name">集料管理</div>
        <a-button class="close-btn" @click="aggregatesShow = false"
          >关闭
        </a-button>
      </template>
      <!-- TODO 组件存放 集料列表     -->
      <AggregatesList></AggregatesList>
      <template #footer> </template>
    </a-modal>

    <!--供应商管理-->
    <!-- <a-modal
      v-model:visible="supplierShow"
      width="100%"
      wrap-class-name="table-modal"
      centered
      :closeIcon="true"
      :destroyOnClose="true"
    >
      <template #title>
        <div>供应商管理</div>
        <a-button class="close-btn" @click="supplierShow = false"
          >关闭
        </a-button>
      </template>
      
      <SupplierList></SupplierList>
      <template #footer> </template>
    </a-modal> -->

    <!--单位管理-->
    <a-modal
      v-model:visible="unitsManagerShow"
      width="100%"
      wrap-class-name="table-modal"
      centered
      :closeIcon="true"
      :destroyOnClose="true"
    >
      <template #title>
        <div class="modal-name">单位管理</div>
        <a-button class="close-btn" @click="unitsManagerShow = false">
          关闭
        </a-button>
      </template>
      <!--  TODO 组件存放 供应商列表      -->
      <unitsList></unitsList>
      <template #footer> </template>
    </a-modal>

    <!--标准管理-->
    <a-modal
      v-model:visible="standardManagerShow"
      width="100%"
      wrap-class-name="table-modal"
      centered
      :closeIcon="true"
      :destroyOnClose="true"
    >
      <template #title>
        <div class="modal-name">标准管理</div>
        <a-button class="close-btn" @click="standardManagerShow = false"
          >关闭
        </a-button>
      </template>
      
      <standardList></standardList>
      <template #footer> </template>
    </a-modal>

    <!--检测标准-->
    <a-modal
      v-model:visible="detectionShow"
      width="100%"
      class="control-standard"
      wrap-class-name="table-modal"
      centered
      :destroyOnClose="true"
      :closeIcon="true"
      @cancel="closeStandardModal"
    >
      <template #title>
        <div class="modal-name">内控标准</div>
        <a-button class="close-btn" @click="closeStandardModal">关闭 </a-button>
      </template>
      <!--  TODO 内控标准管理  组件存放处    -->
      <div id="codeEditModalContent"></div>
      <template #footer>
        <div id="materialDosageEditModalFooter"></div>
      </template>
      <teleport to="#codeEditModalContent" v-if="desshow">
        <detectionManage
          ref="detectionManageRef"
          @close-detection-modal="closeDetectionManage"
        ></detectionManage>
      </teleport>
    </a-modal>
  </div>
</template>

<script setup>
import {
  ref,
  // reactive,
  onMounted,
  watchEffect,
  createVNode,
  // defineEmits
} from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
// import { apiTopHeader } from '@/api/api-topHeader.js';
import { apiOperationDevice } from "@/api/api-operation/api-operation.js";
//TODO 导入子组件
import AggregatesList from "./cpns/menu/aggregates-list.vue";
// import SupplierList from './cpns/menu/supplier-list.vue';
import unitsList from "./cpns/menu/units-list.vue";
import standardList from "./cpns/menu/standard-list.vue";
import detectionManage from "./cpns/menu/detection-manage.vue";
import sampleList from "./cpns/menu/sample-list.vue";
import sampleRegist from "./cpns/menu/sample-regist.vue";
import accountManage from "./cpns/menu/account-manage.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import systemParameter from "./cpns/menu/system-parameter.vue";
// const emit = defineEmits(['refreshMainFoot']);

let store = useStore();
// console.log(store.state.engine);

let isMenuOut = ref(true);
// 当前检测标准
let standardCurrent = ref(null);

/**
 * @name 郭冲
 * @Date 2023-05-26 10:10:28
 * @introduction 监听器-监听vuex里的detecttionStandard的变化
 * @description 当变化的时候重新赋值给组件内的standardCurrent
 * @return 无返回类型
 * @exception [违例类型] [违例类型说明]
 */
watchEffect(() => {
  standardCurrent.value = store.state.device.detectionStandard;
});
// 打开样品和取样登记的布尔值
const isRegiste = ref(false);
// 显示取样登记还是样品登记标题
const registerName = ref("");
/**
 * @name 郭冲
 * @Date 2023-05-08 14:38:58
 * @introduction 打开取样或样品登记弹框
 * @description 标记是否为取样登记
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const showMolde1 = (type) => {
  isRegiste.value = true;
  registerName.value = type;
  // type === '取样登记' ? (samplingSwitch.value = 1) : (samplingSwitch.value = 0);
};

onMounted(() => {
  /**
   * @name 郭冲
   * @Date 2023-05-25 11:09:31
   * @introduction 取样和样品登记----获取当前检测标准信息
   * @description configType和id都是标准类型的标识（1--国标，2--内控）
   * @param {参数类型} 参数 参数说明
   * @return {返回类型说明}
   * @exception [违例类型] [违例类型说明]
   */
  // apiTopHeader
  //   .getCurrentStandards()
  //   .then((res) => {
  //     if (res.data.code === 0) {
  //       //当前的检测标准
  //       standardCurrent.value = res.data.data.configType;
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     message.error('获取当前检测标准失败', 2);
  //   });
});

function menuIn() {
  isMenuOut.value = true;
}
function menuOut() {
  isMenuOut.value = false;
}

//TODO 样品列表
// 控制样品列表弹窗显示的布尔值
const sampleListShow = ref(false);
/**
 * @introduction 打开样品弹窗
 */
const onClickSampleList = () => {
  sampleListShow.value = true;
};

//集料管理
const aggregatesShow = ref(false);
//供应商管理
// const supplierShow = ref(false);
const unitsManagerShow = ref(false);
// 检测标准管理
const detectionShow = ref(false);
const desshow = ref(false);
//标准管理
const standardManagerShow = ref(false);

// // 账号管理
const accountShow = ref(false);
// // 系统参数
const parameterShow = ref(false);
//显示 集料列表
const modalConfigurationShow = (type) => {
  switch (type) {
    case "集料管理":
      aggregatesShow.value = true;
      break;
    // case '供应商':
    //   supplierShow.value = true;
    //   break;
    case "单位管理":
      unitsManagerShow.value = true;
      break;
    case "检测标准":
      detectionShow.value = true;
      setTimeout(() => {
        desshow.value = true;
      }, 200);
      break;
    case "标准管理":
    standardManagerShow.value = true;
      break;
    case "账号管理":
      // accountShow.value = true;
      console.log(
        "🚀 ~ file: menuPop.vue:344 ~ modalConfigurationShow ~ accountShow.value:",
        accountShow.value
      );
      break;
    case "系统参数":
      parameterShow.value = true;
      break;
  }
};

const detectionManageRef = ref(null);
/**
 * @name 郭冲
 * @Date 2023-05-24 18:05:11
 * @introduction 关闭检测标准弹窗提示
 * @description 如果切换了标准未保存关闭时提示徐保存后更新为当前标准
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const closeStandardModal = () => {
  if (
    detectionManageRef.value.standard !==
    detectionManageRef.value.standardCurrent
  ) {
    detectionShow.value = true;
    Modal.confirm({
      title: "选择标准需要保存后更新为当前标准！是否关闭？",
      icon: createVNode(ExclamationCircleOutlined),
      content: "",
      onOk() {
        detectionShow.value = false;
        desshow.value = false;
      },
      onCancel() {},
    });
  } else {
    detectionShow.value = false;
    desshow.value = false;
  }
};
//TODO 保存成功关闭内控标准管理
const closeDetectionManage = (e) => {
  // console.log(e);
  detectionShow.value = e;
  desshow.value = false;
};

/**
 * @name 郭冲
 * @Date 2023-08-18 11:21:33
 * @introduction 操作设备急停暂停等
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const operation = (type, operationType) => {
  apiOperationDevice
    .operateDetectionDevice(type)
    .then((res) => {
      if (res.data.code === 0) {
        message.success(`${operationType}成功`, 2);
      }
    })
    .catch(() => {
      message.error(`${operationType}失败`, 2);
    });
};
</script>

<style lang="scss" scoped>
@import "../../static/font.css";
// @import '../assets/style/vxe-coustom.scss';
:deep(.ant-drawer .ant-drawer-content) {
  background-color: rgba(255, 255, 255, 0) !important;
  color: white;
}
:deep(.ant-drawer-body) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}
:deep(.ant-checkbox + span) {
  color: white;
}
:deep(.ant-checkbox-disabled + span) {
  color: rgb(167, 167, 167);
}
:deep(.ant-checkbox-disabled .ant-checkbox-inner) {
  background-color: gray;
}
:deep(.ant-form label) {
  color: white;
}
:deep(.ant-btn-text) {
  color: white;
  font-size: 0.09rem;
}
:deep(.ant-drawer-right.ant-drawer-open.no-mask) {
  width: 88% !important;
}
.menu {
  // pointer-events: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  .menu-out {
    pointer-events: visible;
    width: 0.18rem;
    height: 0.6rem;
    position: absolute;
    right: -0.01rem;
    cursor: pointer;
    object-fit: contain;
  }
  .menu-in {
    pointer-events: visible;
    width: 0.18rem;
    height: 0.6rem;
    position: absolute;
    right: 75%;
    cursor: pointer;
    object-fit: contain;
  }
  .menu-pop-div {
    overflow-y: auto;
    pointer-events: visible;
    position: relative;
    padding: 0.2rem 0rem;
    width: 76%;
    // height: 76%;
    height: 60%;
    right: 0%;
    border-radius: 0.1rem 0% 0% 0.1rem;
    background-color: #14243d;
    border: 0.02rem solid #a9c4e3;
    border-right: none;
    box-shadow: 0px 0px 30px rgba(136, 211, 255, 0.404),
      0px 0px 30px rgba(141, 168, 255, 0.151);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .sample-management {
      width: 100%;
      // height: 32%;
      .title {
        height: 0.25rem;
        padding-left: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top: 0.01rem solid #a9c4e3;
        border-bottom: 0.01rem solid #a9c4e3;
        .sample-name {
          font-size: 0.13rem;
          font-family: "PangMenZhengDao", sans-serif;
          font-weight: 400;
          color: #a9c4e3;
          letter-spacing: 0.01rem;
        }
      }
    }
    .setting {
      width: 100%;
      // height: 32%;
      .title {
        height: 0.25rem;
        padding-left: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top: 0.01rem solid #a9c4e3;
        border-bottom: 0.01rem solid #a9c4e3;
        .setting-name {
          font-size: 0.15rem;
          font-family: "PangMenZhengDao", sans-serif;
          font-weight: 400;
          color: #a9c4e3;
          letter-spacing: 0.01rem;
        }
      }
    }
    .operation {
      width: 100%;
      // height: 32%;
      .title {
        height: 0.25rem;
        padding-left: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top: 0.01rem solid #a9c4e3;
        border-bottom: 0.01rem solid #a9c4e3;
        .operation-name {
          font-size: 0.15rem;
          font-family: "PangMenZhengDao", sans-serif;
          font-weight: 400;
          color: #a9c4e3;
          letter-spacing: 0.01rem;
        }
      }
    }
    .setting-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .setting-list-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 0.09rem;
        width: 100%;
        height: 0.23rem;
        cursor: pointer;
        border-bottom: 0.005rem solid #ffffff1a;
      }
      .setting-list-item:hover {
        background-color: #213b64ce;
      }
    }
  }
  .drawer {
    pointer-events: visible;
  }
}
.registe-list {
  min-width: 720px !important;
}

.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.agrund-item {
  width: 30%;
  margin-right: 1%;
  padding: 0.04rem 0;
  text-align: center;
  background: rgba(18, 27, 37, 0.8);
  border: 1px solid #9ecdfc;
  margin-bottom: 0.06rem;
  color: #fff;
  border-radius: 0.02rem;
  cursor: pointer;
  font-size: 0.07rem;
}
.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
.fromline {
  display: flex;
  align-items: center;
  position: relative;
}
.fromline-name {
  font-size: 0.07rem;
  margin-left: 0.03rem;
  color: #fff;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}
// .stars {
//   background: #47a3fc;
//   border: 1px solid #47a3fc;
// }

// .mbtns {
//   font-size: 0.08rem;
//   color: #fff;
//   background: rgba(263, 39, 69, 0.6);
//   border: 1px solid rgb(263, 39, 69, 0.6);
//   padding: 0.03rem 0.06rem;
//   display: inline-block;
//   border-radius: 0.02rem;
//   width: 0.65rem;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// }
.mbtns .icons {
  font-size: 0.08rem;
  margin-left: 0.05rem;
}
.footes {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes star {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
:deep(.ant-form-item-label > label) {
  color: white;
  font-size: 0.08rem;
}

:deep(.sample-list .ant-modal-title) {
  font-size: 0.15rem;
}
:deep(.ant-input-suffix) {
  font-size: 0.09rem;
}
// :deep(.ant-btn > span) {
//   font-size: 0.09rem;
// }
:deep(.ant-form-item-explain-error) {
  color: #ff4d4f;
  font-size: 0.08rem;
}
.modlcont-wren {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.modlcont-wren-item {
  width: 50%;
  height: 3.5vh;
  display: flex;
  color: white;
  font-size: 0.08rem;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ff444480;
}
.wricon {
  color: rgb(255, 255, 255);
}

.sample-list {
  min-width: 720px !important;
}

.ant-modal-header {
  background: rgb(45, 58, 81) !important;
}

.standard {
  width: 85%;
  height: 0.3rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.standard-name {
  color: white;
  font-size: 0.07rem;
  /* line-height: 0.3rem; */
}
.standard-select {
  width: 1.2rem;
}
.coarse-aggregate {
  background-color: #169bd5;
  padding: 0.04rem;
}
.flex-r-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mod-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.06rem;
}

.save-btn {
  font-size: 0.07rem !important;
  margin-top: 0.1rem;
}
.regist-type {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
</style>
