import {createStore} from 'vuex'
import user from './modules/user';
import device from './modules/device';
import engine from "./modules/engine";
import getters from './getters';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        device,
        engine
    },
    getters
})
