import axios from "axios";

export class apiOperationDevice {
  //检测线操作设备
  static operateDetectionDevice(operateId) {
    return axios({
      url: `/device/operate/setting/${operateId}`,
      method: "GET",
    });
  }
}
