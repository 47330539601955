import axios from "axios";

export class apiLeft {
  //细度模数图表数据
  static getGrainCompositionChart(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/particleGradingCharts/page",
      method: "GET",
      params: query,
    });
  }
}
