<template>
  <div class="blending-container">
    <div class="top">
      <LeftOutlined
        class="prev-btn"
        @click="prevAction"
        :class="type == '推荐集料掺配比例' ? 'disabled-click' : ''"
      />
      <RightOutlined
        class="next-btn"
        @click="nextAction"
        :class="type == '推荐集料砂率' ? 'disabled-click' : ''"
      />
      <a-space size="large" v-if="type == '推荐集料掺配比例'">
        <p class="blending">推荐集料掺配比例</p>
        <!-- <p class="value">{{ blendingRatio || '--' }}</p> -->
      </a-space>
      <a-space size="large" v-else>
        <p class="blending">推荐集料砂率</p>
        <p class="value">{{ sandRateResult?.sandRate || '--' }}%</p>
      </a-space>
    </div>
    <div class="bottom">
      <div class="swiper">
        <div
          class="blending-ratio"
          :class="
            type == '推荐集料掺配比例' ? 'blending-active' : 'blending-noactive'
          "
        >
          <template v-if="aggregateArray.length > 0">
            <div
              class="blending-item"
              v-for="(item, index) in aggregateArray"
              :key="index"
            >
              <div
                :class="item.isSelected ? 'aggregate' : 'unselected'"
                @click="onAggregateClick(index)"
              >
                {{ formatterName(item.aggregate) || '-' }}
              </div>
              <div class="result" v-if="item.isSelected">
                {{ item.result }}%
              </div>
            </div>
          </template>
          <div class="device-empty" v-else>暂无数据</div>
        </div>
        <div
          class="sand-ratio"
          :class="type == '推荐集料砂率' ? 'sand-active' : 'sand-noactive'"
        >
          <div class="sand-top-item">
            <!-- <span>
              {{ formatterName(sandRateResult?.coarseAggregates) || '--' }}
            </span> -->
            <span>最大公称粒径：{{ sandRateResult?.particle || '--' }}mm</span>
            <span style="width: 35%">
              水胶比：
              <a-input
                v-model:value="waterBinder"
                placeholder="水胶比"
                style="width: 70px"
                @change="onDebouncedWB"
                @blur="onWBBlur"
              />
            </span>
          </div>
          <div
            calss="divider"
            style="height: 1px; background-color: #aad8fa"
          ></div>
          <div class="sand-top-item">
            <span>
              {{ formatterName(sandRateResult?.fineAggregates) || '--' }}
            </span>
            <span>
              细度模数：{{ sandRateResult?.finenessModulus || '--' }}
            </span>
            <span style="width: 35%">
              坍落度：
              <a-input
                v-model:value="slumpGrade"
                placeholder="坍落度"
                style="width: 70px"
                @change="onDebouncedSG"
              />
              mm
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import { apiTopHeader } from '../api/api-topHeader';
import { message } from 'ant-design-vue';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
const store = useStore();

const type = ref('推荐集料掺配比例');
const detectionLine = ref('ningtai');
// 推荐集料砂率相关数据
const sandRateResult = ref({});
const waterBinder = ref('0');
const slumpGrade = ref('0');
// const blendingRatio = ref('');
// 集料规格数组
const aggregateArray = ref([
  // { aggregate: '粗集料-碎石-5-10mm碎石', result: '21', isSelected: true },
  // { aggregate: '粗集料-碎石-10-20mm碎石', result: '79', isSelected: true },
  // { aggregate: '5-10mm碎石', result: '25', isSelected: true },
  // { aggregate: '10-20mm碎石', result: '75', isSelected: true },
]);
let setIntervalRatio = null;
let setIntervalSand = null;
const prevAction = () => {
  type.value = '推荐集料掺配比例';
};
const nextAction = () => {
  type.value = '推荐集料砂率';
};
/**
 * @introduction 推荐集料掺配比例点击事件
 */
const onAggregateClick = async (index) => {
  console.log('🚀 ~ onAggregateClick ~ index:', index);
  // if (!aggregateArray.value[index].aggregate.includes('5-10mm')) {
  //   aggregateArray.value[index].isSelected =
  //     !aggregateArray.value[index].isSelected;
  // } else {
  //   message.warning('5-10mm不能取消');
  // }
  // updateBlendRatio();
};
/**
 * @introduction 更新掺配比
 */
const updateBlendRatio = async () => {
  let selectedData = aggregateArray.value
    .filter((item) => item.isSelected)
    .map((item) => item.aggregate);
  try {
    if (selectedData.length > 0) {
      let params = {
        aggregatesSpecsList: selectedData,
        detectionSerialNum: detectionLine.value,
      };
      let res = await apiTopHeader.updateBlendRatio(params);
      if (res.data.code == 0) {
        message.success(res.data.message, 2);
      }
    }
  } catch (error) {
    console.log('🚀 ~ updateSandRate ~ error:', error);
    message.error(error?.data?.message ?? '更新掺配比失败', 2);
  }
};
/**
 * @introduction 水胶比值改变事件
 */
const onWBChange = () => {
  if (waterBinder.value < 0.4 || waterBinder.value > 0.7) {
    message.error('水胶比的值必须是0.4~0.7');
    return;
  } else {
    updateSandRate();
  }
};
const onDebouncedWB = debounce(onWBChange, 600);
/**
 * @introduction 水胶比失焦事件
 */
const onWBBlur = () => {
  // if (waterBinder.value < 0.4 || waterBinder.value > 0.7) {
  //   message.error('水胶比的值必须是0.4~0.7');
  // }
};
/**
 * @introduction 坍落度值改变事件
 */
const onSGChange = () => {
  if (!slumpGrade.value) {
    message.error('坍落度不能为空');
    return;
  }
  updateSandRate();
};
const onDebouncedSG = debounce(onSGChange, 600);
/**
 * @introduction 格式化集料规格名称
 */
const formatterName = (name) => {
  if (name?.indexOf('-') !== -1) {
    return (name = name?.split('-')?.slice(2).join('-'));
  } else {
    return name;
  }
};
onMounted(() => {
  setIntervalEvent();
  setMouseEnter();
  setMouseLeave();

  setInputVlaue();
  
  // updateSandRate();
  // getAggregates();
});

onUnmounted(() => {
  clearIntervalEvent();
});
/**
 * @introduction 获取推荐掺配比所有集料
 */
const getAggregates = async (isRefresh = false) => {
  try {
    const params = {
      detectionSerialNum: detectionLine.value,
    };
    let res = await apiTopHeader.getAggregates(params);
    if (res.data.code == 0) {
      // console.log('🚀 ~ getAggregates ~ res:', res);
      aggregateArray.value = res.data.data.map((item) => {
        return {
          aggregate: item,
          result: '-',
          isSelected: true,
        };
      });
      // 第一次加载时计算掺配比
      !isRefresh && updateBlendRatio();
    }
  } catch (error) {
    console.log('🚀 ~ updateSandRate ~ error:', error);
    message.error(error?.data?.message ?? '获取掺配比集料失败', 2);
  }
};
/**
 * @introduction 获取水胶比、坍落度
 */
const setInputVlaue = () => {
  // 缓存坍落度和水胶比默认值
  let localwaterBinder = localStorage.getItem(
    `waterBinder${detectionLine.value}`
  );
  let localslumpGrade = localStorage.getItem(
    `slumpGrade${detectionLine.value}`
  );
  // if (localwaterBinder == 'undefined' || localslumpGrade == 'undefined') {
  //   localStorage.setItem(`waterBinder${detectionLine.value}`, 0.45);
  //   localStorage.setItem(`slumpGrade${detectionLine.value}`, 220);
  // }
  // 临时修改--开始
  // !localwaterBinder && localStorage.setItem(`waterBinder${detectionLine.value}`, 0.45);
  // !localslumpGrade && localStorage.setItem(`slumpGrade${detectionLine.value}`, 220);
  !localwaterBinder &&
    localStorage.setItem(`waterBinder${detectionLine.value}`, '');
  !localslumpGrade &&
    localStorage.setItem(`slumpGrade${detectionLine.value}`, '');
  // 临时修改--结束
  waterBinder.value = localStorage.getItem(`waterBinder${detectionLine.value}`);
  slumpGrade.value = localStorage.getItem(`slumpGrade${detectionLine.value}`);
};
/**
 * @introduction 更新水胶比、坍落度
 */
const updateSandRate = async () => {
  try {
    let params = {
      detectionSerialNum: detectionLine.value,
      // 临时修改--开始
      // waterBinder: waterBinder.value,
      // slumpGrade: slumpGrade.value,
      // 临时修改--结束
    };
    let res = await apiTopHeader.updateSandRate(params);
    if (res.data.code == 0) {
      message.success(res.data.message, 2);
    }
  } catch (error) {
    console.log('🚀 ~ updateSandRate ~ error:', error);
    message.error(error?.data?.message ?? '更新砂率失败', 2);
  }
};
/**
 * @introduction 设置定时任务
 */
const setIntervalEvent = () => {
  clearIntervalEvent();
  setIntervalRatio = setInterval(() => {
    type.value = '推荐集料掺配比例';
  }, 4500);
  setIntervalSand = setInterval(() => {
    type.value = '推荐集料砂率';
  }, 9000);
};
/**
 * @introduction 清除定时任务
 */
const clearIntervalEvent = () => {
  clearInterval(setIntervalRatio);
  clearInterval(setIntervalSand);
};
/**
 * @introduction 设置鼠标移入事件
 */
const setMouseEnter = () => {
  const content = document.querySelector('.swiper');
  content.addEventListener('mouseenter', function () {
    clearIntervalEvent();
  });
};
/**
 * @introduction 设置鼠标移入事件
 */
const setMouseLeave = () => {
  const content = document.querySelector('.swiper');
  content.addEventListener('mouseleave', function () {
    setIntervalEvent();
  });
};
// 临时修改--开始
// watch(
//   [
//     () => store.state.engine.allEngineData.sandRateResultMap, //最新推荐集料砂率
//     () => store.state.engine.allEngineData.sandRateParam, //最新推荐集料砂率
//   ],
//   ([newSandRateResult, newParamMap]) =>
//     //
//     {
//       // 推荐砂率
//       if (newSandRateResult[detectionLine.value]) {
//         // console.log('🚀 ~ newSandRateResult:', newSandRateResult);
//         sandRateResult.value =
//           newSandRateResult?.[`${detectionLine.value}`]?.[0];
//         sandRateResult.value.sandRate = sandRateResult.value.sandRate + 9;
//       }
//       // 水胶比，坍落度
//       if (newParamMap) {
//         // console.log('🚀 ~ newParamMap:', newParamMap);
//         // console.log('🚀 ~ newParamMap:', newParamMap[detectionLine.value]);
//         localStorage.setItem(
//           `slumpGrade${detectionLine.value}`,
//           newParamMap?.slumpGrade
//         );
//         localStorage.setItem(
//           `waterBinder${detectionLine.value}`,
//           newParamMap?.waterBinder
//         );
//       }
//     },
//   { immediate: false }
// );
// 临时修改--结束
watch(
  [
    () => store.state.engine.allEngineData.planningSolutionResult, //最新推荐集料掺配比例
    () => store.state.engine.allEngineData.sandRateResultMap, //最新推荐集料砂率
    () => store.state.engine.allEngineData.sandRateParam, //最新推荐集料砂率
  ],
  (
    [newPlanningSolutionResult, newSandRateResult, newParamMap],
    [oldPlanningSolutionResult]
  ) =>
    //
    {
      // 推荐集料掺配比例
      if (newPlanningSolutionResult) {
        // console.log('🚀 ~ newPlanningSolutionResult:', newPlanningSolutionResult);
        if (oldPlanningSolutionResult && newPlanningSolutionResult) {
          let newLength = Object.keys(newPlanningSolutionResult)?.length;
          let oldLength = Object.keys(oldPlanningSolutionResult)?.length;
          if (newLength !== oldLength) {
            getAggregates(true);
          }
          Object.keys(newPlanningSolutionResult).forEach((key) => {
            let index = aggregateArray.value.findIndex(
              (item) => key == item.aggregate
            );
            if (index !== -1) {
              aggregateArray.value[index].result =
                newPlanningSolutionResult[key];
            }
          });
        }
      }
      // 推荐砂率
      if (newSandRateResult?.[detectionLine.value]) {
        // console.log('🚀 ~ newSandRateResult:', newSandRateResult);
        sandRateResult.value =
          newSandRateResult?.[`${detectionLine.value}`]?.[0];
        sandRateResult.value.sandRate = sandRateResult.value.sandRate + 9;
      }
      // 水胶比，坍落度
      if (newParamMap) {
        // console.log('🚀 ~ newParamMap:', newParamMap);
        // console.log('🚀 ~ newParamMap:', newParamMap[detectionLine.value]);
        // 临时修改--开始
        // localStorage.setItem(
        //   `slumpGrade${detectionLine.value}`,
        //   newParamMap?.slumpGrade
        // );
        // localStorage.setItem(
        //   `waterBinder${detectionLine.value}`,
        //   newParamMap?.waterBinder
        // );
        // 临时修改--结束
      }
    },
  { immediate: false }
);
// 临时修改--开始
</script>

<style lang="scss" scoped>
.blending-container {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(33, 56, 92, 0.55);
  box-shadow: 0px 0px 24px rgba(190, 190, 190, 0.048),
    0px 0px 50px rgba(202, 202, 202, 0.079);
  .top {
    width: 100%;
    height: 30%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
    background-color: rgba(17, 34, 62, 0.5);
    .prev-btn {
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      line-height: 0.2rem;
      font-size: 0.1rem;
      cursor: pointer;
      left: 2%;
      color: #fff;
      z-index: 99999;
    }
    .next-btn {
      position: absolute;
      width: 0.2rem;
      height: 0.2rem;
      line-height: 0.2rem;
      font-size: 0.1rem;
      cursor: pointer;
      right: 2%;
      color: #fff;
      z-index: 99999;
    }
    .disabled-click {
      cursor: not-allowed;
      color: #797979;
    }
    p {
      margin-bottom: 0;
    }
    .blending,
    .sand-ratio {
      font-size: 0.085rem;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .value {
      font-family: PangMenZhengDao;
      color: #95b5da;
      font-size: 0.11rem;
    }
  }
  .bottom {
    width: 100%;
    height: 70%;
    overflow: hidden;
    border-radius: 0px 0px 5px 5px;
  }
  .swiper {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .blending-ratio {
      overflow-y: auto;
      min-width: 100%;
      height: 100%;
      left: 0%;
      padding: 0% 15%;
      transition: all 0.3s;
      // background-color: #ffffff2f;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      row-gap: 2%;
    }
    .blending-active {
      transform: translateX(0%);
    }
    .blending-noactive {
      transform: translateX(-100%);
    }
    .sand-ratio {
      transition: all 0.3s;
      overflow-y: auto;
      min-width: 100%;
      height: 100%;
      padding: 0% 10%;
      // background-color: #ffcccc4e;
    }
    .sand-active {
      transform: translateX(-100%);
    }
    .sand-noactive {
      transform: translateX(100%);
    }

    .blending-item {
      cursor: pointer;
      width: 50%;
      height: 50%;
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .aggregate,
      .unselected {
        width: 70%;
        background-color: #1e365a;
        border: 1px solid #83b1fc;
        border-radius: 5px;
        color: white;
        font-size: 0.08rem;
        text-align: center;
        padding: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .unselected {
        background-color: #17243c;
        color: white;
        border: none;
      }
      .result {
        font-family: PangMenZhengDao;
        color: #bfdaf9;
        margin-left: 5%;
        font-size: 0.09rem;
      }
    }
  }
}
.device-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
}
.sand-top-item {
  width: 100%;
  height: 45%;
  font-size: 0.08rem;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}
.divider {
  min-width: 100%;
  height: 2px;
  background-color: #aad8fa;
  // background: radial-gradient(closest-side, #ffffff, #9f9f9f75, #8d8d8d12);
}
</style>
