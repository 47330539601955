const dragScroll = {
  mounted(el) {
        let isDragging = false;
        let startPositionX = 0;
        let scrollLeft = 0;

        el.addEventListener('mousedown', (e) => {
            isDragging = true;
            startPositionX = e.pageX - el.offsetLeft;
            scrollLeft = el.scrollLeft;

            el.style.cursor = 'grabbing';
        });

        el.addEventListener('mousemove', (e) => {
            if (!isDragging) return;
            const x = e.pageX - el.offsetLeft;
            const walk = (x - startPositionX) * 2; // 调整滚动速度
            el.scrollLeft = scrollLeft - walk;
        });

        el.addEventListener('mouseup', () => {
            isDragging = false;
            el.style.cursor = 'grab';
        });

        el.addEventListener('mouseleave', () => {
            isDragging = false;
            el.style.cursor = 'grab';
        });
    },
}
// 自定义指令
const directives = {
    dragScroll
};
export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key]);
        });
    },
}