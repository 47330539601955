import {createApp} from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router'
import store from './store'
import axios from 'axios';
import VueAxios from 'vue-axios';
import ECharts from 'vue-echarts'
import {use} from "echarts/core";
import './config/axios-config';
import setting from "./config/setting";
import directive from './views/js/directive'

import md5 from 'js-md5';

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

// import config from "../static/config.json";
// import './sql/syncCache.js';
// 手动引入 ECharts 各模块来减小打包体积
import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    BarChart
} from 'echarts/charts'
import {
    GridComponent,
    TooltipComponent
} from 'echarts/components'

import dataV from '@jiaminghi/data-view'

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent
]);



// setting.token = config.token;
// setting.url = config.url;
// setting.username = config.username;
// setting.password = config.password;
// setting.cacheToken(config.token);
const app = createApp(App);
app.use(VXETable);
app.use(store);
app.use(Antd);
app.use(router);
app.use(dataV);
app.component('v-chart', ECharts);
app.use(VueAxios, axios);
app.use(directive)
app.mount('#app');
app.config.globalProperties.$setting = setting;
app.config.globalProperties.$md5 = md5;

