/**
 * 设备数据
 */
// devices
// deviceSN
// 样品检测结果
// setValue 方法
export default {
    namespaced: true,
    state: {
        // 当前检测标准
        detectionStandard: null,
        // 是否修改检测标准
        isEditStandard: false,
        //2位字母缩写对应的设备
        prefixDevice: {
            TG: 'sky_rail_manipulator',
            QY: 'gantry_sampling',
            WM: 'water_and_mud',
            SF: 'fineness_modulus',
            MB: 'methylene_blue',
            ZP: 'needle_shaped',
        },
        deviceControl: {
            //控制总柜[与天轨合并了]
            control_cabinet: {
                device_id: 'MQSDY0030000001220523CQ',
                device_mode: '自动模式',
                device_status: '停机',
                fault_code: '',
                detection: 'jc0003',
            },
            //龙门取样
            gantry_sampling: {
                prefix: 'QY',
                device_id: 'MQSDY0030000002220523CQ',
                device_mode: '自动模式',
                device_status: '停机',
                fault_code: '',
                detection: 'jc0003',
            },
            //含水含泥
            water_and_mud: {
                prefix: 'WM',
                device_id: 'MQSDY0030000004220523CQ',
                device_mode: '自动模式',
                device_status: '停机',
                fault_code: '',
                detection: 'jc0003',
            },
            //针片状
            needle_shaped: {
                prefix: 'ZP',
                device_id: 'MQSDY0030000005220523CQ',
                device_mode: '自动模式',
                device_status: '停机',
                fault_code: '',
                detection: 'jc0003',
            },
            //细度模数
            fineness_modulus: {
                prefix: 'SF',
                device_id: 'MQSDY0030000006220523CQ',
                device_mode: '自动模式',
                device_status: '停机',
                fault_code: '',
                detection: 'jc0003',
            },
            //亚甲蓝
            methylene_blue: {
                prefix: 'MB',
                device_id: 'MQSDY0030000007220523CQ',
                device_mode: '自动模式',
                device_status: '停机',
                fault_code: '',
                detection: 'jc0003',
            },
            //天轨机械手设备
            sky_rail_manipulator: {
                prefix: 'TG',//前缀用于拼接参数  因为设备的参数都在一个plc 元件名称不能重复 例如 TGdevice_id
                device_id: 'MQSDY0030000003220523CQ',
                device_mode: '自动模式',
                device_status: '急停模式',
                fault_code: '',
                detection: 'jc0003',
            },
        },
        //序列号对应设备数据
        deviceData: {
            //天轨机械手设备TG
            MQSDY0030000003220523CQ: {
                //唯一序号
                serial_number: '0000003',
                //简称
                short_name: '天轨机械手',
                //全称
                name: '茂侨科技智能设备原材料检测系统天轨机械手',
                //设备所属主类   Y 原材料检测 H 混凝土检测
                main_type: '原材料检测',
                //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                sub_type: '天轨机械手',
                //出厂时间 220523
                factory_time: '2022年05月23日',
                //生产地 CQ 重庆
                production_place: '重庆',
                //属性
                attribute: {
                    /**
                     * 基类
                     */
                    //设备标识
                    device_id: {
                        current_value: 'MQSDY0030000001220523CQ',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备IP地址
                    device_ip: {
                        current_value: '192.168.0.3',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测单号
                    detection: {
                        current_value: 'jc0003',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //集料类型 0：碎石 1：砂
                    aggregate_type: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                    group: {
                        current_value: 1,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备模式 0急停模式 1手动模式 2自动模式
                    device_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备状态 0停机 1运行中
                    device_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //故障代码  请参考《砂石含水含泥检测设备操作手册》
                    fault_code: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程急停 0失效 1有效
                    telnet_stop: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程启动 0失效 1有效
                    telnet_start: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    /////////////////////////////////////////////////////////
                    x_axis_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    z_axis_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    gripper_open_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    gripper_rotation_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                },
            },
            //TODO：总柜 [暂时不用了 变成天轨]
            /* MQSDY0030000001220523CQ: {
                 //唯一序号
                 serial_number: '0000001',
                 //简称
                 short_name: '检测总柜',
                 //全称
                 name: '茂侨科技智能设备原材料检测系统总柜',
                 //设备所属主类   Y 原材料检测 H 混凝土检测
                 main_type: '原材料检测',
                 //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                 sub_type: '原材料检测总柜',
                 //出厂时间 220523
                 factory_time: '2022年05月23日',
                 //生产地 CQ 重庆
                 production_place: '重庆',
                 //属性
                 attribute: {
                     /!**
                      * 基类
                      *!/
                     //设备标识
                     device_id: {
                         current_value: 'MQSDY0030000001220523CQ',
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '公共参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //设备IP地址
                     device_ip: {
                         current_value: '192.168.0.3',
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '公共参数',
                         history: [
                             {
                                 detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //检测单号
                     detection: {
                         current_value: 'jc0003',
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '公共参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //集料类型 0：碎石 1：砂
                     aggregate_type: {
                         current_value: 0,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '公共参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                     group: {
                         current_value: 1,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '公共参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //设备模式 0急停模式 1手动模式 2自动模式
                     device_mode: {
                         current_value: 0,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '状态类参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //设备状态 0停机 1运行中
                     device_status: {
                         current_value: 0,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '状态类参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //故障代码  请参考《砂石含水含泥检测设备操作手册》
                     fault_code: {
                         current_value: 0,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '公共参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //远程急停 0失效 1有效
                     telnet_stop: {
                         current_value: 0,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '控制类参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     //远程启动 0失效 1有效
                     telnet_start: {
                         current_value: 0,
                         current_time: '2022年6月8日15:56:04',
                         type: '读',
                         param_type: '控制类参数',
                         history: [
                             {
                                 detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                             },
                             {
                                 detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                             },
                         ],
                     },
                     /////////////////////////////////////////////////////////
                 },
             },*/
            //龙门取样QY
            MQSDY0030000002220523CQ: {
                //唯一序号
                serial_number: '0000002',
                //简称
                short_name: '龙门取样设备',
                //全称
                name: '茂侨科技智能设备原材料检测系统龙门取样设备',
                //设备所属主类   Y 原材料检测 H 混凝土检测
                main_type: '原材料检测',
                //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                sub_type: '龙门取样设备',
                //出厂时间 220523
                factory_time: '2022年05月23日',
                //生产地 CQ 重庆
                production_place: '重庆',
                //属性
                attribute: {
                    /**
                     * 基类
                     */
                    //设备标识
                    device_id: {
                        current_value: 'MQSDY0030000001220523CQ',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备IP地址
                    device_ip: {
                        current_value: '192.168.0.3',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测单号
                    detection: {
                        current_value: 'jc0003',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //集料类型 0：碎石 1：砂
                    aggregate_type: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                    group: {
                        current_value: 1,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备模式 0急停模式 1手动模式 2自动模式
                    device_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备状态 0停机 1运行中
                    device_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //故障代码  请参考《砂石含水含泥检测设备操作手册》
                    fault_code: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程急停 0失效 1有效
                    telnet_stop: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程启动 0失效 1有效
                    telnet_start: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    /////////////////////////////////////////////////////////
                    //X轴位置
                    x_axis_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //Y轴位置
                    y_axis_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //Z轴位置
                    z_axis_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //钻头钻速
                    drill_speed: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //仓门状态
                    bingate_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //盖板状态
                    cover_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点X_1
                    x_axis_samplepoint_1: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点X_2
                    x_axis_samplepoint_2: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点X_3
                    x_axis_samplepoint_3: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点Y_1
                    y_axis_samplepoint_1: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点Y_2
                    y_axis_samplepoint_2: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点Y_3
                    y_axis_samplepoint_3: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 取样点Z_1
                    z_axis_samplepoint_1: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点Z_2
                    z_axis_samplepoint_2: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //取样点Z_3
                    z_axis_samplepoint_3: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                },
            },
            //含水含泥检测WM
            MQSDY0030000004220523CQ: {
                //唯一序号
                serial_number: '0000004',
                //简称
                short_name: '含水含泥设备',
                //全称
                name: '茂侨科技智能设备原材料检测系统含水含泥设备',
                //设备所属主类   Y 原材料检测 H 混凝土检测
                main_type: '原材料检测',
                //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                sub_type: '含水含泥检测设备',
                //出厂时间 220523
                factory_time: '2022年05月23日',
                //生产地 CQ 重庆
                production_place: '重庆',
                //属性
                attribute: {
                    /**
                     * 基类
                     */
                    //设备标识
                    device_id: {
                        current_value: 'MQSDY0030000001220523CQ',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备IP地址
                    device_ip: {
                        current_value: '192.168.0.3',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测单号
                    detection: {
                        current_value: 'jc0003',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //集料类型 0：碎石 1：砂
                    aggregate_type: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                    group: {
                        current_value: 1,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备模式 0急停模式 1手动模式 2自动模式
                    device_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备状态 0停机 1运行中
                    device_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //故障代码  请参考《砂石含水含泥检测设备操作手册》
                    fault_code: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程急停 0失效 1有效
                    telnet_stop: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程启动 0失效 1有效
                    telnet_start: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    /////////////////////////////////////////////////////////
                    //磁控管1状态
                    magnetron_1_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //磁控管2状态
                    magnetron_2_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //磁控管3状态
                    magnetron_3_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //磁控管4状态
                    magnetron_4_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //炉门状态
                    door_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //TODO:称重值  图标变化展示 通过history 分析
                    weight: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //微波挡位
                    gears: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //含水率
                    moisture_content: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //含泥率
                    clay_content: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                },

            },
            //针片状检测ZP
            MQSDY0030000005220523CQ: {
                //唯一序号
                serial_number: '0000005',
                //简称
                short_name: '针片状检测设备',
                //全称
                name: '茂侨科技智能设备原材料检测系统针片状检测设备',
                //设备所属主类   Y 原材料检测 H 混凝土检测
                main_type: '原材料检测',
                //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                sub_type: '针片状检测设备',
                //出厂时间 220523
                factory_time: '2022年05月23日',
                //生产地 CQ 重庆
                production_place: '重庆',
                //属性
                attribute: {
                    /**
                     * 基类
                     */
                    //设备标识
                    device_id: {
                        current_value: 'MQSDY0030000001220523CQ',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备IP地址
                    device_ip: {
                        current_value: '192.168.0.3',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测单号
                    detection: {
                        current_value: 'jc0003',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //集料类型 0：碎石 1：砂
                    aggregate_type: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                    group: {
                        current_value: 1,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备模式 0急停模式 1手动模式 2自动模式
                    device_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备状态 0停机 1运行中
                    device_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //故障代码  请参考《砂石含水含泥检测设备操作手册》
                    fault_code: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程急停 0失效 1有效
                    telnet_stop: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程启动 0失效 1有效
                    telnet_start: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    /////////////////////////////////////////////////////////
                    //直振器
                    vibrator: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //分散轮
                    diffuser: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //皮带线
                    atpd: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //运行时间
                    runtime: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //TODO:针状 占比 图表展示【文档有变化 暂时不处理 2022年6月9日10:25:01】
                    acicular: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //TODO:片状 占比 图表展示【文档有变化 暂时不处理 2022年6月9日10:25:07】
                    flaky: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },

                    diameter4_75to9_5: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    diameter26to31_5: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    diameter9_5to16: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    diameter31_5to37_5: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    diameter16to19: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    diameter19to26: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '检测结果',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                },

            },
            //细度模数检测SF
            MQSDY0030000006220523CQ: {
                //唯一序号
                serial_number: '0000006',
                //简称
                short_name: '细度模数检测',
                //全称
                name: '茂侨科技智能设备原材料检测系统细度模数检测设备',
                //设备所属主类   Y 原材料检测 H 混凝土检测
                main_type: '原材料检测',
                //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                sub_type: '细度模数检测设备',
                //出厂时间 220523
                factory_time: '2022年05月23日',
                //生产地 CQ 重庆
                production_place: '重庆',
                //属性
                attribute: {
                    /**
                     * 基类
                     */
                    //设备标识
                    device_id: {
                        current_value: 'MQSDY0030000001220523CQ',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备IP地址
                    device_ip: {
                        current_value: '192.168.0.3',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测单号
                    detection: {
                        current_value: 'jc0003',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //集料类型 0：碎石 1：砂
                    aggregate_type: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                    group: {
                        current_value: 1,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备模式 0急停模式 1手动模式 2自动模式
                    device_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备状态 0停机 1运行中
                    device_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //故障代码  请参考《砂石含水含泥检测设备操作手册》
                    fault_code: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程急停 0失效 1有效
                    telnet_stop: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程启动 0失效 1有效
                    telnet_start: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    /////////////////////////////////////////////////////////
                    //电机运行状态
                    motor_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //电机运行频率
                    motor_frequency: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //4.75mm筛上重量
                    sieve_weight_4_75_mm: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //2.36筛上重量
                    sieve_weight_2_36: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 1.15筛上重量
                    sieve_weight_1_15: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //0.6筛上重量
                    sieve_weight_0_6: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 0.3筛上重量
                    sieve_weight_0_3: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 0.15筛上重量
                    sieve_weight_0_15: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 0.075筛上重量
                    sieve_weight_0_075: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 小于0.075mm筛上重量
                    small_sieve_weight_0_075mm: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },

                    //TODO:细度模数 图表展示
                    fineness_modulus: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                },

            },
            //亚甲蓝检测MB
            MQSDY0030000007220523CQ: {
                //唯一序号
                serial_number: '0000007',
                //简称
                short_name: '亚甲蓝检测设备',
                //全称
                name: '茂侨科技智能设备原材料检测系统亚甲蓝检测设备',
                //设备所属主类   Y 原材料检测 H 混凝土检测
                main_type: '原材料检测',
                //设备所属子类 001：原材料检测总柜 002：龙门取样设备003：天轨桁架004：含水含泥检测设备005：针片状检测设备006：细度模数检测设备007：亚甲蓝检测设备
                sub_type: '亚甲蓝检测设备',
                //出厂时间 220523
                factory_time: '2022年05月23日',
                //生产地 CQ 重庆
                production_place: '重庆',
                //属性
                attribute: {
                    /**
                     * 基类
                     */
                    //设备标识
                    device_id: {
                        current_value: 'MQSDY0030000001220523CQ',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'MQSDY0030000001220523CQ', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备IP地址
                    device_ip: {
                        current_value: '192.168.0.3',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: '192.168.0.1', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: '192.168.0.2', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测单号
                    detection: {
                        current_value: 'jc0003',
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 'jc0001', time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 'jc0002', time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //集料类型 0：碎石 1：砂
                    aggregate_type: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测组合 0：保留 1：仅做含水率、含泥率检测  2：含水率、含泥率检测、针片状检测  3：含水率、含泥率检测、细度模数检测 4：含水率、含泥率检测、细度模数检测、MBV检测
                    group: {
                        current_value: 1,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 2, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 3, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备模式 0急停模式 1手动模式 2自动模式
                    device_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //设备状态 0停机 1运行中
                    device_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //故障代码  请参考《砂石含水含泥检测设备操作手册》
                    fault_code: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '公共参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 2, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程急停 0失效 1有效
                    telnet_stop: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //远程启动 0失效 1有效
                    telnet_start: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '控制类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    /////////////////////////////////////////////////////////
                    //夹持臂Z轴当前位置
                    gripper_arm_z_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //夹持臂R1轴当前位置
                    gripper_arm_r1_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 夹持臂R2轴当前位置
                    gripper_arm_r2_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 移液臂Z轴当前位置
                    pipetting_arm_z_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //移液臂R1轴当前位置
                    pipetting_arm_r1_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //  移液臂R2轴当前位置
                    pipetting_arm_r2_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 搅拌组X轴当前位置
                    stirring_group_x_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 搅拌组Z1轴当前位置
                    stirring_group_z1_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //搅拌组Z2轴当前位置
                    stirring_group_z2_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //搅拌组Z3轴当前位置
                    stirring_group_z3_axis_current_position: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //搅拌电机1转速
                    stirring_motor_1_speed: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //搅拌电机2转速
                    stirring_motor_2_speed: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //  搅拌电机3转速
                    stirring_motor_3_speed: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 蠕动泵电机1转速
                    peristaltic_pump_motor_1_speed: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //  蠕动泵电机2转速
                    peristaltic_pump_motor_2_speed: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //夹持臂柔性夹爪开闭状态
                    gripper_arm_flexible_gripper_open_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //夹持臂柔性夹爪旋转状态
                    gripper_arm_flexible_gripper_rotate_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //移液枪状态
                    pipette_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //蒸馏水液位
                    distilled_water_level: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //亚甲蓝液位
                    methylene_blue_level: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 送料直振器1状态
                    feed_straight_vibrator_1_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //送料直振器2状态
                    feed_straight_vibrator_2_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 送料直振器3状态
                    feed_straight_vibrator_3_status: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //送料控制气缸1
                    feed_control_cylinder_1: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 送料控制气缸2
                    feed_control_cylinder_2: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04',
                        type: '读',
                        param_type: '状态类参数',
                        history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    // 送料控制气缸3
                    feed_control_cylinder_3: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04', type: '读', param_type: '状态类参数', history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //检测方式
                    detection_mode: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04', type: '读', param_type: '检测方式', history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                    //TODO:亚甲蓝值 图表展示
                    MBV: {
                        current_value: 0,
                        current_time: '2022年6月8日15:56:04', type: '读', param_type: '检测结果', history: [
                            {
                                detection: 'jc0001', value: 1, time: '2022年6月8日15:54:10',
                            },
                            {
                                detection: 'jc0002', value: 0, time: '2022年6月8日15:54:10',
                            },
                        ],
                    },
                },

            },
        },
        //左侧数据
        sampleTestResult: [
            /* {
                 detection: 'jc0001',
                 sampling_time: '2022年6月9日10:09:41',
                 aggregate_type: '10-20mm砂石',
                 results: [
                     {
                         serial_number: 1,
                         item: '含水率',
                         result: '4.56%',
                         status: 1
                     },
                     {
                         serial_number: 2,
                         item: '含泥率',
                         result: '10.45%',
                         status: 1
                     },
                     {
                         serial_number: 3,
                         item: '细度模数',
                         result: '5.547',
                         status: 0
                     },
                     {
                         serial_number: 4,
                         item: '针状',
                         result: '-',
                         status: ''
                     },
                     {
                         serial_number: 5,
                         item: '片状',
                         result: '-',
                         status: ''
                     },
                     {
                         serial_number: 6,
                         item: '亚甲蓝（MB）',
                         result: '-',
                         status: ''
                     }
                 ]
             },
             {
                 detection: 'jc0002',
                 sampling_time: '2022年6月9日10:09:41',
                 aggregate_type: '10-20mm砂石',
                 results: [
                     {
                         serial_number: 1,
                         item: '含水率',
                         result: '4.56%',
                         status: 0
                     },
                     {
                         serial_number: 2,
                         item: '含泥率',
                         result: '10.45%',
                         status: 1
                     },
                     {
                         serial_number: 3,
                         item: '细度模数',
                         result: '5.547 11.1 123213',
                         status: 1
                     },
                     {
                         serial_number: 4,
                         item: '针状',
                         result: '-',
                         status: ''
                     },
                     {
                         serial_number: 5,
                         item: '片状',
                         result: '-',
                         status: ''
                     },
                     {
                         serial_number: 6,
                         item: '亚甲蓝（MB）',
                         result: '-',
                         status: ''
                     }
                 ]
             },
             {
                 detection: 'jc0003',
                 sampling_time: '2022年6月9日10:09:41',
                 aggregate_type: '10-20mm砂石',
                 results: [
                     {
                         serial_number: 1,
                         item: '含水率',
                         result: '4.56%',
                         status: 1
                     },
                     {
                         serial_number: 2,
                         item: '含泥率',
                         result: '10.45%',
                         status: 1
                     },
                     {
                         serial_number: 3,
                         item: '细度模数',
                         result: '5.547',
                         status: 0
                     },
                     {
                         serial_number: 4,
                         item: '针状',
                         result: '-',
                         status: ''
                     },
                     {
                         serial_number: 5,
                         item: '片状',
                         result: '-',
                         status: ''
                     },
                     {
                         serial_number: 6,
                         item: '亚甲蓝（MB）',
                         result: '-',
                         status: ''
                     }
                 ]
             }*/
        ],
        //右侧数据
        chartData: {
            //含水含泥
            water_and_mud: [],
            //细度模数
            fineness_modulus: [],
            //级配和针片状
            graded_and_needle_flakes: [],
            //亚甲蓝（MB）
            methylene_blue: []
        },
        //检测记录数据
        dataSource: [
            /* {
                 key: '1',
                 detection: 'jc0003',
                 aggregate_type: 0,
                 sampling_time: '2022年6月14日09:49:21',
                 moisture_content: 0,
                 clay_content: 0,
                 acicular: 0,
                 flaky: 0,
                 fineness_modulus: 0,
                 MBV: 0,
                 test_report: '下载地址',
             },
             {
                 key: '2',
                 detection: 'jc0002',
                 aggregate_type: 0,
                 sampling_time: '2022年6月14日09:49:21',
                 moisture_content: 0,
                 clay_content: 0,
                 acicular: 0,
                 flaky: 0,
                 fineness_modulus: 0,
                 MBV: 0,
                 test_report: '下载地址',
             },
             {
                 key: '1',
                 detection: 'jc0001',
                 aggregate_type: 0,
                 sampling_time: '2022年6月14日09:49:21',
                 moisture_content: 0,
                 clay_content: 0,
                 acicular: 0,
                 flaky: 0,
                 fineness_modulus: 0,
                 MBV: 0,
                 test_report: '下载地址',
             },*/
        ],
        //整线启动
        Detection_ACK: 0
    },

    mutations: {
        SET_STANDARD(state, payload) {
            state.detectionStandard = payload
        },
        SET_ISEDITSTANDARD(state, payload) {
            state.isEditStandard = payload
        }
    },
    actions: {
        CHANGE_SATNDARD({ commit }, payload) {
            console.log("🚀 ~ file: device.js:2676 ~ CHANGE_SATNDARD ~ payload:", payload)
            commit('SET_STANDARD', payload)
        },
        CHANGE_ISEDITSTANDARD({ commit }, payload) {
            console.log("🚀 ~ file: device.js:2676 ~ 是否编辑检测标准 ~ payload:", payload)
            commit('SET_ISEDITSTANDARD', payload)
        }
    }
}
