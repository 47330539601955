<template>
  <div class="main-foots-car" v-dragScroll ref="scrollContainer">
    <div class="main-foots-left" v-if="sampleTotalData.length > 0">
      <div
        class="main-foots-item"
        v-for="item in sampleTotalData"
        :Key="item.key"
      >
        <!-- item.status 1为待检测 2为检测中 3为完成 4为未完成 -->
        <sampleItem
          :sampleData="item"
          :sampleDetectionLength="sampleDetectionLength"
        >
        </sampleItem>
      </div>
    </div>
    <div v-else class="main-foots-empty">
      <a-empty class="list-empty" :image="Empty.PRESENTED_IMAGE_SIMPLE" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
// import { apiTopHeader } from '@/api/api-topHeader.js';
// import { message } from 'ant-design-vue';
import sampleItem from './cpns/sample/sample-item.vue';
import { Empty, message } from 'ant-design-vue'
import { useStore } from 'vuex';
let store = useStore();

// 取样登记的数据长度
const sampleDetectionLength = ref(0);
// 底部可滑动区域的实例
const scrollContainer = ref(null);

/**
 * @introduction 获取指定标准
 */
// const getExecutionStandard = (executionStandard) => {
//   if (executionStandard) {
//     return store.state.engine.allEngineData.executionStandardOptions.find((element) => element.value == executionStandard)?.label
//   }else{
//     return ''
//   }
// }

watch(
  () => store.state.engine.allEngineData, //检测数据的前20条数据
  (newValue) => {
    nextTick(() => {
      let  newQueueToList= newValue?.toBeTestedQueueToList || []
      // console.log('最新数据',newQueueToList);
      // formataData(toRaw(newQueueToList));
      // data.value.sort((a, b) => b.createDatetime - a.createDatetime);
      // 状态为检测中的项组成的数组
      let detectingData = newQueueToList
        .filter((item) => item.status == 2)
        .sort((a, b) => b.createDatetime - a.createDatetime);
      // （地磅自动取样）插队的待检测项组成的数组
      let sampleDetectingData = newQueueToList
        .filter((item) => item.status == 1 && item.samplingSwitch == 1)
        .sort((a, b) => a.createDatetime - b.createDatetime);
      sampleDetectionLength.value = sampleDetectingData.length; //取样登记如果有数据则检测完后才可启动样品登记

      // （手动登记自动取样与取样登记数据）待检测的项组成的数组
      let underDetectingData = newQueueToList
        .filter((item) => item.status == 1 && !item.samplingSwitch)
        .sort((a, b) => a.createDatetime - b.createDatetime);
      // 如果没有取样数据则设置样品登记的第一个可以启动
      if (underDetectingData.length > 0) {
        underDetectingData[0].launchEnable = true;
      }
      // 已完成和未完成项的数组
      let completeAndUnfinishDetectionData = newQueueToList
        .filter((item) => item.status == 3 || item.status == 4)
        .sort((a, b) => b.createDatetime - a.createDatetime);
      // 根据sampleDetails生成对应检测项字段，此处用于检测记录表显示和隐藏对应的检测记录数据
      // completeAndUnfinishDetectionData?.forEach((item) => {
      //   let sampling = { result: null }; // 取样（每一项反对应的取样）
      //   let moisture = { result: null }; // 含水（每一项...）
      //   let mudcontent = { result: null }; // 含泥（每一项...）
      //   let needleParticle = { result: null }; // 颗粒级配--针片状颗粒（每一项...）
      //   let irregularParticle = { result: null }; // 颗粒级配--不规则颗粒（每一项...）
      //   let finenessModulus = { result: null }; // 细度模数（每一项...）
      //   let methyleneBlue = { result: null }; // 亚甲蓝（每一项...）
      //   item.sampleDetails?.forEach((element) => {
      //     switch (element.detections) {
      //       case '取样':
      //         sampling.result = element.detectionResults;
      //         break;
      //       case '含水率':
      //         moisture.result = element.detectionResults;
      //         break;
      //       case '含泥率':
      //         mudcontent.result = element.detectionResults;
      //         break;
      //       case '颗粒级配':
      //         needleParticle.result = JSON.parse(
      //           element.detectionResults
      //         )?.needleParticleTotalQualityRate;
      //         irregularParticle.result = JSON.parse(
      //           element.detectionResults
      //         )?.irregularParticleContent;
      //         break;
      //       case '细度模数':
      //         finenessModulus.result = element.detectionResults;
      //         break;
      //       case 'MB值':
      //         methyleneBlue.result = element.detectionResults;
      //         break;
      //     }
      //   });
      //   // 根据sampleDetails生成对应的检测项字段，不影响原来的数据
      //   Object.assign(item, {
      //     sampling: sampling, // 取样
      //     moisture: moisture, // 含水
      //     mudcontent: mudcontent, // 含泥
      //     needleParticle: needleParticle, // 颗粒级配--针片状颗粒
      //     irregularParticle: irregularParticle, // 颗粒级配--不规则颗粒
      //     finenessModulus: finenessModulus, // 细度模数
      //     methyleneBlue: methyleneBlue, // 亚甲蓝
      //     // executionStandardName:getExecutionStandard(item.executionStandard) // 执行标准
      //   });
      // });
      // 最终将所有数据排序赋值给sampleTotalData
      let sortTotalData = [
        ...detectingData, //检测中
        ...sampleDetectingData, //取样登记（插队）
        ...underDetectingData, //待检测
        ...completeAndUnfinishDetectionData, //已完成和未完成
      ];
      // 只展示10条数据
      sampleTotalData.value = sortTotalData?.slice(0, 10);
      // sampleTotalData.value = sortTotalData;
      // console.log(
      //   '🚀 ~ file: mainFoots.vue:45 ~ nextTick ~ sampleTotalData.value :',
      //   sampleTotalData.value
      // );
    });
  },
  { immediate: false }
);

// 样品列表---样品列表所有数据
let sampleTotalData = ref([
  // {
  //   key: "1",
  //   testNumber: "NTJ20230403-001",
  //   aggregatesSpecs: "10-20mm砂石",
  //   supplierName: "重庆龙积达建材有限公司",
  //   producerName: "合川砂石场",
  //   createDatetime: "2022-5-30 12:30:10",
  //   cargo: "【粗骨料-碎石】",
  //   moisture: "25%",
  //   mudcontent: "1%",
  //   particleGrading: "55",
  //   finenessModulus: "60",
  //   methyleneBlue: "33",
  //   status: 1,
  // },
  // {
  //   testNumber: 'MQJX20230330-0001',
  //   aggregatesSpecs: '10-20mm砂石',
  //   combinationNumber: 4,
  //   executionStandard: '国标',
  //   producerName: 'jsd',
  //   sampleDetails: [
  //     {
  //       detection: '含水率',
  //       status: 3,
  //       detectionResults: '0.3',
  //     },
  //   ],
  //   sampleDetailsMap: {
  //     含水率: 3,
  //     含泥率: 3,
  //     细度模数: 3,
  //   },
  //   samplingSwitch: 0,
  //   status: 1,
  //   launchEnable: true,
  //   supplierName: 'gonginas',
  // },
]);
onMounted(() => {
  nextTick(() => {
    // 获取水平滚动容器的引用
    const container = scrollContainer.value;

    if (container) {
      // 监听鼠标滚轮事件
      container.addEventListener('wheel', (e) => {
        // 阻止默认滚动行为，避免整个页面滚动
        e.preventDefault();

        // 计算滚动的距离，根据需要进行调整
        const delta = e.deltaY || e.detail || e.wheelDelta;

        // 设置水平滚动条的滚动位置
        container.scrollLeft += delta;
      });
    }
  });
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.main-foots-car {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 0.04rem;
  overflow-x: auto;
}
.main-foots-left {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1%;
}
.main-foots-empty {
  width: 100%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  font-size: 0.09rem;
  background-color: rgba(7, 7, 7, 0.2);
}
.main-foots-item {
  width: 19%;
  height: 100%;
  flex-shrink: 0;
}

:deep(.slick-slide) {
  text-align: center;
  width: 100%;
  height: 100%;
  background: #364d79;
  overflow: hidden;
}
/* 
:deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  transition: ease all 0.3s;
  opacity: 0.3;
  z-index: 1;
}
:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}
:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: #fff;
  opacity: 0.5;
}

:deep(.slick-slide h3) {
  color: #fff;
} */
:deep(.ant-empty-description){
  color: white;
}
</style>
