import axios from "axios";

export class apiTopHeader {
  //========集料管理==========//
  //分页查询 集料列表
  static findAggregateListByPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/aggregateManage/page",
      method: "GET",
      params: query,
    });
  }

  //根据id获取集料详情
  static getAggregateDetailById(id) {
    return axios({
      url: `/device/aggregateManage/${id}`,
      method: "GET",
    });
  }

  //添加集料
  static addAggregate(reqData) {
    return axios({
      url: "/device/aggregateManage",
      method: "POST",
      data: reqData,
    });
  }

  //删除集料
  static deleteAggregate(id) {
    return axios({
      url: `/device/aggregateManage/${id}`,
      method: "DELETE",
    });
  }

  //==========供应商管理==================//
  //全部集料查询
  static queryAllAggregates() {
    return axios({
      url: "/device/aggregateManage",
      method: "GET",
    });
  }
  //是否禁用集料查询
  static queryEnableAggregates(params) {
    return axios({
      url: "/device/aggregateManage/existAggregatesSpecsFlag",
      method: "POST",
      data: params,
    });
  }

  //供应商列表
  static findSupplierListByPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/sampling/aggregateToVendor",
      method: "POST",
      data: query,
    });
  }

  //添加供应商
  static addSupplier(reqData) {
    return axios({
      url: "/device/vendorManage",
      method: "POST",
      data: reqData,
    });
  }

  //修改供应商
  static editSupplier(reqData) {
    return axios({
      url: "/device/vendorManage",
      method: "PUT",
      data: reqData,
    });
  }

  //删除供应商
  static deleteSupplier(id) {
    return axios({
      url: `/device/vendorManage/${id}`,
      method: "DELETE",
    });
  }

  //添加供应商
  static isExistVendorManage(reqData) {
    return axios({
      url: "/device/vendorManage/existVendorManage",
      method: "POST",
      data: reqData,
    });
  }

  //==========内控标准==================//
  //全部内控标准查询
  static queryAllControlStandards() {
    return axios({
      url: "/device/controlStandards",
      method: "GET",
    });
  }
  //保存内控标准
  static saveAllControlStandards(data) {
    return axios({
      url: "/device/controlStandards",
      method: "PUT",
      data: data,
    });
  }
  //查询对应的检测标准
  static queryControlStandard(id) {
    return axios({
      url: `/device/controlStandards/${id}`,
      method: "GET",
    });
  }
  //查询当前检测标准
  static getCurrentStandards() {
    return axios({
      url: "/device/controlStandards/enable",
      method: "GET",
    });
  }
  //查询检测标准管理
  static getStandardPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/standardManagement/page",
      method: "GET",
      params: query,
    });
  }
  //查询集料分类属性数据
  static getAggregateTreeList(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/aggregateManage/materialTreeData",
      method: "GET",
      params: query,
    });
  }

  //=============日志==================//

  //日志列表
  static findLogListByPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/runLog/page",
      method: "GET",
      params: query,
    });
  }

  //=============系统参数管理==================//
  // 获取系统参数
  static getSysConfig(queryParams) {
    const query = queryParams;
    return axios({
      url: "/sysConfig/getConfig",
      method: "GET",
      params: query,
    });
  }
  // 保存系统参数
  static sysConfigSave(reqData) {
    return axios({
      url: "/sysConfig/save",
      method: "POST",
      data: reqData,
    });
  }
  // 获取所有系统参数
  static getAllConfig(queryParams) {
    const query = queryParams;
    return axios({
      url: "/sysConfig/getAllConfig",
      method: "GET",
      params: query,
    });
  }

  //=============集料分类管理==================//
  // 查询集料分类数据
  static findAggregatesClassificationPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/aggregateClassification/page",
      method: "GET",
      params: query,
    });
  }
  // 集料分类--添加或编辑时查询大类数据
  static findMainCategory(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/standardManagement/materialCategory",
      method: "GET",
      params: query,
    });
  }
  // 集料分类--编辑时查询集料分类详情
  static getCategoryDetail(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/aggregateClassification",
      method: "GET",
      params: query,
    });
  }
  // 集料分类--编辑集料分类
  static editCategoryClasification(reqData) {
    return axios({
      url: "/device/aggregateClassification/edit",
      method: "POST",
      data: reqData,
    });
  }
  // 集料分类--添加集料分类
  static addCategoryClasification(reqData) {
    return axios({
      url: "/device/aggregateClassification/add",
      method: "POST",
      data: reqData,
    });
  }
  // 更新砂率
  static updateSandRate(reqData) {
    return axios({
      url: "/device/sample/updateSandRate",
      method: "POST",
      data: reqData,
    });
  }
  // 更新砂率
  static getAggregates(reqData) {
    return axios({
      url: "/device/aggregateManage/category",
      method: "POST",
      data: reqData,
    });
  }
  // 更新掺配比
  static updateBlendRatio(reqData) {
    return axios({
      url: "/device/sample/updatePlanningSolution",
      method: "POST",
      data: reqData,
    });
  }

  //=============内控标准集料数据获取==================//

  // 集料--获取内控标准
  static getControlStandards(reqData) {
    return axios({
      url: "/device/controlStandards/getControlStandards",
      method: "GET",
      data: reqData,
    });
  }
  //保存内控标准
  static saveAlldata(data) {
    return axios({
      url: "/device/controlStandards/save",
      method: "POST",
      data: data,
    });
  }

  //=============单位管理==================//

  //单位管理列表
  static findCompanyListByPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/company/page",
      method: "GET",
      params: query,
    });
  }
  //删除单位管理
  static deleteCompany(id) {
    return axios({
      url: `/device/company/${id}`,
      method: "DELETE",
    });
  }
  //添加单位
  static addCompany(reqData) {
    return axios({
      url: "/device/company",
      method: "POST",
      data: reqData,
    });
  }
  //编辑单位
  static editCompany(reqData) {
    return axios({
      url: "/device/company",
      method: "PUT",
      data: reqData,
    });
  }

  //=============标准管理==================//
  static getTestingBasisPage(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/testingBasis/getTestingBasisPage",
      method: "POST",
      data: query,
    });
  }
  static TestingBasisSave(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/testingBasis/save",
      method: "POST",
      data: query,
    });
  }
  static testingBasisDelete(id) {
    return axios({
      url: `/device/testingBasis/${id}`,
      method: "DELETE",
    });
  }

 
}
