import axios from "axios";

export class apiMainfoots {
  //样品列表--集料规格查询
  static getAggregateSpecification(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/aggregateManage",
      method: "GET",
      params: query,
    });
  }

  //样品列表--供应商查询(v2.0)
  static getSupplier(data) {
    return axios({
      url: "/device/company/getCompanyByType",
      method: "POST",
      data: data,
    });
  }

  //样品列表--生产商查询
  static getProducer(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/manufacturers",
      method: "GET",
      params: query,
    });
  }
  //样品列表--样品列表查询
  static getSampleList(queryParams) {
    return axios({
      url: "/device/sample/page",
      method: "POST",
      data: queryParams,
    });
  }
  //样品列表--样品单号查询详情
  static getSampleDetail(testNumber) {
    return axios({
      url: `/device/sample/testNumber/${testNumber}`,
      method: "GET",
    });
  }
  //样品列表--下载集料检测记录表
  static downloadRecordTable(reqData) {
    return axios({
      url: `/device/sample/exportReport`,
      method: "POST",
      data: reqData,
      responseType: 'blob', // 设置响应类型为 blob
    });
  }
  //样品列表--打印集料检测记录表
  static printRecordTable(reqData) {
    return axios({
      url: `/device/sample/printTestReport`,
      method: "POST",
      data: reqData,
    });
  }
  //样品列表--查看集料检测记录表
  static viewRecordTable(reqData) {
    return axios({
      url: `/device/sample/exportReport`,
      method: "POST",
      data: reqData,
    });
  }
  //样品列表--删除待检测记录
  static deleteUnderDetection(params) {
    return axios({
      url: `/device/sample/delSample`,
      method: "POST",
      data: params,
    });
  }
  //样品列表--编辑待检测记录
  static editUnderDetection(params) {
    return axios({
      url: `/device/sample`,
      method: "PUT",
      data: params,
    });
  }
  //样品列表--启动样品检测
  static launchDetection(params) {
    return axios({
      url: `/device/sampling/startPlcTest`,
      method: "POST",
      data: params,
    });
  }
  //样品列表--放弃检测
  static abandonDetection(params) {
    return axios({
      url: `/device/sample/abandonTest`,
      method: "POST",
      data: params,
    });
  }
  //样品列表--确认检测前获取车型
  static getVhicleList () {
    return axios({
      url: `/vehicle-model/list`,
      method: "GET",
    });
  }
}
